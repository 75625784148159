import { observable, action, makeObservable } from "mobx";
import { toast } from "react-toastify";

export default class ApiBasedStore {
  rootStore;
  hasFetched = false;
  isFetching = false;
  isSilentFetching = false;
  hasFailed = false;
  errorMessage = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      hasFetched: observable,
      isFetching: observable,
      isSilentFetching: observable,
      hasFailed: observable,
      errorMessage: observable,
      startRequest: action,
      endRequest: action,
      onError: action,
    });
  }

  startRequest(setIsFetching = true) {
    this.hasFailed = false;
    this.errorMessage = false;
    this.isFetching = setIsFetching;
    this.isSilentFetching = true;
    this.rootStore.pendingRequestCount++;
  }

  endRequest() {
    this.hasFetched = true;
    this.isFetching = false;
    this.isSilentFetching = false;
    this.rootStore.pendingRequestCount--;
  }

  onError(error) {
    console.log(error);
    this.isFetching = false;
    this.hasFailed = true;
    this.errorMessage = typeof error === "object" ? error.message : error;
    this.rootStore.pendingRequestCount--;
    toast.error(error.message, { autoClose: false });
    if (error.message === "Login details not recognised.") {
      this.rootStore.authStore.clearStore();
    }
  }
}
