import React from "react";

export const TestHarnesses = () => {
  return (
    <div>
      <h1>Test Harnesses</h1>
      <div>
        <div>
          <h2>Styling</h2>
          <ul>
            <li>
              <a href="harnesses/styling/shuffle">Shuffle</a>
            </li>
            <li>
              <a href="harnesses/styling/wpick">WPick</a>
            </li>
            <li>
              <a href="harnesses/styling/wpick/logs">WPick Logs</a>
            </li>
          </ul>
        </div>
        <div>
          <h2>Wardrobe</h2>
          <ul>
            <li>
              <a href="harnesses/wardrobe/pieces">Pieces</a>
            </li>
            <li>
              <a href="harnesses/wardrobe/outfits">Outfits</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
