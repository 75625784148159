import axios from "axios";

/**
 * @param {string} baseUrl
 * @param {string} endpoint
 * @param {('GET'|'POST'|'PATCH'|'DELETE)} method
 * @param {Object} params
 * @returns
 */
export default function apiFetch(baseUrl, endpoint, method, params) {
  let url = `${baseUrl}/${endpoint}`;

  // Always expect a JSON response
  const opts = {
    headers: {
      Accept: "application/json",
    },
    body: {},
  };

  // Add the token to the request
  const userData = JSON.parse(window.localStorage.getItem("AuthStore"));
  if (userData.token) {
    opts.headers["Authorization"] = `Bearer ${userData.token}`;
  }

  // add request body if data is not a GET
  if (method !== "GET") {
    if (params instanceof FormData) {
      // console.log(params, "ooo");
      // opts.headers["Content-Type"] = "multipart/form-data";
      // opts.headers["Accept"] = "multipart/form-data";
      opts.body = params;
      opts.headers["Content-Type"] = "multipart/form-data";
    } else {
      opts.headers["Content-Type"] = "application/json";
      opts.body = JSON.stringify(params);
    }
  }

  console.log(`Calling:`, url, opts);
  // const trace = perf.trace(`FETCH_${endpoint}_${params.action}`);
  // trace.start();
  return axios({ method, url, data: opts.body, headers: opts.headers, withCredentials: true }).then((response) => {
    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`[ERROR]: ${response.data.message ?? `[SERVER ERROR]: ${response.status}`}`);
    }
  });
}
