import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";
import AdminForm from "./components/AdminForm";

function Admin() {
  const { adminStore, authStore } = useStores();
  const [showCreate, setShowCreate] = useState(false);
  const [selectedUser, setSelectedUser] = useState(false);

  const fetchUsers = async () => {
    await adminStore.getAllUsers();
  };

  const handleOpenCreate = () => {
    if (authStore.user.role !== "SuperUser") {
      toast("You do not have access to this resource. Please contact a SuperUser.");
    } else {
      setShowCreate(true);
    }
  };

  const onSaveUser = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    if (!selectedUser) {
      await adminStore.createUser(formDataObj);
      if (!adminStore.hasFailed) {
        setShowCreate(false);
        toast("New user created!", { type: "success" });
      }
    } else {
      await adminStore.updateUser(formDataObj, selectedUser.userID);
      if (!adminStore.hasFailed) {
        setShowCreate(false);
        toast("User updated!", { type: "success" });
        setSelectedUser(false);
      }
    }
  };

  const onEditUser = (user) => {
    setSelectedUser({ ...user });
    setShowCreate(true);
  };

  const deleteUser = async (user) => {
    if (window.confirm("Delete user, are you sure?")) {
      await adminStore.deleteUser(user.userID);
      if (!adminStore.hasFailed) {
        toast("User Deleted");
      }
    }
  };

  const onHide = () => {
    setShowCreate(false);
    setSelectedUser(false);
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <TableWrapper title="Admin - Users" showLoading={adminStore.isFetching} onAddNewClick={handleOpenCreate}>
        <Table className="model-table">
          <thead>
            <tr>
              <th></th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Role</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {adminStore.users.map((user) => (
              <tr key={user.userID}>
                <td>{user.userID}</td>
                <td>{user.firstname}</td>
                <td>{user.surname}</td>
                <td>{user.email}</td>
                <td>{user.role}</td>
                <td>
                  <Button onClick={() => onEditUser(user)}>Edit</Button>
                  <Button onClick={() => deleteUser(user)} variant="danger">
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      <Modal show={showCreate} onHide={onHide}>
        <Modal.Header closeButton>Create New Admin User</Modal.Header>
        <Modal.Body>
          <AdminForm onSubmit={onSaveUser} user={selectedUser} updateUser={setSelectedUser} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default observer(Admin);
