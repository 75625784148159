import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Alert, Button, Table } from "react-bootstrap";
import { useStores } from "../../context/mobx-context";
import TableWrapper from "../../components/TableWrapper";

function Spotlights({ history }) {
  const { marketplaceStore } = useStores();

  const [brokenImages, setBrokenImages] = useState([]);

  useEffect(() => {
    async function getData() {
      await marketplaceStore.getSpotlights();
    }

    getData();
  }, []);

  const goToProduct = (productID = "new") => {
    history.push(`/marketplace/spotlight/${productID}`);
  };

  return (
    <TableWrapper
      title="Marketplace - Spotlights"
      searchValue={marketplaceStore.searchTerm}
      showLoading={marketplaceStore.isFetching}
      onAddNewClick={() => goToProduct()}
    >
      {brokenImages.length > 0 && (
        <Alert variant="danger">
          <Alert.Heading>The following products have broken images and may have been removed.</Alert.Heading>
          <p>Please either update the product image or delete the record.</p>
          {brokenImages.map((product) => (
            <p
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => goToProduct(product.productID)}
            >
              {product.name}
            </p>
          ))}
        </Alert>
      )}

      <Table className="model-table">
        <thead>
          <tr>
            <th></th>
            <th>Gender</th>
            <th>Locations</th>
            <th>Brand Spotlight</th>
            <th>Edit Spotlight</th>
            <th>Featured Edits</th>
            <th>Trending Brands</th>
          </tr>
        </thead>
        <tbody>
          {marketplaceStore.spotlights.map((spot) => {
            return (
              <tr key={spot?.spotlightID}>
                <td></td>
                <>
                  <td className="text-capitalize">{spot?.gender}</td>
                  <td>
                    {spot?.locations &&
                      spot?.locations.map((item, i) => {
                        return <p className="m-0">{item}</p>;
                      })}
                  </td>
                  <td>{spot.brand_spotlight?.name}</td>
                  <td>{spot.edit_spotlight?.name}</td>
                  <td>
                    {spot?.featured_edits.map((item, i) => {
                      return <p className="m-0">{item?.name}</p>;
                    })}
                  </td>
                  <td>
                    {spot?.trending_brands.map((item, i) => {
                      return <p className="m-0">{item?.name}</p>;
                    })}
                  </td>
                </>

                <td>
                  <Button onClick={() => goToProduct(spot.spotlightID)}>Edit</Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default observer(Spotlights);
