import { CHER_BASE_URL } from ".";
import wheringFetch from "../../helpers/fetch";

const route = "ucdbreported";
const baseUrl = CHER_BASE_URL;

const ucdbReportedApi = {
  get: async (page) => {
    return wheringFetch(baseUrl, `${route}?page=${page}`, "GET");
  },

  create: async (data) => {
    return wheringFetch(baseUrl, `${route}`, "POST", data);
  },
  update: async (id, data) => {
    return wheringFetch(baseUrl, `${route}/${id}`, "PATCH", data);
  },
  delete: async (id) => {
    return wheringFetch(baseUrl, `${route}/${id}`, "DELETE");
  },
};

export default ucdbReportedApi;
