import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Table, Dropdown } from "react-bootstrap";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { toBase64 } from "../../helpers/image";
import { CustomToggle } from "../../components/CustomToggle";

function Thoughts({ history }) {
  const { blogStore } = useStores();

  useEffect(() => {
    blogStore.getAllArticles();
  }, []);

  const goToArticle = (articleID) => {
    history.push(`/thoughts/${articleID}`);
  };

  const handleDuplicateArticle = async (article) => {
    const { articleID, created, published, ...articleData } = article;
    articleData.title = article.title + " (Copy)";
    await blogStore.createArticle(articleData);
  };

  return (
    <TableWrapper
      title="Thoughts - Articles"
      showLoading={blogStore.isFetching}
      onAddNewClick={() => goToArticle("new")}
    >
      <Table className="model-table">
        <thead>
          <tr>
            <th></th>
            <th>Title</th>
            <th>Category</th>
            <th>Published</th>
            {/* <th>Active</th> */}
          </tr>
        </thead>
        <tbody>
          {blogStore.articles.map((article) => {
            const today = new Date();
            const publishedDate = article.published ? new Date(article.published) : false;
            return (
              <tr key={article.articleID}>
                <td>
                  <img src={article.imageUrl} height={100} />
                </td>
                <td>
                  <a href={`https://whering.co.uk/thoughts/${article.url}`}>{article.title}</a>
                </td>
                <td>{article.category}</td>
                <td>{publishedDate !== false && publishedDate <= today ? <FcCheckmark /> : <FcCancel />}</td>
                <td>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => goToArticle(article.articleID)}>Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleDuplicateArticle(article)} style={{ color: "orange" }}>
                        Duplicate
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default observer(Thoughts);
