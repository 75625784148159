import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

let firebaseConfig;

if (process.env.REACT_APP_VERCEL_ENV === "production" || process.env.REACT_APP_APP_ENV === "production") {
  firebaseConfig = {
    apiKey: "AIzaSyB-q8zrBPXb75HgvzD5brgH84Qj0TvvMsg",
    authDomain: "whering.firebaseapp.com",
    databaseURL: "https://whering.firebaseio.com",
    projectId: "whering",
    storageBucket: "whering.appspot.com",
    messagingSenderId: "182334734580",
    appId: "1:182334734580:web:2590c69c543934241e5ea1",
    measurementId: "G-899MM3L2B8",
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyDuiVRnQ0bL920ve1uHNxb_tQp_DkidyO8",
    authDomain: "whering-test.firebaseapp.com",
    databaseURL: "https://whering-test-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "whering-test",
    storageBucket: "whering-test.appspot.com",
    messagingSenderId: "328664314186",
    appId: "1:328664314186:web:2f71d4fb6767d1177791b3",
    measurementId: "G-8YSK1EDEPR",
  };
}

export const app = initializeApp(firebaseConfig);
export const firestore = getFirestore();
if (process.env.REACT_APP_APP_ENV === "local") {
  connectFirestoreEmulator(firestore, "localhost", 8080);
}
