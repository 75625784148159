import React, { useEffect, useRef, useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import { Alert, Button, Col, Form, Image, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";
import { toBase64 } from "../../helpers/image";
import { observer } from "mobx-react";
import { RiImageEditLine } from "react-icons/ri";
import ImageGallery from "../ImageGallery";

function NewThought({ match, history }) {
  const articleID = match.params.articleID === "new" ? match.params.articleID : parseInt(match.params.articleID);
  const inputRef = useRef(null);
  const { blogStore } = useStores();
  const initialValue = `<article>
  <div class="align-vertical">
    <p class="date">Article Date</p>
    <h1 class="ivy">Article Title</h1>
    <p class="author-by">by <a href="https://www.instagram.com/whering__">Team Whering</a></p>
  </div>
  <div class="align-horizontal">
      <p>Left Item</p>
      <p>Right Item</p>
  </div>
</article>`;

  const [articleHtml, setArticleHtml] = useState({ __html: initialValue });
  const [formState, setFormState] = useState({
    title: "",
    category: "",
    imageUrl: "",
    published: "",
    changed: false,
  });
  const [imageFile, setImageFile] = useState(false);
  const [showImageGallery, setShowImageGallery] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      if (blogStore.articles.length === 0) {
        await blogStore.getAllArticles();
      }
      if (articleID !== "new") {
        const article = blogStore.articles.find((article) => article.articleID === articleID);
        const data = {
          title: article.title,
          category: article.category,
          imageUrl: article.imageUrl,
          published: article.published ? new Date(article.published).toISOString().slice(0, 10) : "",
          changed: false,
        };

        if (article) {
          setFormState(data);
          setArticleHtml({ __html: article.body });
        }
      }
    }
    getData();
  }, []);

  const updateFormState = (event) => {
    setFormState((prev) => {
      return { ...prev, [event.target.name]: event.target.value, changed: true };
    });
  };

  const onChangeImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setImageFile(file);
    setFormState((prev) => {
      return {
        ...prev,
        imageUrl: URL.createObjectURL(file),
        changed: true,
      };
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (!formState.imageUrl) {
      toast("You must include an image", { type: "warning" });
      return;
    }

    let data = { ...formState, body: articleHtml.__html };

    // If an image file is in state, we need to upload it with the request, convert to base64 and append to data object
    if (imageFile !== false) {
      data.newImage = await toBase64(imageFile);
    }
    delete data.changed; // only needed for UI check, not in request object

    if (articleID === "new") {
      delete data["imageUrl"]; // don't send an imageUrl on new articles, we'll always be uploading a new one
      const newArticleID = await blogStore.createArticle(data);
      history.push(`/thoughts/${newArticleID}`);
    } else {
      const article = blogStore.articles.find((article) => article.articleID === articleID);
      if (!article) {
        toast("cannot update an article that does not exist", { type: "error" });
        return;
      }

      // concatenate the formstate and original data object so created and updated are maintained
      await blogStore.updateArticle(articleID, data);
    }

    if (!blogStore.hasFailed) {
      setFormState((prev) => {
        return { ...prev, changed: false };
      });
      toast("Article Saved!", { type: "success" });
    }
  };

  const handleDelete = async () => {
    await blogStore.deleteArticle(articleID);

    if (!blogStore.hasFailed) {
      toast("Article Deleted!", { type: "error" });
      setTimeout(() => {
        history.goBack();
      }, 500);
    }
  };

  const onDoneClick = (event) => {
    event.preventDefault();

    if (formState.changed && !window.confirm("You have unsaved changes, are you sure you want to continue?")) {
      return;
    }

    history.push("/thoughts");
  };

  return (
    <div>
      <Alert variant="danger" show={showDelete}>
        <Alert.Heading>This action is irreversible.</Alert.Heading>
        <p>The data you are about to delete will not be recoverable, are you sure you want to continue?</p>
        <Button variant="outline-danger" onClick={handleDelete}>
          Yes, delete
        </Button>
        <Button onClick={() => setShowDelete(false)}>No, cancel</Button>
      </Alert>
      <div style={{ display: "flex", borderBottom: "solid 2px black" }}>
        <CodeMirror
          value={articleHtml.__html}
          height="600px"
          minWidth="600px"
          maxWidth="1200px"
          extensions={[html({ matchClosingTags: true, autoCloseTags: true })]}
          onChange={(value) => {
            setArticleHtml({ __html: value });
            setFormState((prev) => {
              return { ...prev, changed: true };
            });
          }}
          theme="dark"
          style={{ flex: 2 }}
        />
        <Form style={{ flex: 1, padding: "1rem" }} onSubmit={handleSave}>
          <Col className="mb-3" style={{ display: "flex", justifyContent: "center" }}>
            <input type="file" id="file" ref={inputRef} style={{ display: "none" }} onChange={onChangeImage} />
            {formState.imageUrl ? (
              <div style={{ position: "relative", width: "fit-content" }}>
                <Image src={formState.imageUrl} style={{ height: 300 }} />
                <Button style={{ position: "absolute", right: 0, margin: 10 }} onClick={() => inputRef.current.click()}>
                  <RiImageEditLine size={30} />
                </Button>
              </div>
            ) : (
              <Button onClick={() => inputRef.current.click()}>Add Main Image</Button>
            )}
          </Col>
          <Form.Group className="mb-3">
            <Form.Label>Article Title</Form.Label>
            <Form.Control
              placeholder="Enter a title for your Article"
              name="title"
              required
              value={formState.title}
              onChange={updateFormState}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Article Category</Form.Label>
            <Form.Control
              placeholder="Enter a category for your Article"
              name="category"
              required
              value={formState.category}
              onChange={updateFormState}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Published Date</Form.Label>
            <Form.Control type="date" name="published" onChange={updateFormState} value={formState.published} />
          </Form.Group>

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 20 }}>
            <Button type="submit" variant="success" disabled={blogStore.isFetching}>
              {blogStore.isFetching ? "Saving..." : "Save"}
            </Button>
            <Button type="submit" onClick={onDoneClick}>
              Done
            </Button>
            {articleID !== "new" && (
              <Button variant="danger" onClick={() => setShowDelete(true)} disabled={blogStore.isFetching}>
                Delete
              </Button>
            )}
          </div>
        </Form>
      </div>
      <div style={{ display: "flex", margin: 20 }}>
        <Button onClick={() => setShowImageGallery(true)}>Add Image To Article</Button>
        <a
          href="https://field-joke-7e5.notion.site/Thoughts-HTML-CSS-Cheatsheet-815523988121449fa0de4d45c9286d59"
          target="_blank"
        >
          <Button>Cheatsheet</Button>
        </a>
      </div>
      <div dangerouslySetInnerHTML={articleHtml}></div>
      <Modal show={showImageGallery} onHide={() => setShowImageGallery(false)} size="xl">
        <Modal.Header closeButton></Modal.Header>
        <ImageGallery closeModal={() => setShowImageGallery(false)} />
      </Modal>
    </div>
  );
}

export default observer(NewThought);
