export const countriesList = [
  { name: "Global", country_code: "GLOBAL" },
  { name: "United Kingdom", country_code: "GB", dial_iso: "+44", emoji: "🇬🇧", currency: "GBP" },
  { name: "United States", country_code: "US", dial_iso: "+1", emoji: "🇺🇸", currency: "USD" },
  { name: "Afghanistan", country_code: "AF", dial_iso: "+93", emoji: "🇦🇫", currency: "AFN" },
  { name: "Aland Islands", country_code: "AX", dial_iso: "+358", emoji: "🇦🇽", currency: "EUR" },
  { name: "Albania", country_code: "AL", dial_iso: "+355", emoji: "🇦🇱", currency: "ALL" },
  { name: "Algeria", country_code: "DZ", dial_iso: "+213", emoji: "🇩🇿", currency: "DZD" },
  { name: "AmericanSamoa", country_code: "AS", dial_iso: "+1 684", emoji: "🇦🇸", currency: "USD" },
  { name: "Andorra", country_code: "AD", dial_iso: "+376", emoji: "🇦🇩", currency: "EUR" },
  { name: "Angola", country_code: "AO", dial_iso: "+244", emoji: "🇦🇴", currency: "AOA" },
  { name: "Anguilla", country_code: "AI", dial_iso: "+1 264", emoji: "🇦🇮", currency: "XCD" },
  { name: "Antarctica", country_code: "AQ", dial_iso: "+672", emoji: "🇦🇶", currency: "" },
  { name: "Antigua and Barbuda", country_code: "AG", dial_iso: "+1268", emoji: "🇦🇬", currency: "XCD" },
  { name: "Argentina", country_code: "AR", dial_iso: "+54", emoji: "🇦🇷", currency: "ARS" },
  { name: "Armenia", country_code: "AM", dial_iso: "+374", emoji: "🇦🇲", currency: "AMD" },
  { name: "Aruba", country_code: "AW", dial_iso: "+297", emoji: "🇦🇼", currency: "AWG" },
  { name: "Australia", country_code: "AU", dial_iso: "+61", emoji: "🇦🇺", currency: "AUD" },
  { name: "Austria", country_code: "AT", dial_iso: "+43", emoji: "🇦🇹", currency: "EUR" },
  { name: "Azerbaijan", country_code: "AZ", dial_iso: "+994", emoji: "🇦🇿", currency: "AZN" },
  { name: "Bahamas", country_code: "BS", dial_iso: "+1 242", emoji: "🇧🇸", currency: "BSD" },
  { name: "Bahrain", country_code: "BH", dial_iso: "+973", emoji: "🇧🇭", currency: "BHD" },
  { name: "Bangladesh", country_code: "BD", dial_iso: "+880", emoji: "🇧🇩", currency: "BDT" },
  { name: "Barbados", country_code: "BB", dial_iso: "+1 246", emoji: "🇧🇧", currency: "BBD" },
  { name: "Belarus", country_code: "BY", dial_iso: "+375", emoji: "🇧🇾", currency: "BYR" },
  { name: "Belgium", country_code: "BE", dial_iso: "+32", emoji: "🇧🇪", currency: "EUR" },
  { name: "Belize", country_code: "BZ", dial_iso: "+501", emoji: "🇧🇿", currency: "BZD" },
  { name: "Benin", country_code: "BJ", dial_iso: "+229", emoji: "🇧🇯", currency: "XOF" },
  { name: "Bermuda", country_code: "BM", dial_iso: "+1 441", emoji: "🇧🇲", currency: "BMD" },
  { name: "Bhutan", country_code: "BT", dial_iso: "+975", emoji: "🇧🇹", currency: "BTN" },
  {
    name: "Bolivia, Plurinational State of",
    country_code: "BO",
    dial_iso: "+591",
    emoji: "🇧🇴",
    currency: "BOB",
  },
  { name: "Bosnia and Herzegovina", country_code: "BA", dial_iso: "+387", emoji: "🇧🇦", currency: "BAM" },
  { name: "Botswana", country_code: "BW", dial_iso: "+267", emoji: "🇧🇼", currency: "BWP" },
  { name: "Brazil", country_code: "BR", dial_iso: "+55", emoji: "🇧🇷", currency: "BRL" },
  {
    name: "British Indian Ocean Territory",
    country_code: "IO",
    dial_iso: "+246",
    emoji: "🇮🇴",
    currency: "USD",
  },
  { name: "Brunei Darussalam", country_code: "BN", dial_iso: "+673", emoji: "🇧🇳", currency: "BND" },
  { name: "Bulgaria", country_code: "BG", dial_iso: "+359", emoji: "🇧🇬", currency: "BGN" },
  { name: "Burkina Faso", country_code: "BF", dial_iso: "+226", emoji: "🇧🇫", currency: "XOF" },
  { name: "Burundi", country_code: "BI", dial_iso: "+257", emoji: "🇧🇮", currency: "BIF" },
  { name: "Cambodia", country_code: "KH", dial_iso: "+855", emoji: "🇰🇭", currency: "KHR" },
  { name: "Cameroon", country_code: "CM", dial_iso: "+237", emoji: "🇨🇲", currency: "XAF" },
  { name: "Canada", country_code: "CA", dial_iso: "+1", emoji: "🇨🇦", currency: "CAD" },
  { name: "Cape Verde", country_code: "CV", dial_iso: "+238", emoji: "🇨🇻", currency: "CVE" },
  { name: "Cayman Islands", country_code: "KY", dial_iso: "+ 345", emoji: "🇰🇾", currency: "KYD" },
  { name: "Central African Republic", country_code: "CF", dial_iso: "+236", emoji: "🇨🇫", currency: "XAF" },
  { name: "Chad", country_code: "TD", dial_iso: "+235", emoji: "🇹🇩", currency: "XAF" },
  { name: "Chile", country_code: "CL", dial_iso: "+56", emoji: "🇨🇱", currency: "CLP" },
  { name: "China", country_code: "CN", dial_iso: "+86", emoji: "🇨🇳", currency: "CNY" },
  { name: "Christmas Island", country_code: "CX", dial_iso: "+61", emoji: "🇨🇽", currency: "AUD" },
  { name: "Cocos (Keeling) Islands", country_code: "CC", dial_iso: "+61", emoji: "🇨🇨", currency: "AUD" },
  { name: "Colombia", country_code: "CO", dial_iso: "+57", emoji: "🇨🇴", currency: "COP" },
  { name: "Comoros", country_code: "KM", dial_iso: "+269", emoji: "🇰🇲", currency: "KMF" },
  { name: "Congo", country_code: "CG", dial_iso: "+242", emoji: "🇨🇬", currency: "XAF" },
  {
    name: "Congo, The Democratic Republic of the Congo",
    country_code: "CD",
    dial_iso: "+243",
    emoji: "🇨🇩",
    currency: "CDF",
  },
  { name: "Cook Islands", country_code: "CK", dial_iso: "+682", emoji: "🇨🇰", currency: "NZD" },
  { name: "Costa Rica", country_code: "CR", dial_iso: "+506", emoji: "🇨🇷", currency: "CRC" },
  { name: "Cote d'Ivoire", country_code: "CI", dial_iso: "+225", emoji: "🇨🇮", currency: "XOF" },
  { name: "Croatia", country_code: "HR", dial_iso: "+385", emoji: "🇭🇷", currency: "HRK" },
  { name: "Cuba", country_code: "CU", dial_iso: "+53", emoji: "🇨🇺", currency: "CUP" },
  { name: "Cyprus", country_code: "CY", dial_iso: "+357", emoji: "🇨🇾", currency: "EUR" },
  { name: "Czech Republic", country_code: "CZ", dial_iso: "+420", emoji: "🇨🇿", currency: "CZK" },
  { name: "Denmark", country_code: "DK", dial_iso: "+45", emoji: "🇩🇰", currency: "DKK" },
  { name: "Djibouti", country_code: "DJ", dial_iso: "+253", emoji: "🇩🇯", currency: "DJF" },
  { name: "Dominica", country_code: "DM", dial_iso: "+1 767", emoji: "🇩🇲", currency: "XCD" },
  { name: "Dominican Republic", country_code: "DO", dial_iso: "+1 849", emoji: "🇩🇴", currency: "DOP" },
  { name: "Ecuador", country_code: "EC", dial_iso: "+593", emoji: "🇪🇨", currency: "USD" },
  { name: "Egypt", country_code: "EG", dial_iso: "+20", emoji: "🇪🇬", currency: "EGP" },
  { name: "El Salvador", country_code: "SV", dial_iso: "+503", emoji: "🇸🇻", currency: "USD" },
  { name: "Equatorial Guinea", country_code: "GQ", dial_iso: "+240", emoji: "🇬🇶", currency: "XAF" },
  { name: "Eritrea", country_code: "ER", dial_iso: "+291", emoji: "🇪🇷", currency: "ERN" },
  { name: "Estonia", country_code: "EE", dial_iso: "+372", emoji: "🇪🇪", currency: "EUR" },
  { name: "Ethiopia", country_code: "ET", dial_iso: "+251", emoji: "🇪🇹", currency: "ETB" },
  { name: "Falkland Islands (Malvinas)", country_code: "FK", dial_iso: "+500", emoji: "🇫🇰", currency: "FKP" },
  { name: "Faroe Islands", country_code: "FO", dial_iso: "+298", emoji: "🇫🇴", currency: "DKK" },
  { name: "Fiji", country_code: "FJ", dial_iso: "+679", emoji: "🇫🇯", currency: "FJD" },
  { name: "Finland", country_code: "FI", dial_iso: "+358", emoji: "🇫🇮", currency: "EUR" },
  { name: "France", country_code: "FR", dial_iso: "+33", emoji: "🇫🇷", currency: "EUR" },
  { name: "French Guiana", country_code: "GF", dial_iso: "+594", emoji: "🇬🇫", currency: "EUR" },
  { name: "French Polynesia", country_code: "PF", dial_iso: "+689", emoji: "🇵🇫", currency: "XPF" },
  { name: "Gabon", country_code: "GA", dial_iso: "+241", emoji: "🇬🇦", currency: "XAF" },
  { name: "Gambia", country_code: "GM", dial_iso: "+220", emoji: "🇬🇲", currency: "GMD" },
  { name: "Georgia", country_code: "GE", dial_iso: "+995", emoji: "🇬🇪", currency: "GEL" },
  { name: "Germany", country_code: "DE", dial_iso: "+49", emoji: "🇩🇪", currency: "EUR" },
  { name: "Ghana", country_code: "GH", dial_iso: "+233", emoji: "🇬🇭", currency: "GHS" },
  { name: "Gibraltar", country_code: "GI", dial_iso: "+350", emoji: "🇬🇮", currency: "GIP" },
  { name: "Greece", country_code: "GR", dial_iso: "+30", emoji: "🇬🇷", currency: "EUR" },
  { name: "Greenland", country_code: "GL", dial_iso: "+299", emoji: "🇬🇱", currency: "DKK" },
  { name: "Grenada", country_code: "GD", dial_iso: "+1 473", emoji: "🇬🇩", currency: "XCD" },
  { name: "Guadeloupe", country_code: "GP", dial_iso: "+590", emoji: "🇬🇵", currency: "EUR" },
  { name: "Guam", country_code: "GU", dial_iso: "+1 671", emoji: "🇬🇺", currency: "USD" },
  { name: "Guatemala", country_code: "GT", dial_iso: "+502", emoji: "🇬🇹", currency: "GTQ" },
  { name: "Guernsey", country_code: "GG", dial_iso: "+44", emoji: "🇬🇬", currency: "GBP" },
  { name: "Guinea", country_code: "GN", dial_iso: "+224", emoji: "🇬🇳", currency: "GNF" },
  { name: "Guinea-Bissau", country_code: "GW", dial_iso: "+245", emoji: "🇬🇼", currency: "XOF" },
  { name: "Guyana", country_code: "GY", dial_iso: "+595", emoji: "🇬🇾", currency: "GYD" },
  { name: "Haiti", country_code: "HT", dial_iso: "+509", emoji: "🇭🇹", currency: "HTG" },
  {
    name: "Holy See (Vatican City State)",
    country_code: "VA",
    dial_iso: "+379",
    emoji: "🇻🇦",
    currency: "EUR",
  },
  { name: "Honduras", country_code: "HN", dial_iso: "+504", emoji: "🇭🇳", currency: "HNL" },
  { name: "Hong Kong", country_code: "HK", dial_iso: "+852", emoji: "🇭🇰", currency: "HKD" },
  { name: "Hungary", country_code: "HU", dial_iso: "+36", emoji: "🇭🇺", currency: "HUF" },
  { name: "Iceland", country_code: "IS", dial_iso: "+354", emoji: "🇮🇸", currency: "ISK" },
  { name: "India", country_code: "IN", dial_iso: "+91", emoji: "🇮🇳", currency: "INR" },
  { name: "Indonesia", country_code: "ID", dial_iso: "+62", emoji: "🇮🇩", currency: "IDR" },
  {
    name: "Iran, Islamic Republic of Persian Gulf",
    country_code: "IR",
    dial_iso: "+98",
    emoji: "🇮🇷",
    currency: "IRR",
  },
  { name: "Iraq", country_code: "IQ", dial_iso: "+964", emoji: "🇮🇶", currency: "IQD" },
  { name: "Ireland", country_code: "IE", dial_iso: "+353", emoji: "🇮🇪", currency: "EUR" },
  { name: "Isle of Man", country_code: "IM", dial_iso: "+44", emoji: "🇮🇲", currency: "GBP" },
  { name: "Israel", country_code: "IL", dial_iso: "+972", emoji: "🇮🇱", currency: "ILS" },
  { name: "Italy", country_code: "IT", dial_iso: "+39", emoji: "🇮🇹", currency: "EUR" },
  { name: "Jamaica", country_code: "JM", dial_iso: "+1 876", emoji: "🇯🇲", currency: "JMD" },
  { name: "Japan", country_code: "JP", dial_iso: "+81", emoji: "🇯🇵", currency: "JPY" },
  { name: "Jersey", country_code: "JE", dial_iso: "+44", emoji: "🇯🇪", currency: "GBP" },
  { name: "Jordan", country_code: "JO", dial_iso: "+962", emoji: "🇯🇴", currency: "JOD" },
  { name: "Kazakhstan", country_code: "KZ", dial_iso: "+7 7", emoji: "🇰🇿", currency: "KZT" },
  { name: "Kenya", country_code: "KE", dial_iso: "+254", emoji: "🇰🇪", currency: "KES" },
  { name: "Kiribati", country_code: "KI", dial_iso: "+686", emoji: "🇰🇮", currency: "AUD" },
  {
    name: "Korea, Democratic People's Republic of Korea",
    country_code: "KP",
    dial_iso: "+850",
    emoji: "🇰🇵",
    currency: "KPW",
  },
  {
    name: "Korea, Republic of South Korea",
    country_code: "KR",
    dial_iso: "+82",
    emoji: "🇰🇷",
    currency: "KRW",
  },
  { name: "Kosovo", country_code: "XK", dial_iso: "+383", emoji: "🇽🇰", currency: "EUR" },
  { name: "Kuwait", country_code: "KW", dial_iso: "+965", emoji: "🇰🇼", currency: "KWD" },
  { name: "Kyrgyzstan", country_code: "KG", dial_iso: "+996", emoji: "🇰🇬", currency: "KGS" },
  { name: "Laos", country_code: "LA", dial_iso: "+856", emoji: "🇱🇦", currency: "LAK" },
  { name: "Latvia", country_code: "LV", dial_iso: "+371", emoji: "🇱🇻", currency: "EUR" },
  { name: "Lebanon", country_code: "LB", dial_iso: "+961", emoji: "🇱🇧", currency: "LBP" },
  { name: "Lesotho", country_code: "LS", dial_iso: "+266", emoji: "🇱🇸", currency: "LSL" },
  { name: "Liberia", country_code: "LR", dial_iso: "+231", emoji: "🇱🇷", currency: "LRD" },
  { name: "Libyan Arab Jamahiriya", country_code: "LY", dial_iso: "+218", emoji: "🇱🇾", currency: "LYD" },
  { name: "Liechtenstein", country_code: "LI", dial_iso: "+423", emoji: "🇱🇮", currency: "CHF" },
  { name: "Lithuania", country_code: "LT", dial_iso: "+370", emoji: "🇱🇹", currency: "EUR" },
  { name: "Luxembourg", country_code: "LU", dial_iso: "+352", emoji: "🇱🇺", currency: "EUR" },
  { name: "Macao", country_code: "MO", dial_iso: "+853", emoji: "🇲🇴", currency: "MOP" },
  { name: "Macedonia", country_code: "MK", dial_iso: "+389", emoji: "🇲🇰", currency: "MKD" },
  { name: "Madagascar", country_code: "MG", dial_iso: "+261", emoji: "🇲🇬", currency: "MGA" },
  { name: "Malawi", country_code: "MW", dial_iso: "+265", emoji: "🇲🇼", currency: "MWK" },
  { name: "Malaysia", country_code: "MY", dial_iso: "+60", emoji: "🇲🇾", currency: "MYR" },
  { name: "Maldives", country_code: "MV", dial_iso: "+960", emoji: "🇲🇻", currency: "MVR" },
  { name: "Mali", country_code: "ML", dial_iso: "+223", emoji: "🇲🇱", currency: "XOF" },
  { name: "Malta", country_code: "MT", dial_iso: "+356", emoji: "🇲🇹", currency: "EUR" },
  { name: "Marshall Islands", country_code: "MH", dial_iso: "+692", emoji: "🇲🇭", currency: "USD" },
  { name: "Martinique", country_code: "MQ", dial_iso: "+596", emoji: "🇲🇶", currency: "EUR" },
  { name: "Mauritania", country_code: "MR", dial_iso: "+222", emoji: "🇲🇷", currency: "MRO" },
  { name: "Mauritius", country_code: "MU", dial_iso: "+230", emoji: "🇲🇺", currency: "MUR" },
  { name: "Mayotte", country_code: "YT", dial_iso: "+262", emoji: "🇾🇹", currency: "EUR" },
  { name: "Mexico", country_code: "MX", dial_iso: "+52", emoji: "🇲🇽", currency: "MXN" },
  {
    name: "Micronesia, Federated States of Micronesia",
    country_code: "FM",
    dial_iso: "+691",
    emoji: "🇫🇲",
    currency: "USD",
  },
  { name: "Moldova", country_code: "MD", dial_iso: "+373", emoji: "🇲🇩", currency: "MDL" },
  { name: "Monaco", country_code: "MC", dial_iso: "+377", emoji: "🇲🇨", currency: "EUR" },
  { name: "Mongolia", country_code: "MN", dial_iso: "+976", emoji: "🇲🇳", currency: "MNT" },
  { name: "Montenegro", country_code: "ME", dial_iso: "+382", emoji: "🇲🇪", currency: "EUR" },
  { name: "Montserrat", country_code: "MS", dial_iso: "+1664", emoji: "🇲🇸", currency: "XCD" },
  { name: "Morocco", country_code: "MA", dial_iso: "+212", emoji: "🇲🇦", currency: "MAD" },
  { name: "Mozambique", country_code: "MZ", dial_iso: "+258", emoji: "🇲🇿", currency: "MZN" },
  { name: "Myanmar", country_code: "MM", dial_iso: "+95", emoji: "🇲🇲", currency: "MMK" },
  { name: "Namibia", country_code: "NA", dial_iso: "+264", emoji: "🇳🇦", currency: "NAD" },
  { name: "Nauru", country_code: "NR", dial_iso: "+674", emoji: "🇳🇷", currency: "AUD" },
  { name: "Nepal", country_code: "NP", dial_iso: "+977", emoji: "🇳🇵", currency: "NPR" },
  { name: "Netherlands", country_code: "NL", dial_iso: "+31", emoji: "🇳🇱", currency: "EUR" },
  { name: "Netherlands Antilles", country_code: "AN", dial_iso: "+599", emoji: "🇧🇶" },
  { name: "New Caledonia", country_code: "NC", dial_iso: "+687", emoji: "🇳🇨", currency: "XPF" },
  { name: "New Zealand", country_code: "NZ", dial_iso: "+64", emoji: "🇳🇿", currency: "NZD" },
  { name: "Nicaragua", country_code: "NI", dial_iso: "+505", emoji: "🇳🇮", currency: "NIO" },
  { name: "Niger", country_code: "NE", dial_iso: "+227", emoji: "🇳🇪", currency: "XOF" },
  { name: "Nigeria", country_code: "NG", dial_iso: "+234", emoji: "🇳🇬", currency: "NGN" },
  { name: "Niue", country_code: "NU", dial_iso: "+683", emoji: "🇳🇺", currency: "NZD" },
  { name: "Norfolk Island", country_code: "NF", dial_iso: "+672", emoji: "🇳🇫", currency: "AUD" },
  { name: "Northern Mariana Islands", country_code: "MP", dial_iso: "+1 670", emoji: "🇲🇵", currency: "USD" },
  { name: "Norway", country_code: "NO", dial_iso: "+47", emoji: "🇳🇴", currency: "NOK" },
  { name: "Oman", country_code: "OM", dial_iso: "+968", emoji: "🇴🇲", currency: "OMR" },
  { name: "Pakistan", country_code: "PK", dial_iso: "+92", emoji: "🇵🇰", currency: "PKR" },
  { name: "Palau", country_code: "PW", dial_iso: "+680", emoji: "🇵🇼", currency: "USD" },
  {
    name: "Palestinian Territory, Occupied",
    country_code: "PS",
    dial_iso: "+970",
    emoji: "🇵🇸",
    currency: "ILS",
  },
  { name: "Panama", country_code: "PA", dial_iso: "+507", emoji: "🇵🇦", currency: "PAB" },
  { name: "Papua New Guinea", country_code: "PG", dial_iso: "+675", emoji: "🇵🇬", currency: "PGK" },
  { name: "Paraguay", country_code: "PY", dial_iso: "+595", emoji: "🇵🇾", currency: "PYG" },
  { name: "Peru", country_code: "PE", dial_iso: "+51", emoji: "🇵🇪", currency: "PEN" },
  { name: "Philippines", country_code: "PH", dial_iso: "+63", emoji: "🇵🇭", currency: "PHP" },
  { name: "Pitcairn", country_code: "PN", dial_iso: "+872", emoji: "🇵🇳", currency: "NZD" },
  { name: "Poland", country_code: "PL", dial_iso: "+48", emoji: "🇵🇱", currency: "PLN" },
  { name: "Portugal", country_code: "PT", dial_iso: "+351", emoji: "🇵🇹", currency: "EUR" },
  { name: "Puerto Rico", country_code: "PR", dial_iso: "+1 939", emoji: "🇵🇷", currency: "USD" },
  { name: "Qatar", country_code: "QA", dial_iso: "+974", emoji: "🇶🇦", currency: "QAR" },
  { name: "Romania", country_code: "RO", dial_iso: "+40", emoji: "🇷🇴", currency: "RON" },
  { name: "Russia", country_code: "RU", dial_iso: "+7", emoji: "🇷🇺", currency: "RUB" },
  { name: "Rwanda", country_code: "RW", dial_iso: "+250", emoji: "🇷🇼", currency: "RWF" },
  { name: "Reunion", country_code: "RE", dial_iso: "+262", emoji: "🇷🇪", currency: "EUR" },
  { name: "Saint Barthelemy", country_code: "BL", dial_iso: "+590", emoji: "🇧🇱", currency: "EUR" },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    country_code: "SH",
    dial_iso: "+290",
    emoji: "🇸🇭",
    currency: "SHP",
  },
  { name: "Saint Kitts and Nevis", country_code: "KN", dial_iso: "+1 869", emoji: "🇰🇳", currency: "XCD" },
  { name: "Saint Lucia", country_code: "LC", dial_iso: "+1 758", emoji: "🇱🇨", currency: "XCD" },
  { name: "Saint Martin", country_code: "MF", dial_iso: "+590", emoji: "🇲🇫", currency: "EUR" },
  { name: "Saint Pierre and Miquelon", country_code: "PM", dial_iso: "+508", emoji: "🇵🇲", currency: "EUR" },
  {
    name: "Saint Vincent and the Grenadines",
    country_code: "VC",
    dial_iso: "+1 784",
    emoji: "🇻🇨",
    currency: "XCD",
  },
  { name: "Samoa", country_code: "WS", dial_iso: "+685", emoji: "🇼🇸", currency: "WST" },
  { name: "San Marino", country_code: "SM", dial_iso: "+378", emoji: "🇸🇲", currency: "EUR" },
  { name: "Sao Tome and Principe", country_code: "ST", dial_iso: "+239", emoji: "🇸🇹", currency: "STD" },
  { name: "Saudi Arabia", country_code: "SA", dial_iso: "+966", emoji: "🇸🇦", currency: "SAR" },
  { name: "Senegal", country_code: "SN", dial_iso: "+221", emoji: "🇸🇳", currency: "XOF" },
  { name: "Serbia", country_code: "RS", dial_iso: "+381", emoji: "🇷🇸", currency: "RSD" },
  { name: "Seychelles", country_code: "SC", dial_iso: "+248", emoji: "🇸🇨", currency: "SCR" },
  { name: "Sierra Leone", country_code: "SL", dial_iso: "+232", emoji: "🇸🇱", currency: "SLL" },
  { name: "Singapore", country_code: "SG", dial_iso: "+65", emoji: "🇸🇬", currency: "SGD" },
  { name: "Slovakia", country_code: "SK", dial_iso: "+421", emoji: "🇸🇰", currency: "EUR" },
  { name: "Slovenia", country_code: "SI", dial_iso: "+386", emoji: "🇸🇮", currency: "EUR" },
  { name: "Solomon Islands", country_code: "SB", dial_iso: "+677", emoji: "🇸🇧", currency: "SBD" },
  { name: "Somalia", country_code: "SO", dial_iso: "+252", emoji: "🇸🇴", currency: "SOS" },
  { name: "South Africa", country_code: "ZA", dial_iso: "+27", emoji: "🇿🇦", currency: "ZAR" },
  {
    name: "South Georgia and the South Sandwich Islands",
    country_code: "GS",
    dial_iso: "+500",
    emoji: "🇬🇸",
    currency: "GBP",
  },
  { name: "Spain", country_code: "ES", dial_iso: "+34", emoji: "🇪🇸", currency: "EUR" },
  { name: "Sri Lanka", country_code: "LK", dial_iso: "+94", emoji: "🇱🇰", currency: "LKR" },
  { name: "Sudan", country_code: "SD", dial_iso: "+249", emoji: "🇸🇩", currency: "SDG" },
  { name: "Suriname", country_code: "SR", dial_iso: "+597", emoji: "🇸🇷", currency: "SRD" },
  { name: "Svalbard and Jan Mayen", country_code: "SJ", dial_iso: "+47", emoji: "🇸🇯", currency: "NOK" },
  { name: "Swaziland", country_code: "SZ", dial_iso: "+268", emoji: "🇸🇿", currency: "SZL" },
  { name: "Sweden", country_code: "SE", dial_iso: "+46", emoji: "🇸🇪", currency: "SEK" },
  { name: "Switzerland", country_code: "CH", dial_iso: "+41", emoji: "🇨🇭", currency: "CHF" },
  { name: "Syrian Arab Republic", country_code: "SY", dial_iso: "+963", emoji: "🇸🇾", currency: "SYP" },
  { name: "Taiwan", country_code: "TW", dial_iso: "+886", emoji: "🇹🇼", currency: "TWD" },
  { name: "Tajikistan", country_code: "TJ", dial_iso: "+992", emoji: "🇹🇯", currency: "TJS" },
  {
    name: "Tanzania, United Republic of Tanzania",
    country_code: "TZ",
    dial_iso: "+255",
    emoji: "🇹🇿",
    currency: "TZS",
  },
  { name: "Thailand", country_code: "TH", dial_iso: "+66", emoji: "🇹🇭", currency: "THB" },
  { name: "Timor-Leste", country_code: "TL", dial_iso: "+670", emoji: "🇹🇱", currency: "USD" },
  { name: "Togo", country_code: "TG", dial_iso: "+228", emoji: "🇹🇬", currency: "XOF" },
  { name: "Tokelau", country_code: "TK", dial_iso: "+690", emoji: "🇹🇰", currency: "NZD" },
  { name: "Tonga", country_code: "TO", dial_iso: "+676", emoji: "🇹🇴", currency: "TOP" },
  { name: "Trinidad and Tobago", country_code: "TT", dial_iso: "+1 868", emoji: "🇹🇹", currency: "TTD" },
  { name: "Tunisia", country_code: "TN", dial_iso: "+216", emoji: "🇹🇳", currency: "TND" },
  { name: "Turkey", country_code: "TR", dial_iso: "+90", emoji: "🇹🇷", currency: "TRY" },
  { name: "Turkmenistan", country_code: "TM", dial_iso: "+993", emoji: "🇹🇲", currency: "TMT" },
  { name: "Turks and Caicos Islands", country_code: "TC", dial_iso: "+1 649", emoji: "🇹🇨", currency: "USD" },
  { name: "Tuvalu", country_code: "TV", dial_iso: "+688", emoji: "🇹🇻", currency: "AUD" },
  { name: "Uganda", country_code: "UG", dial_iso: "+256", emoji: "🇺🇬", currency: "UGX" },
  { name: "Ukraine", country_code: "UA", dial_iso: "+380", emoji: "🇺🇦", currency: "UAH" },
  { name: "United Arab Emirates", country_code: "AE", dial_iso: "+971", emoji: "🇦🇪", currency: "AED" },
  { name: "Uruguay", country_code: "UY", dial_iso: "+598", emoji: "🇺🇾", currency: "UYU" },
  { name: "Uzbekistan", country_code: "UZ", dial_iso: "+998", emoji: "🇺🇿", currency: "UZS" },
  { name: "Vanuatu", country_code: "VU", dial_iso: "+678", emoji: "🇻🇺", currency: "VUV" },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    country_code: "VE",
    dial_iso: "+58",
    emoji: "🇻🇪",
    currency: "VEF",
  },
  { name: "Vietnam", country_code: "VN", dial_iso: "+84", emoji: "🇻🇳", currency: "VND" },
  { name: "Virgin Islands, British", country_code: "VG", dial_iso: "+1 284", emoji: "🇻🇬", currency: "USD" },
  { name: "Virgin Islands, U.S.", country_code: "VI", dial_iso: "+1 340", emoji: "🇻🇮", currency: "USD" },
  { name: "Wallis and Futuna", country_code: "WF", dial_iso: "+681", emoji: "🇼🇫", currency: "XPF" },
  { name: "Yemen", country_code: "YE", dial_iso: "+967", emoji: "🇾🇪", currency: "YER" },
  { name: "Zambia", country_code: "ZM", dial_iso: "+260", emoji: "🇿🇲", currency: "ZMW" },
  { name: "Zimbabwe", country_code: "ZW", dial_iso: "+263", emoji: "🇿🇼", currency: "ZWL" },
];
