import { flow, makeObservable, observable } from "mobx";
import ApiBasedStore from "./ApiBasedStore";

export default class UserStore extends ApiBasedStore {
  userApi;
  users;
  link;

  constructor(rootStore, userApi) {
    super(rootStore);
    this.userApi = userApi;
    this.users = [];
    this.link = "";

    makeObservable(this, {
      users: observable,
      link: observable,
    });
  }

  find = flow(function* (email) {
    this.startRequest();

    try {
      const result = yield this.userApi.find(email);
      this.users = result;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  update = flow(function* (uid, email) {
    this.startRequest();

    try {
      yield this.userApi.update(uid, email);
      const user = this.users.find((u) => u.uid === uid);
      user.email = email;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  delete = flow(function* (uid) {
    this.startRequest();

    try {
      yield this.userApi.delete(uid);
      this.users = [];
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  clearOnboardingData = flow(function* (email) {
    this.startRequest();

    try {
      yield this.userApi.deleteOnboarding(email);
      this.users = [];
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  passwordReset = flow(function* (email) {
    this.startRequest();

    try {
      const result = yield this.userApi.generatePasswordResetLink(email);
      this.link = result.password_reset_url;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });
}
