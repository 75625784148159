import { collection, getDocs, query, where } from "firebase/firestore";
import { useState } from "react";
import { firestore } from "../../../config/firebase";

export default function OutfitsHarness() {
  const [userId, setUserId] = useState("ezxQEkV232aLJSsmNpCH05kNPoH3");
  const [outfits, setOutfits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getUserOutfits = async () => {
    setIsLoading(true);
    const outfitsRef = collection(firestore, "outfits");
    const outfitsQuery = query(outfitsRef, where("user_id", "==", userId));
    const outfitsSnap = await getDocs(outfitsQuery);

    setOutfits(outfitsSnap.docs.map((snap) => ({ id: snap.id, ...snap.data() })));
    setIsLoading(false);
  };

  if (isLoading) {
    return <p>...loading</p>;
  }

  return (
    <div style={{ margin: 10 }}>
      <h1>Outfit Harness</h1>
      <div>
        <div>
          <input placeholder="Enter user id" value={userId} onChange={(el) => setUserId(el.target.value)} />
        </div>

        <button onClick={getUserOutfits}>Submit</button>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
        {outfits.map((outfit) => (
          <div key={outfit.id}>
            <img src={outfit.image_url} height={300} width={200} loading="lazy" />
            <p>
              <b>Outfit ID:</b> {outfit.id}
            </p>
            <p>
              <b>Pieces</b>
            </p>
            <div>
              {outfit.pieces.map((piece) => (
                <div key={piece.piece_id}>
                  <p>{piece.piece_id}</p>
                  <img src={piece.image_url} loading="lazy" height={100} width={100} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
