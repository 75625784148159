import { CHER_BASE_URL, DIONNE_BASE_URL } from ".";
import wheringFetch from "../../helpers/fetch";

const route = "marketplace";
export const taxonomyRoute = "https://tai.whering.co.uk/style/v1/taxonomy";
const baseUrl = DIONNE_BASE_URL;

const marketplaceApi = {
  // products
  getProducts: async (query) => {
    if (query) {
      return wheringFetch(baseUrl, `${route}/products${query}`, "GET");
    } else {
      return wheringFetch(baseUrl, `${route}/products/`, "GET");
    }
  },
  getProductsWithQuery: async (query) => {
    return wheringFetch(baseUrl, `${route}/products${query}`, "GET");
  },
  getOneProduct: async (id) => {
    return wheringFetch(baseUrl, `${route}/products/${id}`, "GET");
  },
  createProduct: async (data) => {
    return wheringFetch(baseUrl, `${route}/products/`, "POST", data);
  },
  createManyProducts: async (data) => {
    return wheringFetch(baseUrl, `${route}/products/bulk_upload`, "POST", data);
  },
  updateProduct: async (productID, data) => {
    return wheringFetch(baseUrl, `${route}/products/${productID}`, "PATCH", data);
  },
  deleteProduct: async (productID, data) => {
    return wheringFetch(baseUrl, `${route}/products/${productID}`, "DELETE");
  },

  // edits
  getEdits: async () => {
    return wheringFetch(baseUrl, `${route}/edits?limit=9999`, "GET");
  },
  createEdit: async (data) => {
    return wheringFetch(baseUrl, `${route}/edits`, "POST", data);
  },
  updateEdit: async (editID, data) => {
    return wheringFetch(baseUrl, `${route}/edits/${editID}`, "PATCH", data);
  },
  deleteEdit: async (editID) => {
    return wheringFetch(baseUrl, `${route}/edits/${editID}`, "DELETE");
  },

  // services
  getServices: async () => {
    return wheringFetch(CHER_BASE_URL, `${route}/service`, "GET");
  },
  createService: async (data) => {
    return wheringFetch(CHER_BASE_URL, `${route}/service`, "POST", data);
  },
  updateService: async (serviceID, data) => {
    return wheringFetch(CHER_BASE_URL, `${route}/service/${serviceID}`, "PATCH", data);
  },
  deleteService: async (serviceID) => {
    return wheringFetch(CHER_BASE_URL, `${route}/service/${serviceID}`, "DELETE");
  },

  // categories
  getCategories: async (query) => {
    if (query) {
      return wheringFetch(baseUrl, `${route}/category${query}`, "GET");
    } else {
      return wheringFetch(baseUrl, `${route}/category`, "GET");
    }
  },
  updateCategory: async (categoryID, data) => {
    return wheringFetch(baseUrl, `${route}/category/${categoryID}`, "PATCH", data);
  },

  // spotlights
  getSpotlights: async () => {
    return wheringFetch(baseUrl, `${route}/spotlights`, "GET");
  },
  createSpotlight: async (data) => {
    return wheringFetch(baseUrl, `${route}/spotlights`, "POST", data);
  },
  updateSpotlight: async (spotlightID, data) => {
    return wheringFetch(baseUrl, `${route}/spotlights/${spotlightID}`, "PATCH", data);
  },

  // retailers
  getRetailers: async () => {
    return wheringFetch(baseUrl, `${route}/retailers?limit=1000`, "GET");
  },
  createRetailer: async (data) => {
    return wheringFetch(baseUrl, `${route}/retailers`, "POST", data);
  },
  updateRetailer: async (retailerID, data) => {
    return wheringFetch(baseUrl, `${route}/retailers/${retailerID}`, "PATCH", data);
  },
  deleteRetailer: async (retailerID) => {
    return wheringFetch(baseUrl, `${route}/retailers/${retailerID}`, "DELETE");
  },

  // brands
  getBrands: async () => {
    return wheringFetch(baseUrl, `${route}/brands?limit=1000`, "GET");
  },
};

export default marketplaceApi;
