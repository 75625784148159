import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { IoCopyOutline } from "react-icons/io5";
import { toast } from "react-toastify";
import TableWrapper from "../components/TableWrapper";
import { useStores } from "../context/mobx-context";
import { toBase64 } from "../helpers/image";

function ImageGallery({ closeModal }) {
  const { blogStore } = useStores();
  const inputRef = useRef(null);

  useEffect(() => {
    blogStore.getAllImages();
  }, []);

  const copyLink = async (url) => {
    await navigator.clipboard.writeText(url);
    toast("Link Copied", { type: "success" });
    if (closeModal) {
      closeModal();
    }
  };

  const copyHtml = async (url, filename) => {
    await navigator.clipboard.writeText(`<img src="${url}" alt="${filename}"  />`);
    toast("Image HTML Copied", { type: "success" });
    if (closeModal) {
      closeModal();
    }
  };

  const onChangeImage = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    const data = {
      filename: file.name,
      type: file.type,
      base64: await toBase64(file),
    };
    await blogStore.uploadImage(data);
    if (!blogStore.hasFailed) {
      toast("Image Saved!", { type: "success" });
      inputRef.current.value = "";
    }
  };

  return (
    <TableWrapper
      title="Thoughts - Images"
      showLoading={blogStore.isFetching}
      onAddNewClick={() => inputRef.current.click()}
    >
      <input type="file" id="file" ref={inputRef} style={{ display: "none" }} onChange={onChangeImage} />
      <Table className="model-table">
        <thead>
          <tr>
            <th></th>
            <th>Filename</th>
            <th>URL</th>
          </tr>
        </thead>
        <tbody>
          {blogStore.images.map((image) => (
            <tr key={image.id}>
              <td>
                <a href={image.imageUrl} target="_blank">
                  <img src={image.imageUrl} width={100} />
                </a>
              </td>
              <td>{image.filename}</td>
              <td className="image-link">
                <Button onClick={() => copyLink(image.imageUrl)}>Copy Link</Button>
                <Button onClick={() => copyHtml(image.imageUrl, image.filename)}>Copy HTML</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default observer(ImageGallery);
