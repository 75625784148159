import React, { useState, useEffect } from "react";
import { Alert, Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";
import { convertDate } from "../../helpers/dateConverter";
import { AddButton, RemoveButton } from "../../components/ActionButtons";

const initialState = {
  title: "",
  start_date: "",
  salary: "",
  responsibilities: [""],
  reports_to: "",
  preferred_attributes: [""],
  location: "",
  ideal_attributes: [""],
  description: "",
  category: "",
  tags: [""],
  active: true,
};

const categories = ["Tech", "Design", "Marketing", "Operations", "Finance"];

function NewJob({ match, history }) {
  const jobID = match.params.jobID;
  const { careerStore } = useStores();

  const [formState, setFormState] = useState(initialState);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    async function getData() {
      if (careerStore.jobs.length === 0) {
        await careerStore.fetchJobs();
      }
      if (jobID !== "new") {
        const job = careerStore.jobs.find((job) => job._id === jobID);

        setFormState({
          title: job.title,
          start_date: job.start_date,
          salary: job.salary,
          responsibilities: job.responsibilities,
          reports_to: job.reports_to,
          preferred_attributes: job.preferred_attributes,
          location: job.location,
          ideal_attributes: job.ideal_attributes,
          description: job.description,
          active: job.active,
          category: job.category,
          tags: job.tags,
          created_at: job.created_at,
          updated_at: job.updated_at,
        });
      } else {
        setFormState(initialState);
      }
    }

    getData();
  }, [jobID]);

  const handleFormChange = (e, index) => {
    if (index === undefined) {
      const { name, value } = e.target;
      setFormState((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      let newFormValues = { ...formState };
      newFormValues[e.target.name][index] = e.target.value;
      setFormState(newFormValues);
    }
  };

  let addFormFields = (name) => {
    setFormState({ ...formState, [name]: [...formState[name], ""] });
  };

  let removeFormFields = (i, name) => {
    let newFormValues = { ...formState };
    newFormValues[name].splice(i, 1);
    setFormState(newFormValues);
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const data = {
      ...formState,
      start_date: formState.start_date ? convertDate(formState.start_date) : "ASAP",
    };

    if (jobID === "new") {
      await careerStore.create(data);
      if (!careerStore.hasFailed) {
        toast("Job Created", { type: "success" });
        history.push("/careers");
      }
    } else {
      await careerStore.update(jobID, data);
      if (!careerStore.hasFailed) {
        history.goBack();
        toast("Job Updated", { type: "success" });
      }
    }
  };

  const handleDelete = async (event) => {
    event.preventDefault();
    await careerStore.delete(jobID);
    if (!careerStore.hasFailed) {
      toast("Job Deleted", { type: "success" });
      window.location.href = `/careers`;
    }
  };

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <h1>{jobID === "new" ? "Add " : "Amend "} a Job</h1>
      <Form className="model-form" onSubmit={handleSave}>
        <div className="row">
          <div className="col-lg-6 mt-5 mb-2">
            <Form.Check
              type="switch"
              label={formState.active ? "Deactivate this job" : "Activate this job"}
              className="text-large fw-bolder"
              checked={formState.active}
              value="1"
              onChange={(e) => setFormState({ ...formState, active: e.currentTarget.checked })}
            />
          </div>
        </div>
        <div className="row justify-content-between align-items-start">
          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <Form.Label>Job Title</Form.Label>
              <Form.Control
                placeholder="Enter the job title"
                name="title"
                required
                value={formState.title}
                onChange={handleFormChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Job category</Form.Label>
              <Form.Select
                name="category"
                className="d-block"
                required
                onChange={handleFormChange}
                value={formState.category}
              >
                <option value="">Please select a category</option>
                {categories.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Reports to</Form.Label>
              <Form.Control
                placeholder="Enter who employee reports to"
                name="reports_to"
                required
                value={formState.reports_to}
                onChange={handleFormChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Job Description</Form.Label>
              <Form.Control
                placeholder="Enter the job description"
                name="description"
                required
                value={formState.description}
                onChange={handleFormChange}
                as="textarea"
                rows={5}
              />
            </Form.Group>
          </div>

          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <Form.Label>Start Date (Leave empty if start date is ASAP)</Form.Label>
              <Form.Control
                placeholder="Select the start date"
                name="start_date"
                value={formState.start_date === "ASAP" ? "" : formState.start_date}
                onChange={handleFormChange}
                type="date"
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Salary</Form.Label>
              <Form.Control
                placeholder="Enter the salary"
                name="salary"
                required
                value={formState.salary}
                onChange={handleFormChange}
              />
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Job Responsibilities</Form.Label>
              {formState?.responsibilities.map((attribute, index) => (
                <div className="row mb-2 align-items-center" key={index}>
                  <div className={index ? "col-sm-11" : "col-sm-12"}>
                    <Form.Control
                      placeholder="Enter a job responsibility"
                      name="responsibilities"
                      required
                      value={attribute || ""}
                      onChange={(e) => handleFormChange(e, index)}
                    />
                  </div>

                  {index ? <RemoveButton clickAction={() => removeFormFields(index, "responsibilities")} /> : null}
                </div>
              ))}

              <AddButton clickAction={() => addFormFields("responsibilities")} label="Add more responsibilities" />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <Form.Label>Ideal Attributes</Form.Label>
              {formState?.ideal_attributes.map((attribute, index) => (
                <div className="row mb-2 align-items-center" key={index}>
                  <div className={index ? "col-sm-11" : "col-sm-12"}>
                    <Form.Control
                      placeholder="Enter an ideal job attribute"
                      name="ideal_attributes"
                      required
                      value={attribute || ""}
                      onChange={(e) => handleFormChange(e, index)}
                    />
                  </div>

                  {index ? <RemoveButton clickAction={() => removeFormFields(index, "ideal_attributes")} /> : null}
                </div>
              ))}

              <AddButton clickAction={() => addFormFields("ideal_attributes")} label="Add more ideal attributes" />
            </Form.Group>
          </div>

          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <Form.Label>Preferred Attributes</Form.Label>
              {formState?.preferred_attributes.map((attribute, index) => (
                <div className="row mb-2 align-items-center" key={index}>
                  <div className={index ? "col-sm-11" : "col-sm-12"}>
                    <Form.Control
                      placeholder="Enter a preferred job attribute"
                      name="preferred_attributes"
                      required
                      value={attribute || ""}
                      onChange={(e) => handleFormChange(e, index)}
                    />
                  </div>

                  {index ? <RemoveButton clickAction={() => removeFormFields(index, "preferred_attributes")} /> : null}
                </div>
              ))}

              <AddButton
                clickAction={() => addFormFields("preferred_attributes")}
                label="Add more preferred attributes"
              />
            </Form.Group>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <Form.Label>Job Location</Form.Label>
              <Form.Control
                placeholder="Enter the job location"
                name="location"
                required
                value={formState.location}
                onChange={handleFormChange}
              />
            </Form.Group>
          </div>

          <div className="col-lg-6">
            <Form.Group className="mb-4">
              <Form.Label>Tags</Form.Label>
              {formState?.tags.map((attribute, index) => (
                <div className="row mb-2 align-items-center" key={index}>
                  <div className={index ? "col-sm-11" : "col-sm-12"}>
                    <Form.Control
                      placeholder="Enter a tag"
                      name="tags"
                      value={attribute || ""}
                      onChange={(e) => handleFormChange(e, index)}
                    />
                  </div>

                  {index ? <RemoveButton clickAction={() => removeFormFields(index, "tags")} /> : null}
                </div>
              ))}

              <AddButton clickAction={() => addFormFields("tags")} label="Add more tags" />
            </Form.Group>
          </div>
        </div>

        <Alert variant="danger" show={showDelete}>
          <Alert.Heading>This action is irreversible.</Alert.Heading>
          <p>The data you are about to delete will not be recoverable, are you sure you want to continue?</p>
          <Button variant="outline-danger" onClick={handleDelete}>
            Yes, delete
          </Button>
          <Button onClick={() => setShowDelete(false)}>No, cancel</Button>
        </Alert>
        <Button variant="success" type="submit">
          Save
        </Button>
        {jobID !== "new" && (
          <Button variant="danger" onClick={() => setShowDelete(true)}>
            Delete
          </Button>
        )}
      </Form>
    </Container>
  );
}

export default NewJob;
