import { makeAutoObservable } from "mobx";

export default class EditModel {
  _id;
  description;
  image_url;
  name;
  locations;
  metadata;
  products;
  slug;
  created_at;
  updated_at;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this._id = data._id;
    this.description = data.description;
    this.image_url = data.image_url;
    this.name = data.name;
    this.locations = data.locations;
    this.metadata = data.metadata;
    this.products = data.products;
    this.slug = data.slug;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
  }
}
