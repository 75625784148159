import { CHER_BASE_URL } from ".";
import wheringFetch from "../../helpers/fetch";

const route = "blog";
const baseUrl = CHER_BASE_URL;

const blogApi = {
  getAll: async () => {
    return wheringFetch(baseUrl, `${route}/?unpublished=true`, "GET");
  },
  create: async (params) => {
    return wheringFetch(baseUrl, `${route}/`, "POST", params);
  },
  update: async (articleID, params) => {
    return wheringFetch(baseUrl, `${route}/${articleID}`, "PATCH", params);
  },
  delete: async (articleID) => {
    return wheringFetch(baseUrl, `${route}/${articleID}`, "DELETE");
  },
  images: async () => {
    return wheringFetch(baseUrl, `${route}/images`, "GET");
  },
  imageUpload: async (params) => {
    return wheringFetch(baseUrl, `${route}/images`, "POST", params);
  },
};

export default blogApi;
