import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

function AdminForm({ onSubmit, user }) {
  return (
    <Form onSubmit={onSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter Email"
          name="email"
          required
          defaultValue={user.email}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicFirstname">
        <Form.Label>First Name</Form.Label>
        <Form.Control
          placeholder="Enter Firstname"
          name="first_name"
          required
          defaultValue={user.firstname}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicSurname">
        <Form.Label>Surname</Form.Label>
        <Form.Control
          placeholder="Enter Surname"
          name="last_name"
          required
          defaultValue={user.surname}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Select a Role</Form.Label>
        <Form.Control as="select" required name="role" defaultValue={user.role}>
          <option value="">Choose a role</option>
          <option value="SuperUser">SuperUser</option>
          <option value="Admin">Admin</option>
        </Form.Control>
      </Form.Group>
      {!user && (
        <Form.Group className="mb-3">
          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password"
              name="password"
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPasswordConfirm">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Password Confirmation"
              name="confirm_password"
              required
            />
          </Form.Group>
        </Form.Group>
      )}

      <Button variant="success" type="submit">
        Submit
      </Button>
    </Form>
  );
}

export default AdminForm;
