import React from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

function Dashboard() {
  const [loading, setLoading] = useState(false);

  const handlePublish = async () => {
    setLoading(true);
    const response = await fetch(
      "https://api.vercel.com/v1/integrations/deploy/prj_96hNFjd4BJixT18Kuh2Ix2ivS9cq/SkJx4szuGq"
    );
    const { job } = await response.json();

    if (job.state === "PENDING") {
      toast("Changes published. These may take up to 5 minutes to appear on the website.", { type: "success" });
    } else {
      toast("Error - Please contact the administrator", { type: "error" });
    }

    setLoading(false);
  };
  return (
    <div style={{ margin: 10 }}>
      <h1>Welcome to the Whering Admin Page</h1>
      <div>
        {loading && <Spinner animation="border" style={{ margin: "20px 0" }} />}
        <button onClick={handlePublish}>Publish Changes</button>
      </div>
    </div>
  );
}

export default Dashboard;
