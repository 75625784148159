import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, InputGroup, Table, Row, Col, FormSelect } from "react-bootstrap";
import { useStores } from "../../context/mobx-context";
// import { FcCheckmark, FcCancel } from "react-icons/fc";
import TableWrapper from "../../components/TableWrapper";
import { parse } from "csv-parse/browser/esm";
import { toast } from "react-toastify";
import { runInAction } from "mobx";
import BulkUploadModal from "../../components/Marketplace/BulkUploadModal";

function Products({ history }) {
  const csvFileRef = useRef(null);

  /**
   * @type {import('../../typedefs').RootStore}
   */
  const { productStore } = useStores();
  const [csvData, setCsvData] = useState([]);
  const [multiSelectIDs, setMultiSelectIDs] = useState([]);
  const [brokenImages, setBrokenImages] = useState([]);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
  const [page, setPage] = useState(null);

  useEffect(() => {
    if (page !== null) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [page]);

  useEffect(() => {
    async function getData() {
      if (productStore.retailers.length === 0) {
        await productStore.getRetailerOptions();
      }
    }
    getData();
  }, [productStore]);

  useEffect(() => {
    if (csvData.length > 0) {
      const promises = csvData.map((productData, index) => {
        return new Promise(async (resolve, reject) => {
          await productStore.createProduct(productData);
          if (!productStore.hasFailed) {
            const updatedData = csvData;
            updatedData[index] = { ...productData, uploaded: true };
            setCsvData(updatedData);
            resolve("request successful");
          } else {
            reject(`${productData.name} failed to upload. Please check the input data`);
          }
        });
      });
      Promise.all(promises)
        .then((values) => {
          console.log("products uploaded", values);
          setCsvData([]);
        })
        .catch((e) => toast(e.message, { type: "error" }));
    }
  }, [csvData, productStore]);

  useEffect(() => {
    // if the page is zero, we are probably just updating filters, update the product list
    if (productStore.products.length === 0) {
      productStore.getFilterOptions().then(() => fetchProducts());
    }
  }, [productStore]);

  const fetchProducts = async () => {
    await productStore.getAllProducts();
  };

  const goToProduct = (productID = "new") => {
    history.push(`/marketplace/products/${productID}`);
  };

  const setFilters = async (key, value) => {
    await productStore.updateActiveFilters(key, value);
  };

  const onAddMultipleClick = async () => {
    const result = prompt(
      "please check that the google sheet is correctly formatted before continuing. If you are ready please type the name of the sheet you would like to upload from."
    );
    if (result && result.toLocaleLowerCase() !== "no") {
      await productStore.createManyProducts(result.trim());
      setShowBulkUploadModal(true);
    } else {
      console.log("they are not ready");
      return;
    }
  };

  const handleCsvFile = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = function (event) {
      const text = event.target.result;
      parse(text, (error, records) => {
        if (error) {
          toast("There was an error loading your CSV file. Please check the data and try again");
        } else {
          const headers = records[0];
          const rows = records.slice(1);
          const data = rows.map((row) => {
            let product = {};
            row.forEach((col, index) => {
              product[headers[index]] = col;
            });
            product.uploaded = false;
            return product;
          });
          setCsvData(data);
        }
      });
    };

    reader.readAsText(file);
    csvFileRef.current.value = "";
  };

  const handleMultiSelect = (productID) => {
    const index = multiSelectIDs.findIndex((id) => id === productID);
    if (index !== -1) {
      setMultiSelectIDs((prev) => prev.filter((id) => id !== productID));
    } else {
      setMultiSelectIDs((prev) => [...prev, productID]);
    }
  };

  const deleteMultipleProducts = async () => {
    for (const productID of multiSelectIDs) {
      await productStore.deleteProduct(productID);
      if (!productStore.hasFailed) {
        setMultiSelectIDs((prev) => prev.filter((id) => id !== productID));
        continue;
      } else {
        toast(`Delete failed for ID ${productID}`);
        break;
      }
    }
  };

  return (
    <TableWrapper
      title="Marketplace - Products"
      // onSearchChange={handleSearch} TODO: reinstate search when the logic is ready
      searchValue={productStore.searchTerm}
      showLoading={productStore.isFetching}
      onAddNewClick={() => goToProduct()}
      onAddMultipleClick={onAddMultipleClick}
    >
      <BulkUploadModal
        show={showBulkUploadModal}
        handleClose={() => {
          setShowBulkUploadModal(false);
          runInAction(() => (productStore.jobInProgress = null));
        }}
        job={productStore.jobInProgress}
      />
      {brokenImages.length > 0 && (
        <Alert variant="danger">
          <Alert.Heading>The following products have broken images and may have been removed.</Alert.Heading>
          <p>Please either update the product image or delete the record.</p>
          {brokenImages.map((product) => (
            <p
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => goToProduct(product.productID)}
            >
              {product.name}
            </p>
          ))}
        </Alert>
      )}
      <div style={{ padding: 12, alignSelf: "flex-end" }}>
        {multiSelectIDs.length > 0 && (
          <Button variant="danger" onClick={deleteMultipleProducts}>
            Delete ({multiSelectIDs.length})
          </Button>
        )}
      </div>
      {/* {TODO: Move this into it's own component along with the UI from EditModal.js} */}
      <section className="my-5 pb-3">
        <Row>
          <Col xs={2}>
            <label>Gender</label>
            <FormSelect
              value={productStore.activeFilters.gender}
              onChange={(e) => {
                setFilters("gender", e.target.value);
              }}
            >
              <option value="">Please select a gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="unisex">Unisex</option>
            </FormSelect>
          </Col>

          <Col xs={2}>
            <label>Retailer</label>
            <FormSelect
              value={productStore.activeFilters.retailer}
              onChange={(e) => {
                setFilters("retailer", e.target.value);
              }}
            >
              <option value="">Please select a retailer</option>
              {productStore?.retailers.map((retail) => (
                <option key={retail?.retailerID} value={retail.name}>
                  {retail.name}
                </option>
              ))}
            </FormSelect>
          </Col>

          <Col xs={2}>
            <label>Brand</label>
            <FormSelect
              value={productStore.activeFilters.brand}
              onChange={(e) => {
                setFilters("brand", e.target.value);
              }}
            >
              <option value="">Please select a brand</option>
              {productStore?.filters &&
                productStore?.filters?.brands &&
                productStore?.filters?.brands.map((brand, i) => (
                  <option key={i} value={brand}>
                    {brand}
                  </option>
                ))}
            </FormSelect>
          </Col>

          <Col xs={2}>
            <label>Categories</label>
            <FormSelect
              value={productStore.activeFilters.tax}
              onChange={(e) => {
                setFilters("tax", e.target.value);
              }}
            >
              <option value="">Please select a category</option>
              {productStore?.filters &&
                productStore?.filters?.categories &&
                productStore?.filters?.categories.map((category, i) => (
                  <option key={i} value={category}>
                    {category}
                  </option>
                ))}
            </FormSelect>
          </Col>

          <Col xs={2}>
            <label>Sale Type</label>
            <FormSelect
              value={productStore.activeFilters.sale_type}
              onChange={(e) => {
                setFilters("sale_type", e.target.value);
              }}
            >
              <option value="">Please select a sale type</option>
              {productStore?.filters &&
                productStore?.filters?.sale_type &&
                productStore?.filters?.sale_type.map((type, i) => (
                  <option key={i} value={type}>
                    {type}
                  </option>
                ))}
            </FormSelect>
          </Col>
        </Row>
      </section>
      <Table className="model-table">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Name</th>
            <th>Tax</th>
            <th>Retailer</th>
            <th>Brand</th>
          </tr>
        </thead>
        <tbody>
          {productStore.products.map((product) => (
            <tr key={product.productID}>
              <td>
                <InputGroup.Checkbox
                  aria-label="Checkbox for multi select"
                  onClick={() => handleMultiSelect(product.productID)}
                />
              </td>
              <td>
                <img
                  src={product?.images[0]}
                  alt={product.name}
                  height={100}
                  onError={() => setBrokenImages((prev) => [...prev, product])}
                />
              </td>
              <td>
                <a href={product.contentUrl} target="_blank" rel="noreferrer">
                  {product.product_name}
                </a>
              </td>

              <td>{product.tax}</td>
              <td>{product.retailer}</td>
              <td>{product.brand}</td>

              <td>
                <Button onClick={() => goToProduct(product.productID)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <input type="file" id="csv_file" style={{ display: "none" }} ref={csvFileRef} onChange={handleCsvFile} />
      <p>
        Showing {productStore.end ? productStore.total : productStore.products.length * (productStore.page + 1)} of{" "}
        {productStore.total}
      </p>
      <div className="flex-row" style={{ marginBottom: 50 }}>
        <Button
          onClick={async () => {
            productStore.resetPage();
            setPage(productStore.page);
          }}
          disabled={productStore.page === 0 || productStore.isFetching}
        >
          Back to Start
        </Button>
        <Button
          onClick={() => {
            productStore.prevPage();
            setPage(productStore.page);
          }}
          disabled={productStore.page === 0 || productStore.isFetching}
        >
          Previous
        </Button>
        <Button
          onClick={() => {
            productStore.nextPage();
            setPage(productStore.page);
          }}
          disabled={productStore.isFetching || productStore.end}
        >
          Next
        </Button>
      </div>
    </TableWrapper>
  );
}

export default observer(Products);
