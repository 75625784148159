import { action, flow, makeObservable, observable } from "mobx";
import ApiBasedStore from "./ApiBasedStore";
import { makePersistable, hydrateStore, clearPersistedStore } from "mobx-persist-store";
import AdminUserModel from "../models/AdminUserModel";

export default class AuthStore extends ApiBasedStore {
  authApi;
  isAuthenticated = false;
  userID = false;
  user = false;
  token = false;

  constructor(rootStore, authApi) {
    super(rootStore);
    this.authApi = authApi;
    makeObservable(this, {
      isAuthenticated: observable,
      userID: observable,
      user: observable,
      token: observable,
      login: action,
      logout: action,
    });

    makePersistable(this, {
      name: "AuthStore",
      properties: ["isAuthenticated", "userID", "user", "token"],
    });
  }

  login = flow(function* (email, password) {
    try {
      const { token, ...user } = yield this.authApi.login(email, password);
      this.userID = user.id;
      this.user = new AdminUserModel(user);
      this.isAuthenticated = true;
      this.token = token;
    } catch (e) {
      this.onError(e);
    }
  });

  logout = flow(function* () {
    this.startRequest();
    try {
      yield this.authApi.logout();
      yield this.clearStore();
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  async checkForPersistedUser() {
    await hydrateStore(this);
  }

  clearStore = flow(function* () {
    yield clearPersistedStore(this);
    this.isAuthenticated = false;
    this.userID = false;
    this.user = false;
    this.token = false;
  });
}
