import React from "react";
import { HiDotsVertical } from "react-icons/hi";

export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    <HiDotsVertical color="black" size={25} />
    {children}
  </a>
));
