import { makeAutoObservable } from "mobx";

export default class BasicsModel {
  id;
  attributes;
  attributes_map;
  filename;
  image_url;
  order;
  item_url;
  tags_added;
  tax;
  gender;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this.id = data.id;
    this.attributes = data.attributes;
    this.attributes_map = data.attributes_map;
    this.filename = data.filename;
    this.image_url = data.image_url;
    this.order = data.order;
    this.item_url = data.item_url;
    this.tags_added = data.tags_added;
    this.tax = data.tax;
    this.gender = data.gender;
  }
}
