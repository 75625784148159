import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";

function Categories({ history }) {
  const { marketplaceStore } = useStores();

  useEffect(() => {
    if (marketplaceStore.categories.length === 0) {
      marketplaceStore.getCategories();
    }
  }, []);

  let pageStart = marketplaceStore.categoryPage * 12;
  let pageEnd = pageStart + 12;

  const goToCategory = (categoryID) => {
    history.push(`/marketplace/categories/${categoryID}`);
  };

  return (
    <TableWrapper showLoading={marketplaceStore.isFetching} title="Marketplace - Categories">
      <Table className="model-table">
        <colgroup>
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Slug</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {marketplaceStore.categories.slice(pageStart, pageEnd).map((category) => (
            <tr key={category._id}>
              <td>
                <img src={category.image_url} height={100} alt="category" />
              </td>
              <td>
                <a href={category.contentUrl} target="_blank" rel="noreferrer">
                  {category.name}
                </a>
              </td>

              <td>{category.slug}</td>
              <td>{category.description}</td>

              <td>
                <Button onClick={() => goToCategory(category.categoryID)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="flex-row" style={{ marginBottom: 50 }}>
        <Button
          onClick={() =>
            runInAction(() => {
              marketplaceStore.categoryPage = 0;
              marketplaceStore.categoryEnd = false;
            })
          }
          disabled={marketplaceStore.categoryPage === 0 || marketplaceStore.isFetching}
        >
          Back to Start
        </Button>
        <Button
          onClick={() => marketplaceStore.prevPage("categories")}
          disabled={marketplaceStore.categoryPage === 0 || marketplaceStore.isFetching}
        >
          Previous
        </Button>
        <Button
          onClick={() => marketplaceStore.nextPage("categories")}
          disabled={marketplaceStore.isFetching || marketplaceStore.categoryEnd}
        >
          Next
        </Button>
      </div>
    </TableWrapper>
  );
}

export default observer(Categories);
