import { action, flow, makeObservable, observable } from "mobx";
import brandApi from "../api/brandApi";
import BrandModel from "../models/BrandModel";
import ApiBasedStore from "./ApiBasedStore";

export default class BrandStore extends ApiBasedStore {
  constructor(rootStore) {
    super(rootStore);
    this.brands = [];
    this.page = 0;
    this.end = false;
    this.searchQuery = "";
    this.showingSuggestions = false;

    makeObservable(this, {
      brands: observable,
      page: observable,
      end: observable,
      searchQuery: observable,
      showingSuggestions: observable,
      prevPage: action,
      nextPage: action,
      searchBrands: action,
      setSearchQuery: action,
      setUserSuggestions: action,
    });
  }

  fetchBrands = flow(function* () {
    this.startRequest();
    try {
      const { brands, end, count } = yield brandApi.get(this.page, this.showingSuggestions, this.searchQuery);
      this.addBrands(brands);
      this.end = end;
      return { end, count };
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  create = flow(function* (params) {
    this.startRequest();

    try {
      const { data } = yield brandApi.create(params);
      this.addBrands([data]);
      return data.id;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  update = flow(function* (id, params) {
    this.startRequest();

    try {
      const data = yield brandApi.update(id, params);
      this.addBrands([data]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  delete = flow(function* (id) {
    this.startRequest();
    try {
      yield brandApi.delete(id);
      const index = this.brands.findIndex((brand) => brand.id === id);
      this.brands.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  setSearchQuery = (value) => {
    this.searchQuery = value;
  };

  setUserSuggestions = (value) => {
    this.showingSuggestions = value;
  };

  searchBrands = async () => {
    this.page = 0;
    this.brands = [];
    await this.fetchBrands();
  };

  /**
   * Creates a new brand in state or overwrites an existing entry with new data
   * @param {Array} newBrands
   */
  addBrands(newBrands) {
    newBrands.forEach((newBrand) => {
      const existing = this.brands.find((existingBrand) => {
        return existingBrand.id === newBrand._id; // server uses id key for all objects
      });

      if (!existing) {
        const newBrandModel = new BrandModel(newBrand);

        if (newBrands.length > 1) {
          this.brands.push(newBrandModel);
        } else {
          this.brands.unshift(newBrandModel);
        }
      } else {
        existing.update(newBrand);
      }
    });
  }

  prevPage = async () => {
    if (this.page > 0) {
      this.page -= 1;
    }
  };

  nextPage = async () => {
    if (!this.end) {
      this.page += 1;
      if (this.brands.length <= 30 * this.page) {
        await this.fetchBrands();
      }
    }
  };
}
