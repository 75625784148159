import { doc, onSnapshot } from "firebase/firestore";
import { action, flow, makeObservable, observable, runInAction } from "mobx";
import { firestore } from "../../config/firebase";
import marketplaceApi from "../api/marketplaceApi";
import CategoriesModel from "../models/CategoriesModel";
import EditModel from "../models/EditModel";
import ProductModel from "../models/ProductModel";
import RetailerModel from "../models/RetailerModel";
import ServiceModel from "../models/ServiceModel";
import SpotlightsModel from "../models/SpotlightsModel";
import ApiBasedStore from "./ApiBasedStore";

export default class MarketplaceStore extends ApiBasedStore {
  marketplaceApi;
  products;
  edits;
  searchTerm;

  constructor(rootStore, marketplaceApi) {
    super(rootStore);
    this.marketplaceApi = marketplaceApi;
    this.products = [];
    this.productWithQuery = [];
    this.edits = [];
    this.services = [];
    this.categories = [];
    this.categoryPage = 0;
    this.categoryEnd = false;
    this.spotlights = [];
    this.retailers = [];
    this.searchTerm = "";
    this.filters = {};
    this.page = 0;
    this.end = false;
    this.jobInProgress = null;

    makeObservable(this, {
      products: observable,
      productWithQuery: observable,
      edits: observable,
      services: observable,
      categories: observable,
      categoryPage: observable,
      categoryEnd: observable,
      spotlights: observable,
      retailers: observable,
      searchTerm: observable,
      page: observable,
      end: observable,
      filters: observable,
      jobInProgress: observable,
      addProducts: action,
      updateSearchTerm: action,
    });
  }

  updateSearchTerm = function (query) {
    this.searchTerm = query;
  };

  getAllProducts = flow(function* (query) {
    this.startRequest();
    try {
      const { products, filters, end, count, total } = yield marketplaceApi.getProducts(query);
      this.addProducts(products);
      this.filters = filters;
      this.end = end;
      return { end, count, total };
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  getProductsWithQuery = flow(function* (query) {
    this.startRequest();
    try {
      const { products } = yield marketplaceApi.getProductsWithQuery(query);
      this.addProductsWithQuery(products);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createProduct = flow(function* (data) {
    this.startRequest();
    try {
      const product = yield marketplaceApi.createProduct(data);
      this.addProducts([product]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createManyProducts = flow(function* (sheetName) {
    this.startRequest();
    try {
      const data = yield marketplaceApi.createManyProducts({ sheet_name: sheetName });
      console.log(`received ${data.job_id}, opening listener to the firestore document`);
      this.productUploadQueueListener(data.job_id);
    } catch (e) {
      console.log(e);
      let errorMessage = `${e.response.data?.message ?? e.message}`;
      if (e.response.data.data && Object.keys(e.response.data.data).length > 0) {
        errorMessage += `: ${e.response.data.data.map((error) => `${error.row}: ${error.error}`).join(" ")}`;
      }
      this.onError(new Error(errorMessage));
    } finally {
      this.endRequest();
    }
  });

  updateProduct = flow(function* (productID, data) {
    this.startRequest();
    try {
      const product = yield marketplaceApi.updateProduct(productID, data);
      this.addProducts([product]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  deleteProduct = flow(function* (productID) {
    this.startRequest();
    try {
      const product = yield marketplaceApi.deleteProduct(productID);
      const index = this.products.findIndex((product) => product.productID === productID);
      this.products.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  getEdits = flow(function* () {
    this.startRequest();
    try {
      const edits = yield marketplaceApi.getEdits();
      this.addEdits(edits.edits);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createEdit = flow(function* (data) {
    this.startRequest();
    try {
      const edit = yield marketplaceApi.createEdit(data);
      this.addEdits([edit]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  updateEdit = flow(function* (editID, data) {
    this.startRequest();
    try {
      const edit = yield marketplaceApi.updateEdit(editID, data);
      this.addEdits([edit]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  deleteEdit = flow(function* (editID) {
    this.startRequest();
    try {
      yield marketplaceApi.deleteEdit(editID);
      const index = this.edits.findIndex((edit) => edit.editID === editID);
      this.edits.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  getServices = flow(function* () {
    this.startRequest();
    try {
      const { services } = yield marketplaceApi.getServices();
      this.addServices(services);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createService = flow(function* (data) {
    this.startRequest();
    try {
      const service = yield marketplaceApi.createService(data);
      this.addServices([service]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  updateService = flow(function* (serviceID, data) {
    this.startRequest();
    try {
      const service = yield marketplaceApi.updateService(serviceID, data);
      this.addServices([service]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  deleteService = flow(function* (serviceID) {
    this.startRequest();
    try {
      yield marketplaceApi.deleteService(serviceID);
      const index = this.services.findIndex((service) => service.serviceID === serviceID);
      this.services.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * Creates a new product in state or overwrites an existing entry with new data
   * @param {Array} newProducts
   */
  addProducts(newProducts) {
    newProducts.forEach((newProduct) => {
      const existing = this.products.find((existingProduct) => {
        return existingProduct.productID === newProduct._id; // server uses id key for all objects
      });

      if (!existing) {
        const newProductModel = new ProductModel(newProduct);
        if (newProducts.length > 1) {
          this.products.push(newProductModel);
        } else {
          this.products.unshift(newProductModel);
        }
      } else {
        existing.update(newProduct);
      }
    });
  }

  addProductsWithQuery(newProducts) {
    newProducts.forEach((newProduct) => {
      const existing = this.productWithQuery.find((existingProduct) => {
        return existingProduct.productID === newProduct._id; // server uses id key for all objects
      });

      if (!existing) {
        const newProductModel = new ProductModel(newProduct);
        if (newProducts.length > 1) {
          this.productWithQuery.push(newProductModel);
        } else {
          this.productWithQuery.unshift(newProductModel);
        }
      } else {
        existing.update(newProduct);
      }
    });
  }

  /**
   * Creates a new edit in state or overwrites an existing entry with new data
   * @param {Array} newEdits
   */
  addEdits(newEdits) {
    newEdits.forEach((newEdit) => {
      const existing = this.edits.find((existingEdit) => {
        return existingEdit._id === newEdit._id; // server uses id key for all objects
      });

      if (!existing) {
        const newEditModel = new EditModel(newEdit);

        if (newEdits.length > 1) {
          this.edits.push(newEditModel);
        } else {
          this.edits.unshift(newEditModel);
        }
      } else {
        existing.update(newEdit);
      }
    });
  }

  /**
   * Creates a new service in state or overwrites an existing entry with new data
   * @param {Array} newServices
   */
  addServices(newServices) {
    newServices.forEach((newService) => {
      const existing = this.services.find((existingService) => {
        return existingService.serviceID === newService.id; // server uses id key for all objects
      });

      if (!existing) {
        const newServiceModel = new ServiceModel(newService);
        this.services.unshift(newServiceModel);
      } else {
        existing.update(newService);
      }
    });
  }

  /**
   * Creates a new category in state or overwrites an existing entry with new data
   * @param {Array} newCategories
   */
  addCategories(newCategories) {
    newCategories.forEach((newCategory) => {
      const existing = this.categories.find((existingCategory) => {
        return existingCategory.categoryID === newCategory._id; // server uses id key for all objects
      });

      if (!existing) {
        const newCategoryModel = new CategoriesModel(newCategory);

        if (newCategories.length > 1) {
          this.categories.push(newCategoryModel);
        } else {
          this.categories.unshift(newCategoryModel);
        }
      } else {
        existing.update(newCategory);
      }
    });
  }

  getCategories = flow(function* (query) {
    this.startRequest();
    try {
      const { documents, end } = yield marketplaceApi.getCategories(query);
      this.categoryEnd = end;
      this.addCategories(documents);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  updateCategory = flow(function* (categoryID, data) {
    this.startRequest();
    try {
      const category = yield marketplaceApi.updateCategory(categoryID, data);
      this.addCategories([category]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  getSpotlights = flow(function* () {
    this.startRequest();
    try {
      const data = yield marketplaceApi.getSpotlights();
      this.addSpotlights(data?.documents);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createSpotlight = flow(function* (data) {
    this.startRequest();
    try {
      const spotlight = yield marketplaceApi.createSpotlight(data);
      this.addSpotlights([spotlight]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  updateSpotlight = flow(function* (spotlightID, data) {
    this.startRequest();
    try {
      const spotlight = yield marketplaceApi.updateSpotlight(spotlightID, data);
      this.addSpotlights([spotlight]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * @param {Array} newSpotlights
   */
  addSpotlights(newSpotlights) {
    newSpotlights.forEach((newSpotlight) => {
      const existing = this.spotlights.find((existingSpotlight) => {
        return existingSpotlight.spotlightID === newSpotlight._id; // server uses id key for all objects
      });

      if (!existing) {
        const newSpotlightModel = new SpotlightsModel(newSpotlight);

        if (newSpotlights.length > 1) {
          this.spotlights.push(newSpotlightModel);
        } else {
          this.spotlights.unshift(newSpotlightModel);
        }
      } else {
        existing.update(newSpotlight);
      }
    });
  }

  getRetailers = flow(function* () {
    this.startRequest();
    try {
      const data = yield marketplaceApi.getRetailers();
      this.addRetailers(data?.documents);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createRetailer = flow(function* (data) {
    this.startRequest();
    try {
      const retailer = yield marketplaceApi.createRetailer(data);
      this.addRetailers([retailer]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  updateRetailer = flow(function* (retailerID, data) {
    this.startRequest();
    try {
      const retailer = yield marketplaceApi.updateRetailer(retailerID, data);
      this.addRetailers([retailer]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  deleteRetailer = flow(function* (retailerID) {
    this.startRequest();
    try {
      yield marketplaceApi.deleteRetailer(retailerID);
      const index = this.retailers.findIndex((retailer) => retailer.retailerID === retailerID);
      this.retailers.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * @param {Array} newRetailers
   */
  addRetailers(newRetailers) {
    newRetailers.forEach((newRetailer) => {
      const existing = this.retailers.find((existingRetailer) => {
        return existingRetailer.retailerID === newRetailer._id; // server uses id key for all objects
      });

      if (!existing) {
        const newRetailerModel = new RetailerModel(newRetailer);

        if (newRetailers.length > 1) {
          this.retailers.push(newRetailerModel);
        } else {
          this.retailers.unshift(newRetailerModel);
        }
      } else {
        existing.update(newRetailer);
      }
    });
  }

  productUploadQueueListener(documentId) {
    // Listen for updates to the document, add new products as they come in to the product list
    // add errors to some error ui somewhere
    console.log(`listening to ${documentId}`);
    onSnapshot(doc(firestore, "marketplace_job_queue", documentId), async (doc) => {
      const data = doc.data();
      this.jobInProgress = data;
      console.log(`progress is ${data.success.length + data.failed.length}/${data.total}`);
      if (data.success.length > 0) {
        for (const job of data.success) {
          if (!this.products.find((product) => product.productID === job._id)) {
            const data = await this.marketplaceApi.getOneProduct(job._id);
            this.addProducts([{ _id: job._id, ...data }]);
          }
        }
      }

      if (data.failed) {
        // add the failed writes to a UI with the reasons for failure failed is an array of {error: string, row: string}
      }

      if (data.success.length + data.failed.length === data.total) {
        console.log(`job has processed all ${data.total} writes and is finished`);
        console.log(`${data.success.length} were successful`);
        console.log(`${data.failed.length} failed`);
      }

      if (data.job_status === "failed") {
        // something went very wrong and no products have been written, alert the user, there will also be an error key in the document
        // error is a string
        console.log(data.error);
      }

      if (data.job_status === "complete") {
        // the job is finished, some products may have failed, but at least one was written
      }
    });
  }

  prevPage = async (resource) => {
    if (resource === "categories") {
      if (this.categoryPage > 0) {
        this.categoryEnd = false;
        this.categoryPage -= 1;
      }
    }
  };

  /**
   *
   * @param {'products', 'categories'} resource
   */
  nextPage = async (resource) => {
    if (resource === "categories") {
      if (!this.categoryEnd) {
        this.categoryPage += 1;
        await this.getCategories(`?page=${this.categoryPage}`);
      }
    }
  };
}
