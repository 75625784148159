import { useEffect, useRef, useState } from "react";
import { firestore } from "../../../config/firebase";
import { collection, getDocs, query, where, orderBy } from "firebase/firestore";
import axios from "axios";
import { Accordion, Tab, Tabs } from "react-bootstrap";
import WpickReplayModal from "../../../components/WpickReplayModal";

export default function WpickUserLog({ match }) {
  const userId = match.params.userId;
  const [userPieces, setUserPieces] = useState(null);
  const [userLogs, setUserLogs] = useState(null);
  const [userOutfits, setUserOutfits] = useState(null);
  const [taxonomies, setTaxonomies] = useState(null);
  const [replayRequst, setReplayRequest] = useState(false);
  const flatTaxonomy = useRef(null);

  useEffect(() => {
    async function getUserData() {
      const response = await axios.get(`${process.env.REACT_APP_WHERING_STYLING_URL}/outfitclasses`);
      const taxResponse = await axios.get(`${process.env.REACT_APP_WHERING_STYLING_URL}/taxonomy?type=flat`);
      flatTaxonomy.current = taxResponse.data;
      console.log(flatTaxonomy.current);
      const outfitClasses = response.data;
      console.log(outfitClasses);
      const logQuery = query(collection(firestore, "wpick_results"), where("user_id", "==", userId));
      const logDocs = await getDocs(logQuery);
      const logData = [];
      logDocs.forEach((doc) => logData.push({ id: doc.id, ...doc.data() }));
      setUserLogs(logData.sort((a, b) => b.created_at?.toDate() - a.created_at?.toDate()));
      // console.log(logData);

      const pieceQuery = query(collection(firestore, "pieces"), where("user_id", "==", userId));
      const pieceDocs = await getDocs(pieceQuery);
      const pieceData = [];
      const tmpTaxonomies = {};
      pieceDocs.forEach((doc) => {
        const docData = doc.data();
        if (Object.keys(tmpTaxonomies).includes(docData.tax)) {
          tmpTaxonomies[docData.tax] += 1;
        } else {
          tmpTaxonomies[docData.tax] = 1;
        }
        pieceData.push({
          id: doc.id,
          ...doc.data(),
          slot: flatTaxonomy.current[docData.tax]?.slot,
          category: flatTaxonomy.current[docData.tax]?.category,
        });
      });
      setTaxonomies(tmpTaxonomies);
      setUserPieces(pieceData);
      console.log(pieceData);

      const outfitQuery = query(
        collection(firestore, "outfits"),
        where("user_id", "==", userId),
        orderBy("created_at", "desc")
      );
      const outfitDocs = await getDocs(outfitQuery);
      const outfitData = [];
      outfitDocs.forEach((doc) =>
        outfitData.push({ id: doc.id, ...doc.data(), created_at: doc.data().created_at?.toDate() })
      );
      setUserOutfits(outfitData);
      // console.log(outfitData);
    }

    getUserData();
  }, []);

  if (!userPieces || !userLogs || !userOutfits) {
    return <p>Loading</p>;
  } else {
    return (
      <>
        <div style={{ display: "flex", alignItems: "flex-start", margin: 10 }}>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <h2>Latest Logs</h2>
            {userLogs.map((log) => {
              return (
                <div key={log.id}>
                  <p>LogID: {log.id}</p>
                  <p>Date: {log.created_at.toDate().toISOString()}</p>
                  <p>Outfits Requested: {log.numOutfits}</p>
                  <p>Outfits Returned: {log.outfits.length}</p>
                  <button onClick={() => setReplayRequest(log.requestUrl)}>Replay</button>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {log.outfits.map((outfit) => {
                      return (
                        <div
                          key={outfit.outfit_hash}
                          style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                        >
                          <p>
                            {userOutfits.find((userOutfit) => outfit.outfit_hash === userOutfit.outfit_hash)
                              ? "Yes"
                              : "No"}
                          </p>
                          <img src={outfit.image_url} height={100} width={75} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <h2>User Wardrobe</h2>
            <div>
              <p>User ID: {userLogs[0]?.user_id ?? ""}</p>
              <p>User Email: {userLogs[0]?.user_email ?? ""}</p>
              <p>Total Pieces: {userPieces.length}</p>
              <p>Total Outfits: {userOutfits.length}</p>
              <p>Total Wpick Outfits: {userOutfits.filter((outfit) => outfit.created_on === "jarvis").length}</p>
            </div>
            <UserWardrobeBreakdown
              slots={userPieces.reduce((acc, curr) => {
                if (!acc.includes(curr.slot)) {
                  acc.push(curr.slot);
                }
                return acc;
              }, [])}
              categories={userPieces.reduce((acc, curr) => {
                if (!acc.includes(curr.category)) {
                  acc.push(curr.category);
                }
                return acc;
              }, [])}
              taxonomies={userPieces.reduce((acc, curr) => {
                if (!acc.includes(curr.tax)) {
                  acc.push(curr.tax);
                }
                return acc;
              }, [])}
              userPieces={userPieces}
            />
          </div>
        </div>
        <WpickReplayModal replayRequest={replayRequst} setReplayRequest={setReplayRequest} />
      </>
    );
  }
}

const UserWardrobeBreakdown = ({ slots, categories, taxonomies, userPieces }) => {
  return (
    <div>
      <Tabs>
        <Tab eventKey="slots" title="Slots">
          <Accordion alwaysOpen>
            {slots
              .sort((a, b) => a.localeCompare(b))
              .map((slot, index) => {
                const pieces = userPieces.filter((piece) => piece.slot === slot).sort((a, b) => b.tax - a.tax);
                return (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      {slot} {`(${pieces.length})`}
                    </Accordion.Header>
                    <Accordion.Body style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
                      {pieces.map((piece) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "fit-content",
                          }}
                        >
                          <img
                            src={piece.cropped_image_url ?? piece.nobg_image_url ?? piece.image_url}
                            height={100}
                            width={75}
                          />
                          <p>{piece.tax}</p>
                          <p>{piece.id}</p>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </Tab>
        <Tab eventKey="categories" title="Categories">
          <Accordion alwaysOpen>
            {categories
              .sort((a, b) => a.localeCompare(b))
              .map((category, index) => {
                const pieces = userPieces.filter((piece) => piece.category === category).sort((a, b) => b.tax - a.tax);
                return (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      {category} {`(${pieces.length})`}
                    </Accordion.Header>
                    <Accordion.Body style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
                      {pieces.map((piece) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "fit-content",
                          }}
                        >
                          <img
                            src={piece.cropped_image_url ?? piece.nobg_image_url ?? piece.image_url}
                            height={100}
                            width={75}
                          />
                          <p>{piece.tax}</p>
                          <p>{piece.id}</p>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </Tab>
        <Tab eventKey="taxomomies" title="Taxonomies">
          <Accordion alwaysOpen>
            {taxonomies
              .sort((a, b) => a.localeCompare(b))
              .map((taxonomy, index) => {
                const pieces = userPieces.filter((piece) => piece.tax === taxonomy).sort((a, b) => b.tax - a.tax);

                return (
                  <Accordion.Item eventKey={index}>
                    <Accordion.Header>
                      {taxonomy} {`(${pieces.length})`}
                    </Accordion.Header>
                    <Accordion.Body style={{ display: "flex", flexWrap: "wrap", gap: 10 }}>
                      {pieces.map((piece) => (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "fit-content",
                          }}
                        >
                          <img
                            src={piece.cropped_image_url ?? piece.nobg_image_url ?? piece.image_url}
                            height={100}
                            width={75}
                          />
                          <p>{piece.tax}</p>
                          <p>{piece.id}</p>
                        </div>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
          </Accordion>
        </Tab>
      </Tabs>
    </div>
  );
};
