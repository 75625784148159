import { reaction } from "mobx";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Route, Switch, Redirect } from "react-router-dom";
import { useStores } from "../context/mobx-context";

import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import NotFound from "../pages/NotFound";
import Admin from "../pages/Admin/Admin";
import Edits from "../pages/Marketplace/Edits";
import NewEdit from "../pages/Marketplace/NewEdit";
import Products from "../pages/Marketplace/Products";
import NewProduct from "../pages/Marketplace/NewProduct";
import Services from "../pages/Marketplace/Services";
import NewService from "../pages/Marketplace/NewServices";
import NewThought from "../pages/Thoughts/NewThought";
import Thoughts from "../pages/Thoughts/Thoughts";
import ImageGallery from "../pages/ImageGallery";
import Users from "../pages/Users/Users";
import EditUser from "../pages/Users/EditUser";
import Careers from "../pages/Careers/Careers";
import UcdbReported from "../pages/UcdbReported/UcdbReported";
import JobDetail from "../pages/Careers/JobDetail";
import NewJob from "../pages/Careers/NewJob";
import Spotlights from "../pages/Marketplace/Spotlights";
import NewSpotlight from "../pages/Marketplace/NewSpotlight";
import EditCategory from "../pages/Marketplace/EditCategory";
import Retailers from "../pages/Marketplace/Retailers";
import NewRetailer from "../pages/Marketplace/NewRetailer";
import Categories from "../pages/Marketplace/Categories";
import Basics from "../pages/Basics/Basics";
import NewBasics from "../pages/Basics/AddNew";
import MarketplaceBrands from "../pages/Marketplace/Brands";
import NewMarketplaceBrand from "../pages/Marketplace/NewBrand";
import { TestHarnesses } from "../pages/TestHarnesses/TestHarnesses";
import { ShuffleHarness } from "../pages/TestHarnesses/styling/ShuffleHarness";
import { PiecesHarness } from "../pages/TestHarnesses/wardrobe/PiecesHarness";
import { WpickHarness } from "../pages/TestHarnesses/styling/WpickHarness";
import AppBuilds from "../pages/Builds/AppBuilds";
import { WpickLoggingHarness } from "../pages/TestHarnesses/styling/WpickLoggingHarness";
import WpickLogEntry from "../pages/TestHarnesses/styling/WpickLogEntry";
import WpickUserLog from "../pages/TestHarnesses/styling/WpickUserLog";
import OutfitsHarness from "../pages/TestHarnesses/wardrobe/OutfitsHarness";

/*
  Use this array to configure new or edit existing routes. Each object accepts the following keys:
  path: the url path used to access the route
  key: the internal key used when rendering the route
  exact: whether to match only the exact path or to also accept sub paths
  private: whether the route should only be available to authenticated users
  component: the React component that will be rendered by the route
  routes: An array of any subroutes that are associated with the parent
 */
const ROUTES = [
  {
    path: "/",
    key: "ROOT",
    exact: false,
    component: RenderRoutes,
    routes: [
      {
        path: "/",
        key: "ROOT",
        exact: true,
        component: Login,
      },
      {
        path: "/dashboard",
        key: "DASHBOARD",
        exact: true,
        component: Dashboard,
        private: true,
      },
      {
        path: "/admin",
        key: "ADMIN",
        exact: true,
        component: Admin,
        private: true,
      },
      {
        path: "/marketplace",
        key: "MARKETPLACE",
        exact: false,
        private: true,
        component: RenderRoutes,
        routes: [
          {
            path: "/marketplace/products",
            key: "MARKETPLACE_PIECES",
            exact: true,
            component: Products,
            private: true,
          },
          {
            path: "/marketplace/products/:productID",
            key: "MARKETPLACE_PIECES",
            exact: true,
            component: NewProduct,
            private: true,
          },
          {
            path: "/marketplace/edits",
            key: "MARKETPLACE_EDITS",
            exact: true,
            component: Edits,
            private: true,
          },
          {
            path: "/marketplace/edits/:editID",
            key: "MARKETPLACE_EDITS",
            exact: true,
            component: NewEdit,
            private: true,
          },
          {
            path: "/marketplace/retailers",
            key: "MARKETPLACE_RETAILERS",
            exact: true,
            component: Retailers,
            private: true,
          },
          {
            path: "/marketplace/retailers/:retailerID",
            key: "MARKETPLACE_EDITS",
            exact: true,
            component: NewRetailer,
            private: true,
          },
          {
            path: "/marketplace/services",
            key: "MARKETPLACE_SERVICES",
            exact: true,
            component: Services,
            private: true,
          },
          {
            path: "/marketplace/services/:serviceID",
            key: "MARKETPLACE_SERVICES",
            exact: true,
            component: NewService,
            private: true,
          },
          {
            path: "/marketplace/categories",
            key: "MARKETPLACE_CATEGORIES",
            exact: true,
            component: Categories,
            private: true,
          },
          {
            path: "/marketplace/categories/:categoryID",
            key: "MARKETPLACE_CATEGORIES",
            exact: true,
            component: EditCategory,
            private: true,
          },
          {
            path: "/marketplace/spotlights",
            key: "MARKETPLACE_SPOTLIGHTS",
            exact: true,
            component: Spotlights,
            private: true,
          },
          {
            path: "/marketplace/spotlight/:spotlightID",
            key: "MARKETPLACE_SPOTLIGHT",
            exact: true,
            component: NewSpotlight,
            private: true,
          },
          {
            path: "/marketplace/brands",
            key: "MARKETPLACE_BRANDS",
            exact: true,
            component: MarketplaceBrands,
            private: true,
          },
          {
            path: "/marketplace/brand/:brandID",
            key: "MARKETPLACE_BRAND",
            exact: true,
            component: NewMarketplaceBrand,
            private: true,
          },
        ],
      },
      {
        path: "/thoughts",
        key: "THOUGHTS",
        exact: true,
        private: true,
        component: Thoughts,
      },
      {
        path: "/thoughts/:articleID",
        key: "THOUGHTS",
        exact: true,
        private: true,
        component: NewThought,
      },
      {
        path: "/images/:folder?",
        key: "IMAGE_GALLERY",
        exact: true,
        private: true,
        component: ImageGallery,
      },
      {
        path: "/users",
        key: "USERS",
        exact: true,
        private: true,
        component: Users,
      },
      {
        path: "/users/:uid",
        key: "EDIT_USERS",
        exact: true,
        private: true,
        component: EditUser,
      },
      {
        path: "/careers",
        key: "CAREERS",
        exact: true,
        private: true,
        component: Careers,
      },
      {
        path: "/careers/details/:jobID",
        key: "JOBDETAIL",
        exact: true,
        private: true,
        component: JobDetail,
      },
      {
        path: "/careers/:jobID",
        key: "NEWJOB",
        exact: true,
        private: true,
        component: NewJob,
      },
      {
        path: "/ucdbreported",
        key: "UCDBREPORTED",
        exact: true,
        private: true,
        component: UcdbReported,
      },
      {
        path: "/basics",
        key: "BASICS",
        exact: true,
        private: true,
        component: Basics,
      },
      {
        path: "/basics/:basicID",
        key: "BASICS",
        exact: true,
        private: true,
        component: NewBasics,
      },
      {
        path: "/harnesses",
        key: "TEST_HARNESSES_ROUTES",
        exact: false,
        private: true,
        component: RenderRoutes,
        routes: [
          {
            path: "/harnesses",
            key: "TEST_HARNESSES_HOME",
            exact: true,
            component: TestHarnesses,
            private: true,
          },
          {
            path: "/harnesses/styling/shuffle",
            key: "TEST_HARNESSES_STYLING_SHUFFLE",
            exact: true,
            component: ShuffleHarness,
            private: true,
          },
          {
            path: "/harnesses/styling/wpick",
            key: "TEST_HARNESSES_STYLING_WPICK",
            exact: true,
            component: WpickHarness,
            private: true,
          },
          {
            path: "/harnesses/styling/wpick/logs",
            key: "TEST_HARNESSES_STYLING_WPICK",
            exact: true,
            component: WpickLoggingHarness,
            private: true,
          },
          {
            path: "/harnesses/styling/wpick/logs/user/:userId",
            key: "TEST_HARNESSES_STYLING_WPICK",
            exact: true,
            component: WpickUserLog,
            private: true,
          },
          {
            path: "/harnesses/styling/wpick/logs/:logId",
            key: "TEST_HARNESSES_STYLING_WPICK",
            exact: true,
            component: WpickLogEntry,
            private: true,
          },
          {
            path: "/harnesses/wardrobe/pieces",
            key: "TEST_HARNESSES_WARDROBE_PIECES",
            exact: true,
            component: PiecesHarness,
            private: true,
          },
          {
            path: "/harnesses/wardrobe/outfits",
            key: "TEST_HARNESSES_WARDROBE_OUTFITS",
            exact: true,
            component: OutfitsHarness,
            private: true,
          },
        ],
      },
      { path: "/builds", key: "BASICS", exact: true, private: true, component: AppBuilds },
    ],
  },
];

// Determines whether the route's component should be rendered or redirected according to application state
// i.e. preventing private routes from being exposed to unauthenticated users
function RenderRoutePage(props, route) {
  if (route.private && !route.isAuthenticated) {
    return <Redirect to="/" />;
  } else {
    return <route.component {...props} routes={route.routes} />;
  }
}

// Passes subroutes as props to parent routes that specify them in ROUTES.
function RouteWithSubRoutes(route) {
  return <Route path={route.path} exact={route.exact} render={(props) => RenderRoutePage(props, route)} />;
}

// Used to access the route from app.js
export function RenderRoutes({ routes }) {
  const { authStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    reaction(
      () => authStore.isAuthenticated,
      (isAuthenticated) => {
        if (!isAuthenticated) {
          history.push("/");
        }
      }
    );
  }, []);
  return (
    <Switch>
      {routes.map((route) => {
        return <RouteWithSubRoutes key={route.key} {...route} isAuthenticated={authStore.isAuthenticated} />;
      })}
      <Route component={NotFound} />
    </Switch>
  );
}

export default ROUTES;
