import { makeAutoObservable } from "mobx";

export default class ProductModel {
  productID;
  images;
  name;
  prices;
  content_url;
  brand;
  published;
  created;
  updated;
  sale_type;
  tax;
  colour;
  age;
  description;
  sizes;
  gender;
  in_stock;
  metadata;
  retailer;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this.productID = data._id;
    this.images = data.images;
    this.product_name = data.product_name;
    this.prices = data.prices;
    this.content_url = data.content_url;
    this.brand = data.brand;
    this.published = typeof data.published === "undefined" ? true : data.published;
    this.created = data.created_at;
    this.updated = data.updated_at;
    this.sale_type = data.sale_type;
    this.tax = data.tax;
    this.slug = data.slug;
    this.colour = data.colour;
    this.age = data.age;
    this.description = data.description;
    this.sizes = data.sizes;
    this.gender = data.gender;
    this.in_stock = data.in_stock;
    this.metadata = data.metadata;
    this.retailer = data.retailer;
  }
}
