import { makeAutoObservable } from "mobx";

export default class RetailerModel {
  retailerID;
  _id;
  slug;
  created_at;
  description;
  image_url;
  logo_image_url;
  metadata;
  name;
  updated_at;
  locations;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this._id = data._id;
    this.retailerID = data._id;
    this.slug = data.slug;
    this.created_at = data.created_at;
    this.description = data.description;
    this.image_url = data.image_url;
    this.logo_image_url = data.logo_image_url;
    this.metadata = data.metadata;
    this.name = data.name;
    this.updated_at = data.updated_at;
    this.locations = data.locations;
  }
}
