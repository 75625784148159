import React, { useState } from "react";
import { Alert, Button, Container, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

const actionCodeSettings = {
  dynamicLinkDomain: process.env.REACT_APP_DYNAMIC_LINK_DOMAIN,
  url: process.env.REACT_APP_CONTINUE_URL,
  handleCodeInApp: true,
  iOS: {
    bundleId: process.env.REACT_APP_IOS_PACKAGE_NAME,
  },
  android: {
    packageName: process.env.REACT_APP_ANDROID_PACKAGE_NAME,
  },
};

function EditUser({ match, history }) {
  const { userStore } = useStores();
  const auth = getAuth();
  const uid = match.params.uid;
  const user = userStore.users.find((u) => u.uid === uid);

  const [showDelete, setShowDelete] = useState(false);
  const [showPasswordResetLink, setShowPasswordResetLink] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [formState, setFormState] = useState({
    email: user.email,
  });

  const handleDelete = async () => {
    await userStore.delete(uid);
    if (!userStore.hasFailed) {
      toast(`User ${user.email} has been deleted`, { type: "success" });
      history.goBack();
    }
  };
  const handleSave = async (event) => {
    event.preventDefault();
    await userStore.update(uid, formState.email);
    if (!userStore.hasFailed) {
      toast("User Email Updated", { type: "success" });
      history.goBack();
    }
  };

  const clearOnboardingData = async () => {
    await userStore.clearOnboardingData(user.email);
    if (!userStore.hasFailed) {
      toast("Onboarding data cleared!", { type: "success" });
    }
  };

  const sendPasswordReset = async () => {
    await sendPasswordResetEmail(auth, user.email, actionCodeSettings)
      .then(() => {
        toast(`Password reset email has been sent to ${user.email} successfully`, { type: "success" });
      })
      .catch((error) => {
        toast(error.message, { type: "error" });
      });
  };

  const getPasswordResetLink = async () => {
    await userStore.passwordReset(user.email);
    if (!userStore.hasFailed) {
      setShowPasswordResetLink(true);
    }
  };

  const copyToClipBoard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess("Copied!🎉");
    } catch (err) {
      setCopySuccess("Failed to copy!");
    }
  };

  return (
    <Container style={{ marginTop: 50 }}>
      <Alert variant="danger" show={showDelete}>
        <Alert.Heading>This action is irreversible.</Alert.Heading>
        <p>The data you are about to delete will not be recoverable, are you sure you want to continue?</p>
        <Button variant="outline-danger" onClick={handleDelete}>
          Yes, delete
        </Button>
        <Button onClick={() => setShowDelete(false)}>No, cancel</Button>
      </Alert>

      <Alert variant="success" show={showPasswordResetLink}>
        <Alert.Heading>Password reset link generated successfully</Alert.Heading>
        <p style={{ wordBreak: "break-all" }}>{userStore?.link}</p>
        <Button variant="light" onClick={() => copyToClipBoard(userStore?.link)}>
          Copy
        </Button>
        <Button
          onClick={() => {
            setShowPasswordResetLink(false);
            setCopySuccess("");
          }}
        >
          Close
        </Button>
        {copySuccess}
      </Alert>
      <h1>User Record</h1>
      <Form className="model-form" onSubmit={handleSave}>
        <Form.Group className="mb-3">
          <Form.Label>User ID</Form.Label>
          <Form.Control value={user.uid} disabled />
          <Form.Label>First Name</Form.Label>
          <Form.Control value={user.first_name} disabled />
          <Form.Label>Last Name</Form.Label>
          <Form.Control value={user.last_name} disabled />
          <Form.Label>Email</Form.Label>
          <Form.Control
            placeholder="Enter a new Email"
            name="email"
            required
            type="email"
            value={formState.email}
            onChange={(event) =>
              setFormState((prev) => {
                return { ...prev, email: event.target.value };
              })
            }
          />
        </Form.Group>
        <Button variant="success" type="submit">
          Update Email
        </Button>
        <Button variant="warning" onClick={() => clearOnboardingData()}>
          Clear Onboarding Data
        </Button>
        <Button variant="danger" onClick={() => setShowDelete(true)}>
          Delete User Data
        </Button>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              This sends an email to the user with a reset link. Any new email sent or link generated invalidates the
              previous one.
            </Tooltip>
          }
        >
          <Button variant="secondary" onClick={() => sendPasswordReset()}>
            Send Password Reset Email
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip id="tooltip">
              This generates a password reset link which you can copy from here and send to the user. Any new email sent
              or link generated invalidates the previous one.
            </Tooltip>
          }
        >
          <Button variant="secondary" onClick={() => getPasswordResetLink()}>
            Get Password Reset Link
          </Button>
        </OverlayTrigger>
      </Form>
    </Container>
  );
}

export default EditUser;
