import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";

function Services({ history }) {
  const { marketplaceStore } = useStores();

  useEffect(() => {
    if (marketplaceStore.services.length === 0) {
      marketplaceStore.getServices();
    }
  }, []);

  const goToService = (serviceID) => {
    history.push(`/marketplace/services/${serviceID}`);
  };
  return (
    <TableWrapper
      showLoading={marketplaceStore.isFetching}
      onAddNewClick={() => goToService("new")}
      title="Marketplace - Services"
    >
      <Table className="model-table">
        <colgroup>
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Brand</th>
            <th>Category</th>
            <th>Description</th>
            <th>Discount</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {marketplaceStore.services.map((service) => (
            <tr key={service.serviceID}>
              <td>
                <img src={service.imageUrl} height={100} />
              </td>
              <td>
                <a href={service.contentUrl} target="_blank">
                  {service.name}
                </a>
              </td>
              <td>{service.brand}</td>
              <td>{service.category}</td>
              <td>{service.description}</td>
              <td>{service.discount}</td>
              <td>{service.location}</td>

              <td>
                <Button onClick={() => goToService(service.serviceID)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default observer(Services);
