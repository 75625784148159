import React, { useEffect, useState } from "react";
import { Button, Col, FormSelect, Modal, Row } from "react-bootstrap";
import { BsCheckCircleFill, BsCircleFill } from "react-icons/bs";
import axios from "axios";
import { DIONNE_BASE_URL } from "../../data/api";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { useStores } from "../../context/mobx-context";

const PAGINATION_LIMIT = 30;

const ProductSelectModal = ({
  show,
  handleClose,
  modalHeader,
  updateProduct,
  selectedIds,
  gender,
  collection,
  retailer,
  brand,
  modalType,
}) => {
  // Move the filter state and product list inside the local state to avoid interfering with the product state in the
  // marketplace store
  const [filters, setFilters] = useState({
    gender: gender ?? "",
    retailer: modalType === "brand_spotlight" && collection === "retailer" && retailer ? retailer : "",
    brand: modalType === "brand_spotlight" && collection === "brand" && brand ? brand : "",
    tax: "",
    sale_type: "",
    edit: "",
  });
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(0);
  const { marketplaceStore } = useStores();

  useEffect(() => {
    // if the page is already zero, no need to reset it, just fetch the products as this is likely a filter changing
    if (page === 0) {
      fetchProducts();
    } else {
      // however if it's not, we need to go back to the beginning, so set page to zero and let the the refetch happen
      // as a side effect of the page update so we don't get out of sync
      setPage(0);
    }
  }, [filters]);

  useEffect(() => {
    // Always refetch if page is changed
    fetchProducts();
  }, [page]);

  async function fetchProducts() {
    try {
      // don't use the same fetch as the marketplace store so the products array isn't updated in the store
      let productUrl = `${DIONNE_BASE_URL}/marketplace/products?limit=${PAGINATION_LIMIT}&page=${page}&`;
      // apply any filters that have a value
      for (const [key, value] of Object.entries(filters)) {
        if (value.length > 0) {
          productUrl += `${key}=${value}&`;
        }
      }
      const { data } = await axios.get(productUrl);
      setProducts(data.products);
    } catch (e) {
      toast(e.message, { type: "error" });
    }
  }

  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalHeader} Products</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* {TODO: Move this into it's own component along with the UI from Products.js} */}
        <section className="my-5 pb-3">
          <Row>
            <Col xs={2}>
              <label>Gender</label>
              <FormSelect
                value={filters.gender}
                onChange={(e) => {
                  setFilters({ ...filters, gender: e.target.value });
                }}
              >
                <option value="">Please select a gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="unisex">Unisex</option>
              </FormSelect>
            </Col>

            <Col xs={2}>
              <label>Retailer</label>
              <FormSelect
                value={filters.retailer}
                onChange={(e) => {
                  setFilters({ ...filters, retailer: e.target.value });
                }}
              >
                <option value="">Please select a retailer</option>
                {marketplaceStore?.retailers.map((retail) => (
                  <option key={retail?.retailerID} value={retail.name}>
                    {retail.name}
                  </option>
                ))}
              </FormSelect>
            </Col>

            <Col xs={2}>
              <label>Brand</label>
              <FormSelect
                value={filters.brand}
                onChange={(e) => {
                  setFilters({ ...filters, brand: e.target.value });
                }}
              >
                <option value="">Please select a brand</option>
                {marketplaceStore?.filters &&
                  marketplaceStore?.filters?.brands &&
                  marketplaceStore?.filters?.brands.map((brand, i) => (
                    <option key={i} value={brand}>
                      {brand}
                    </option>
                  ))}
              </FormSelect>
            </Col>

            <Col xs={2}>
              <label>Categories</label>
              <FormSelect
                value={filters.tax}
                onChange={(e) => {
                  setFilters({ ...filters, tax: e.target.value });
                }}
              >
                <option value="">Please select a category</option>
                {marketplaceStore?.filters &&
                  marketplaceStore?.filters?.categories &&
                  marketplaceStore?.filters?.categories.map((category, i) => (
                    <option key={i} value={category}>
                      {category}
                    </option>
                  ))}
              </FormSelect>
            </Col>

            <Col xs={2}>
              <label>Sale Type</label>
              <FormSelect
                value={filters.sale_type}
                onChange={(e) => {
                  setFilters({ ...filters, sale_type: e.target.value });
                }}
              >
                <option value="">Please select a sale type</option>
                {marketplaceStore?.filters &&
                  marketplaceStore?.filters?.sale_type &&
                  marketplaceStore?.filters?.sale_type.map((type, i) => (
                    <option key={i} value={type}>
                      {type}
                    </option>
                  ))}
              </FormSelect>
            </Col>
            {modalHeader === "Edit Spotlight" && (
              <Col xs={2}>
                <label>Edit</label>
                <FormSelect
                  value={filters.edit}
                  onChange={(e) => {
                    setFilters({ ...filters, edit: e.target.value });
                  }}
                >
                  <option value="">Please select a sale type</option>
                  {marketplaceStore?.edits &&
                    marketplaceStore?.edits.map((edit, i) => (
                      <option key={i} value={edit.slug}>
                        {edit.name}
                      </option>
                    ))}
                </FormSelect>
              </Col>
            )}
          </Row>
        </section>

        <Row style={{ justifyContent: "center" }}>
          {products?.length !== 0 ? (
            products.map((product) => {
              const productInEdit = selectedIds.includes(product?._id);
              return (
                <Col
                  lg={2}
                  className="mb-4"
                  key={product._id}
                  style={{ display: "flex", border: "2px solid black", marginRight: 10 }}
                >
                  <div className="text-center h-100" style={{ position: "relative" }}>
                    <img
                      src={product?.images[0]}
                      alt={product?.name}
                      className="img-fluid h-100"
                      style={{ objectFit: "cover" }}
                    />
                    <small className="fw-bolder text-center">{product?.product_name.slice(0, 15)}</small>

                    {productInEdit ? (
                      <BsCheckCircleFill
                        onClick={() => updateProduct(product, productInEdit)}
                        size={30}
                        color="green"
                        style={{ position: "absolute", top: 5, right: 0, cursor: "pointer" }}
                      />
                    ) : (
                      <BsCircleFill
                        onClick={() => updateProduct(product, productInEdit)}
                        size={30}
                        color="grey"
                        style={{ position: "absolute", top: 5, right: 0, cursor: "pointer" }}
                      />
                    )}
                  </div>
                </Col>
              );
            })
          ) : (
            <p className="text-center">No products to display</p>
          )}
        </Row>

        <div className="flex-row" style={{ marginBottom: 50 }}>
          <Button onClick={() => setPage(0)} disabled={page === 0}>
            Back to Start
          </Button>
          <Button onClick={() => setPage(page - 1)} disabled={page === 0}>
            Previous
          </Button>
          <Button onClick={() => setPage(page + 1)} disabled={products.length < PAGINATION_LIMIT}>
            Next
          </Button>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={handleClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(ProductSelectModal);
