import { configurePersistable } from "mobx-persist-store";
import adminApi from "../api/adminApi";
import authApi from "../api/authApi";
import blogApi from "../api/blogApi";
import userApi from "../api/userApi";
import careerApi from "../api/careerApi";
import brandApi from "../api/brandApi";
import ucdbReportedApi from "../api/ucdbReportedApi";
import marketplaceApi from "../api/marketplaceApi";
import basicsApi from "../api/basicsApi";
import AdminStore from "./AdminStore";
import AuthStore from "./AuthStore";
import BlogStore from "./BlogStore";
import MarketplaceStore from "./MarketplaceStore";
import UserStore from "./UserStore";
import CareerStore from "./CareerStore";
import UcdbReportedStore from "./UcdbReportedStore";
import BasicsStore from "./BasicsStore";
import BrandStore from "./BrandStore";
import ProductStore from "./ProductStore";

configurePersistable({
  storage: window.localStorage,
  expireIn: 86400000,
});

export default class RootStore {
  authStore;
  adminStore;
  marketplaceStore;
  blogStore;
  brandStore;
  basicsStore;

  constructor() {
    this.authStore = new AuthStore(this, authApi);
    this.adminStore = new AdminStore(this, adminApi);
    this.marketplaceStore = new MarketplaceStore(this, marketplaceApi);
    this.blogStore = new BlogStore(this, blogApi);
    this.userStore = new UserStore(this, userApi);
    this.careerStore = new CareerStore(this, careerApi);
    this.ucdbReportedStore = new UcdbReportedStore(this, ucdbReportedApi);
    this.basicsStore = new BasicsStore(this, basicsApi);
    this.brandStore = new BrandStore(this, brandApi);
    this.productStore = new ProductStore(this, marketplaceApi);
  }
}
