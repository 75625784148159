import { Button } from "react-bootstrap";
import addButton from "../assets/images/plus.svg";
import removeIcon from "../assets/images/subtract.svg";

export const AddButton = ({ clickAction, label }) => {
  return (
    <div className="d-flex justify-content-end mt-3">
      <Button
        variant="success"
        className="d-flex justify-content-between align-items-center add-more-size"
        onClick={clickAction}
      >
        {label} <img width={25} height={25} src={addButton} alt="add-button" />
      </Button>
    </div>
  );
};

export const RemoveButton = ({ clickAction }) => {
  return (
    <div className="col-sm-1 cursor">
      <img width={25} height={25} src={removeIcon} alt="remove" onClick={clickAction} />
    </div>
  );
};
