import { doc, onSnapshot } from "firebase/firestore";
import { action, flow, makeObservable, observable } from "mobx";
import { firestore } from "../../config/firebase";
import brandApi from "../api/brandApi";
import marketplaceApi from "../api/marketplaceApi";
import ProductModel from "../models/ProductModel";
import ApiBasedStore from "./ApiBasedStore";

const PAGINATION_LIMIT = 30;

export default class ProductStore extends ApiBasedStore {
  marketplaceApi;
  products;
  edits;
  searchTerm;

  constructor(rootStore, marketplaceApi) {
    super(rootStore);
    this.marketplaceApi = marketplaceApi;
    this.products = [];
    this.retailers = [];
    this.brands = [];
    this.searchTerm = "";
    this.colours = [];
    this.filters = {
      brand: [],
      tax: [],
      retailer: [],
    };
    this.activeFilters = {
      brand: "",
      tax: "",
      retailer: "",
      sale_type: "",
      gender: "",
    };
    this.page = 0;
    this.limit = PAGINATION_LIMIT;
    this.end = false;
    this.jobInProgress = null;
    this.total = 0;

    makeObservable(this, {
      products: observable,
      retailers: observable,
      brands: observable,
      searchTerm: observable,
      colours: observable,
      page: observable,
      end: observable,
      filters: observable,
      jobInProgress: observable,
      total: observable,
      addProducts: action,
      updateSearchTerm: action,
      nextPage: action,
      prevPage: action,
      resetPage: action,
    });
  }

  updateSearchTerm = function (query) {
    this.searchTerm = query;
  };

  /**
   * Make a request for all products to get the full list of filtering options
   */
  getFilterOptions = flow(function* () {
    const { filters } = yield marketplaceApi.getProducts();
    this.filters = filters;
  });

  getBrandOptions = flow(function* () {
    const { brands } = yield brandApi.get(0, false, "", 9999, "name,slug");
    this.brands = brands;
  });

  getRetailerOptions = flow(function* () {
    const { documents } = yield marketplaceApi.getRetailers();
    this.retailers = documents.sort((a, b) => a.name.localeCompare(b.name));
  });

  /**
   * Update the filters currently being applied to the product fetch
   * @param {string} key
   * @param {string} value
   */
  updateActiveFilters = flow(function* (key, value) {
    this.products = [];
    this.page = 0;
    this.activeFilters[key] = value;
    yield this.getAllProducts();
  });

  getAllProducts = flow(function* () {
    this.startRequest();
    try {
      const queryParams = new URLSearchParams(`page=${this.page}&limit=${this.limit}`);
      for (const [key, value] of Object.entries(this.activeFilters)) {
        if (value) {
          queryParams.append(key, value);
        }
      }
      const { products, end, count, total } = yield marketplaceApi.getProducts(`?${queryParams.toString()}`);
      this.products = products.map((data) => new ProductModel(data));
      this.end = end;
      this.total = total;
      return { end, count, total };
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  getOneProduct = flow(function* (id) {
    const product = yield marketplaceApi.getOneProduct(id);
    return product;
  });

  getProductsWithQuery = flow(function* (query) {
    this.startRequest();
    try {
      const { products } = yield marketplaceApi.getProductsWithQuery(query);
      this.addProductsWithQuery(products);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createProduct = flow(function* (data) {
    this.startRequest();
    try {
      const product = yield marketplaceApi.createProduct(data);
      this.addProducts([product]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createManyProducts = flow(function* (sheetName) {
    this.startRequest();
    try {
      const data = yield marketplaceApi.createManyProducts({ sheet_name: sheetName });
      console.log(`received ${data.job_id}, opening listener to the firestore document`);
      this.productUploadQueueListener(data.job_id);
    } catch (e) {
      console.log(e);
      let errorMessage = `${e.response.data?.message ?? e.message}`;
      if (e.response.data.data && Object.keys(e.response.data.data).length > 0) {
        errorMessage += `: ${e.response.data.data.map((error) => `${error.row}: ${error.error}`).join(" ")}`;
      }
      this.onError(new Error(errorMessage));
    } finally {
      this.endRequest();
    }
  });

  updateProduct = flow(function* (productID, data) {
    this.startRequest();
    try {
      const product = yield marketplaceApi.updateProduct(productID, data);
      this.addProducts([product]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  deleteProduct = flow(function* (productID) {
    this.startRequest();
    try {
      const product = yield marketplaceApi.deleteProduct(productID);
      const index = this.products.findIndex((product) => product.productID === productID);
      this.products.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * Creates a new product in state or overwrites an existing entry with new data
   * @param {Array} newProducts
   */
  addProducts(newProducts) {
    newProducts.forEach((newProduct) => {
      const existing = this.products.find((existingProduct) => {
        return existingProduct.productID === newProduct._id; // server uses id key for all objects
      });

      if (!existing) {
        const newProductModel = new ProductModel(newProduct);
        if (newProducts.length > 1) {
          this.products.push(newProductModel);
        } else {
          this.products.unshift(newProductModel);
        }
      } else {
        existing.update(newProduct);
      }
    });
  }

  addProductsWithQuery(newProducts) {
    newProducts.forEach((newProduct) => {
      const existing = this.productWithQuery.find((existingProduct) => {
        return existingProduct.productID === newProduct._id; // server uses id key for all objects
      });

      if (!existing) {
        const newProductModel = new ProductModel(newProduct);
        if (newProducts.length > 1) {
          this.productWithQuery.push(newProductModel);
        } else {
          this.productWithQuery.unshift(newProductModel);
        }
      } else {
        existing.update(newProduct);
      }
    });
  }

  productUploadQueueListener(documentId) {
    // Listen for updates to the document, add new products as they come in to the product list
    // add errors to some error ui somewhere
    console.log(`listening to ${documentId}`);
    onSnapshot(doc(firestore, "marketplace_job_queue", documentId), async (doc) => {
      const data = doc.data();
      this.jobInProgress = data;
      console.log(`progress is ${data.success.length + data.failed.length}/${data.total}`);
      if (data.success.length > 0) {
        for (const job of data.success) {
          if (!this.products.find((product) => product.productID === job._id)) {
            const data = await this.marketplaceApi.getOneProduct(job._id);
            this.addProducts([{ _id: job._id, ...data }]);
          }
        }
      }

      if (data.failed) {
        // add the failed writes to a UI with the reasons for failure failed is an array of {error: string, row: string}
      }

      if (data.success.length + data.failed.length === data.total) {
        console.log(`job has processed all ${data.total} writes and is finished`);
        console.log(`${data.success.length} were successful`);
        console.log(`${data.failed.length} failed`);
      }

      if (data.job_status === "failed") {
        // something went very wrong and no products have been written, alert the user, there will also be an error key in the document
        // error is a string
        console.log(data.error);
      }

      if (data.job_status === "complete") {
        // the job is finished, some products may have failed, but at least one was written
      }
    });
  }

  prevPage = () => {
    if (this.page > 0) {
      this.end = false;
      this.page -= 1;
      this.getAllProducts();
    }
  };

  nextPage = async () => {
    if (!this.end) {
      this.page += 1;
      await this.getAllProducts();
    }
  };

  resetPage = () => {
    this.page = 0;
    this.getAllProducts();
  };
}
