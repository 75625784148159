import { CHER_BASE_URL } from ".";
import wheringFetch from "../../helpers/fetch";

const route = "admin";
const baseUrl = CHER_BASE_URL;

const authApi = {
  login: async (email, password) => {
    return wheringFetch(baseUrl, `${route}/login`, "POST", { email, password });
  },
  logout: async () => {
    return wheringFetch(baseUrl, `${route}/logout`, "POST");
  },
};

export default authApi;
