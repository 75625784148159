import wheringFetch from "../../helpers/fetch";
import { CHER_BASE_URL } from ".";

const baseUrl = CHER_BASE_URL;

const basicsApi = {
  get: async (page) => {
    return wheringFetch(baseUrl, `basics?page=${page}&limit=30`, "GET");
  },
  create: async (data) => {
    return wheringFetch(baseUrl, `basics`, "POST", data);
  },
  update: async (basicID, data) => {
    return wheringFetch(baseUrl, `basics/${basicID}`, "PATCH", data);
  },
  delete: async (basicID) => {
    return wheringFetch(baseUrl, `basics/${basicID}`, "DELETE");
  },
};

export default basicsApi;
