import { makeAutoObservable } from "mobx";

export default class UcdbReportedModel {
  _id;
  image_url;
  mongo_id;
  reason;
  report_date;
  reporting_user;
  actioned;
  created_at;
  updated_at;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this._id = data._id;
    this.image_url = data.image_url;
    this.mongo_id = data.mongo_id;
    this.reason = data.reason;
    this.report_date = data.report_date;
    this.reporting_user = data.reporting_user;
    this.actioned = data.actioned;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
  }
}
