import axios from "axios";
import React, { useEffect, useState } from "react";
import { firestore } from "../../../config/firebase";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";
import { toast } from "react-toastify";

export const PiecesHarness = () => {
  const [pieces, setPieces] = useState([]);
  const [pieceByTaxonomy, setPiecesByTaxonomy] = useState({
    tops: [],
    bottoms: [],
    outerwear: [],
    full: [],
    intimates: [],
    bags: [],
    footwear: [],
    accessories: [],
  });
  const [taxFilter, setTaxFilter] = useState(false);
  const [userId, setUserId] = useState("ezxQEkV232aLJSsmNpCH05kNPoH3");
  const [taxonomy, setTaxonomy] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTaxonomy();

    async function getTaxonomy() {
      try {
        const response = await axios.get("https://tai.whering.co.uk/style/v1/taxonomy?type=full");
        setTaxonomy(response.data);
      } catch (e) {
        toast("failed to fetch taxonomy, please try again later");
      } finally {
        setIsLoading(false);
      }
    }
  }, []);

  const getUserPieces = async () => {
    setIsLoading(true);
    const piecesRef = collection(firestore, "pieces");
    const piecesQuery = query(piecesRef, where("user_id", "==", userId));
    const piecesSnap = await getDocs(piecesQuery);

    for (const [slot, value] of Object.entries(taxonomy)) {
      let taxonomies = [];
      if (value._children) {
        for (const firstChild of value._children) {
          if (firstChild._children) {
            for (const secondChild of firstChild._children) {
              taxonomies.push(secondChild.id);
            }
          } else {
            taxonomies.push(firstChild.id);
          }
        }
      } else {
        taxonomies.push(value.id);
      }
      piecesSnap.docs.forEach((snap) => {
        const data = snap.data();
        if (taxonomies.includes(data.tax)) {
          setPiecesByTaxonomy((prev) => {
            return { ...prev, [value.id]: prev[value.id] ? [...prev[value.id], data] : [data] };
          });
        }
      });
    }

    setPieces(piecesSnap.docs.map((snap) => ({ id: snap.id, ...snap.data() })));
    setIsLoading(false);
  };

  return (
    <div>
      <h1>Wardrobe - Pieces Harness</h1>
      <div>
        <div>
          <input placeholder="Enter user id" value={userId} onChange={(el) => setUserId(el.target.value)} />
        </div>

        <button onClick={getUserPieces}>Submit</button>
      </div>
      <div>
        <p style={{ cursor: "pointer", textDecoration: "underline" }} onClick={() => setTaxFilter(false)}>
          All: {pieces.length}
        </p>
        {Object.keys(pieceByTaxonomy).map((tax) => (
          <p style={{ cursor: "pointer", textDecoration: "underline" }} key={tax} onClick={() => setTaxFilter(tax)}>
            {tax}: {pieceByTaxonomy[tax].length}
          </p>
        ))}
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {!isLoading ? (
          taxFilter ? (
            pieceByTaxonomy[taxFilter].map((p) => <PieceContainer p={p} />)
          ) : (
            pieces.map((p) => <PieceContainer p={p} />)
          )
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};

const PieceContainer = ({ p }) => {
  return (
    <div key={p.id} style={{ display: "flex", flexDirection: "column", width: 300, alignItems: "center" }}>
      <img src={p.nobg_image_url ?? p.image_url} style={{ height: 300, objectFit: "contain", maxWidth: "100%" }} />
      <div>
        <p>ID: {p.id}</p>
        <p>Tax: {p.tax}</p>
        <p>Worn Count: {p.worn_count}</p>
        <p>Scheduled Worn Count: {p.scheduled_worn_count}</p>
        <p>Manual Worn Count: {p.manual_worn_count}</p>
      </div>
    </div>
  );
};
