import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";
import MarketplaceStore from "../../data/stores/MarketplaceStore";
import { countriesList } from "../../helpers/countriesList";

function Retailers({ history }) {
  /**
   * @type {{marketplaceStore: MarketplaceStore}}
   */
  const { marketplaceStore } = useStores();

  useEffect(() => {
    async function getRetailers() {
      if (marketplaceStore.retailers.length === 0) {
        await marketplaceStore.getRetailers();
      }
    }
    getRetailers();
  }, []);

  const goToRetailer = (retailerID) => {
    history.push(`/marketplace/retailers/${retailerID}`);
  };

  return (
    <TableWrapper
      showLoading={marketplaceStore.isFetching}
      title="Marketplace - Retailers"
      onAddNewClick={() => history.push(`/marketplace/retailers/new`)}
    >
      <Table className="model-table">
        <colgroup>
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Locations</th>
          </tr>
        </thead>
        <tbody>
          {marketplaceStore.retailers.map((retailer) => (
            <tr key={retailer._id}>
              <td>
                <img src={retailer.logo_image_url} height={100} alt="category" />
              </td>
              <td>{retailer.name}</td>
              <td>
                {retailer.locations?.map((countryCode) => (
                  <p style={{ margin: "5px 0" }}>{countriesList.find((c) => c.country_code === countryCode).name}</p>
                ))}
              </td>
              <td width={1}>
                <Button onClick={() => goToRetailer(retailer.retailerID)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default observer(Retailers);
