import axios from "axios";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

export default function WpickReplayModal({ replayRequest, setReplayRequest }) {
  const [loading, setLoading] = useState(true);
  const [replayResult, setReplayResult] = useState(null);

  useEffect(() => {
    async function makeRequest() {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_WHERING_STYLING_URL}${replayRequest}&replay=true`);
        const { data } = response;
        setReplayResult(data);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    if (replayRequest) {
      makeRequest();
    }
  }, [replayRequest]);

  return (
    <Modal show={replayRequest} onHide={() => setReplayRequest(false)} size="xl">
      <Modal.Header closeButton>
        <h1>Wpick Request Replay</h1>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <p>Loading</p>
        ) : replayResult.outfits.length > 0 ? (
          replayResult.outfits.map((outfit) => {
            return (
              <div style={{ display: "flex", gap: 20 }} key={outfit.outfit_hash}>
                <div>
                  <img src={outfit.image_url} height={300} width={200} />
                </div>
                <div>
                  <p>
                    {outfit.outfitClass.title} ({outfit.outfitClass.id})
                  </p>
                  {outfit.outfitClass.slots.map((slot, index) => (
                    <p key={`slot.${slot.taxonomy.join(",")}.${index}`}>{slot.taxonomy.join(",")}</p>
                  ))}
                </div>
                {outfit.pieces.map((piece) => {
                  return (
                    <div
                      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                      key={piece.piece_id}
                    >
                      <img
                        src={piece.cropped_image_url ?? piece.nobg_image_url ?? piece.image_url}
                        height={150}
                        width={120}
                      />
                      <span>{piece.tax}</span>
                    </div>
                  );
                })}
              </div>
            );
          })
        ) : (
          <p>No results</p>
        )}
      </Modal.Body>
    </Modal>
  );
}
