import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./components/Header";
import { useStores } from "./context/mobx-context";
import ROUTES, { RenderRoutes } from "./helpers/routes";

function App() {
  const { authStore } = useStores();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    hydrateStores();

    async function hydrateStores() {
      await authStore.checkForPersistedUser();
      setLoaded(true);
    }
  }, []);
  if (!loaded) {
    return (
      <div>
        <h1>Loading</h1>
      </div>
    );
  } else {
    return (
      <Router>
        <Header />
        <RenderRoutes routes={ROUTES} />
      </Router>
    );
  }
}

export default App;
