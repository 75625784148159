import { flow, makeObservable, observable } from "mobx";
import CareerModel from "../models/CareerModel";
import ApiBasedStore from "./ApiBasedStore";

export default class CareerStore extends ApiBasedStore {
  careerApi;

  constructor(rootStore, careerApi) {
    super(rootStore);
    this.careerApi = careerApi;
    this.jobs = [];
    this.end = false;

    makeObservable(this, {
      jobs: observable,
      end: observable,
    });
  }

  fetchJobs = flow(function* (initial) {
    this.startRequest();

    try {
      const response = yield this.careerApi.get();
      this.addJobs(response);
      this.end = response.end;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  create = flow(function* (params) {
    this.startRequest();

    try {
      const response = yield this.careerApi.create(params);
      this.addJobs([response.job]);
      return response._id;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  update = flow(function* (id, params) {
    this.startRequest();

    try {
      const jobs = yield this.careerApi.update(id, params);
      this.addJobs([jobs.job]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  delete = flow(function* (id) {
    this.startRequest();
    try {
      yield this.careerApi.delete(id);
      const index = this.jobs.findIndex((job) => job.id === id);
      this.jobs.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * Creates a new job in state or overwrites an existing entry with new data
   * @param {Array} newJobs
   */
  addJobs(newJobs) {
    newJobs.forEach((newJob) => {
      const existing = this.jobs.find((existingJob) => {
        return existingJob._id === newJob._id; // server uses id key for all objects
      });

      if (!existing) {
        const newJobModel = new CareerModel(newJob);

        if (newJobs.length > 1) {
          this.jobs.push(newJobModel);
        } else {
          this.jobs.unshift(newJobModel);
        }
      } else {
        existing.update(newJob);
      }
    });
  }
}
