import { makeAutoObservable } from "mobx";

export default class CategoriesModel {
  categoryID;
  description;
  slug;
  image_url;
  name;
  metadata;
  taxonomy_level;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this.categoryID = data._id;
    this.description = data.description;
    this.slug = data.slug;
    this.image_url = data.image_url;
    this.name = data.name;
    this.metadata = data.metadata;
    this.taxonomy_level = data.taxonomy_level;
  }
}
