import { makeAutoObservable } from "mobx";

export default class AdminUserModel {
  userID;
  firstname;
  surname;
  email;
  role;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this.userID = data.id;
    this.firstname = data.first_name;
    this.surname = data.last_name;
    this.email = data.email;
    this.role = data.role;
  }
}
