import { makeAutoObservable } from "mobx";

export default class ArticleModel {
  articleID;
  title;
  imageUrl;
  category;
  body;
  published;
  created;
  updated;
  url;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this.articleID = data.id;
    this.title = data.title;
    this.imageUrl = data.imageUrl;
    this.category = data.category;
    this.body = data.body;
    this.published = data.published;
    this.created = data.created;
    this.updated = data.updated;
    this.url = data.url;
  }
}
