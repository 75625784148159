import { observer } from "mobx-react";
import React from "react";
import { Button, Col, Container, FormControl, InputGroup, Row, Spinner } from "react-bootstrap";
import { IoSearch } from "react-icons/io5";

function TableWrapper({
  children,
  title,
  onSearchChange,
  onSearchClick,
  onAddNewClick,
  onAddMultipleClick,
  showLoading,
  searchPlaceholderText,
  searchValue,
}) {
  return (
    <Container
      fluid="xxl"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 50,
      }}
    >
      {showLoading && <Spinner animation="border" style={{ margin: "20px 0" }} />}
      <h1>{title}</h1>
      <Row style={{ margin: "1rem", width: "100%" }}>
        <Col>
          {onSearchChange && (
            <InputGroup>
              <FormControl
                onChange={onSearchChange}
                placeholder={searchPlaceholderText ? searchPlaceholderText : "Start typing to search..."}
                value={searchValue}
              />
              <Button variant="secondary" onClick={onSearchClick}>
                <IoSearch />
              </Button>
            </InputGroup>
          )}
        </Col>
        <Col></Col>
        <Col style={{ display: "flex", justifyContent: "flex-end" }}>
          {onAddMultipleClick && (
            <Button variant="success" onClick={onAddMultipleClick}>
              Add Multiple
            </Button>
          )}
          {onAddNewClick && (
            <Button variant="success" onClick={onAddNewClick}>
              + Add New
            </Button>
          )}
        </Col>
      </Row>
      {children}
    </Container>
  );
}

export default observer(TableWrapper);
