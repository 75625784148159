import { runInAction } from "mobx";
import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import { RiDeleteBinLine } from "react-icons/ri";
import { toast } from "react-toastify";
import ProductSelectModal from "../../components/Marketplace/ProductSelectModal";
import { useStores } from "../../context/mobx-context";

function NewEdit({ match, history }) {
  const editID = match.params.editID;
  const inputRef = useRef(null);
  const metaRef = useRef(null);
  const { marketplaceStore } = useStores();

  const [formState, setFormState] = useState({
    name: "",
    description: "",
    image_url: "",
    locations: [],
    metadata: {},
    products: [],
    slug: "",
  });

  const [imageFile, setImageFile] = useState(false);
  const [metadataImageFile, setMetadataImageFile] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      if (marketplaceStore.edits.length === 0) {
        await marketplaceStore.getEdits();
        await marketplaceStore.getAllProducts();
      }
      if (editID !== "new") {
        const edit = marketplaceStore.edits.find((edit) => edit._id === editID);
        setFormState(edit);
        await marketplaceStore.getProductsWithQuery(`?edit=${edit.slug}&limit=1000`);
        setFormState({ ...edit, products: marketplaceStore.productWithQuery });
      }
    }

    getData();
    return () => {
      runInAction(() => {
        // clear the products on dismount so the list refreshes when changing edits
        marketplaceStore.productWithQuery = [];
      });
    };
  }, []);

  const handleSave = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (formState.description.length > 66) {
      toast(`Edit description must be less than 66 characters, currently ${formState.description.length}`, {
        type: "warning",
      });
      return;
    }

    if (editID === "new" && !imageFile) {
      toast("You must include an image", { type: "warning" });
      return;
    }

    // if (!formState?.image_url) {
    //   toast("You must include an image", { type: "warning" });
    //   return;
    // }

    let data = { ...formState };

    if (editID === "new") {
      const updateData = {
        ...data,
        products: data.products.map((item) => item.productID),
      };

      const newData = new FormData();
      newData.append("data", JSON.stringify(updateData));

      if (updateData?.image) {
        newData.append("image", updateData.image);
      }
      if (updateData?.metadata_image) {
        newData.append("metadata_image", updateData.metadata_image);
      }
      await marketplaceStore.createEdit(newData);
    } else {
      const edit = marketplaceStore.edits.find((edit) => edit._id === editID);
      if (!edit) {
        toast("cannot update an edit that does not exist", { type: "error" });
        return;
      }

      // concatenate the formstate and original data object so created and updated are maintained
      const updateData = Object.assign({ ...edit, ...data, products: data.products.map((item) => item.productID) });

      const newData = new FormData();
      newData.append("data", JSON.stringify(updateData));

      if (updateData?.image) {
        newData.append("image", updateData.image);
      }
      if (updateData?.metadata_image) {
        newData.append("metadata_image", updateData.metadata_image);
      }

      await marketplaceStore.updateEdit(editID, newData);
    }

    if (!marketplaceStore.hasFailed) {
      toast("Edit Saved!", { type: "success" });
      history.goBack();
    }
  };

  const onChangeImage = (event, key) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    if (key === "image") {
      setImageFile(URL.createObjectURL(file));
    } else {
      setMetadataImageFile(URL.createObjectURL(file));
    }

    setFormState((prev) => {
      return {
        ...prev,
        [key]: file,
      };
    });
  };

  const handleShow = async () => {
    setShow(true);
    await marketplaceStore.getRetailers();
  };

  const updateProduct = (productObj, alreadyInEdit) => {
    // if the product is already in there, remove it, otherwise add it to the list
    if (alreadyInEdit) {
      setFormState((prev) => ({
        ...prev,
        products: prev.products.filter((product) => product.productID !== productObj._id),
      }));
    } else {
      setFormState((prev) => ({ ...prev, products: [...prev.products, { productID: productObj._id, ...productObj }] }));
    }
  };

  const handleFilter = async (query) => {
    await marketplaceStore.getAllProducts(query);
  };

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <input
        type="file"
        id="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => onChangeImage(e, "image")}
      />
      <input
        type="file"
        id="file2"
        ref={metaRef}
        style={{ display: "none" }}
        onChange={(e) => onChangeImage(e, "metadata_image")}
      />
      <h1>{editID === "new" ? "Add " : "Amend "} an Edit</h1>

      <Col className="mt-5 mb-3">
        <h3>Image</h3>

        <div style={{ position: "relative", width: "fit-content" }}>
          {imageFile ? (
            <img src={imageFile} alt={formState?.name} height={300} />
          ) : (
            <img src={formState?.image_url} alt={formState?.name} height={300} />
          )}

          <Button style={{ position: "absolute", right: 0, margin: 10 }} onClick={() => inputRef.current.click()}>
            {editID === "new" ? "Add Image" : "Change Image"}
          </Button>
        </div>
      </Col>
      <Form className="model-form" onSubmit={handleSave}>
        <Form.Group className="mb-3">
          <Form.Label> Name</Form.Label>
          <Form.Control
            placeholder="Enter an Edit Name"
            name="name"
            required
            value={formState.name}
            onChange={(event) =>
              setFormState((prev) => {
                return { ...prev, name: event.target.value };
              })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label> Description</Form.Label>
          <Form.Control
            placeholder="Enter an Edit Description"
            name="description"
            required
            value={formState.description}
            as="textarea"
            rows={3}
            onChange={(event) =>
              setFormState((prev) => {
                return { ...prev, description: event.target.value };
              })
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label> URL slug</Form.Label>
          <Form.Control
            placeholder="Enter a URL slug"
            name="slug"
            required
            value={formState.slug}
            onChange={(event) =>
              setFormState((prev) => {
                return { ...prev, slug: event.target.value };
              })
            }
          />
        </Form.Group>

        <h3 className="mt-5">Sharing Metadata</h3>

        <Form.Group className="mb-3">
          <Form.Label> Title</Form.Label>
          <Form.Control
            placeholder="Enter an metadata title"
            name="title"
            required
            value={formState.metadata.title}
            onChange={(event) =>
              setFormState((prev) => {
                return {
                  ...prev,
                  metadata: {
                    ...formState.metadata,
                    title: event.target.value,
                  },
                };
              })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label> Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter an metadata description"
            name="description"
            required
            value={formState.metadata.description}
            onChange={(event) =>
              setFormState((prev) => {
                return {
                  ...prev,
                  metadata: {
                    ...formState.metadata,
                    description: event.target.value,
                  },
                };
              })
            }
          />
        </Form.Group>

        <div style={{ position: "relative", width: "fit-content" }}>
          {metadataImageFile ? (
            <img src={metadataImageFile} alt={formState?.name} height={300} />
          ) : (
            <img src={formState?.metadata?.image_url} alt={formState?.name} height={200} />
          )}

          <Button style={{ position: "absolute", right: 0, margin: 10 }} onClick={() => metaRef.current.click()}>
            Change Metadata Image
          </Button>
        </div>

        <Row className="mt-5">
          <div className="d-flex align-items-center justify-content-between mt-4 mb-5">
            <h3>Products</h3>
            <Button
              onClick={handleShow}
              // disabled={formState?.brand_spotlight?.products.length === 4}
            >
              Add Product <BsPlusCircle />
            </Button>
          </div>

          {formState?.products.length !== 0 ? (
            formState?.products.map((product, i) => {
              return (
                <Col lg={2} sm={6} key={product?._id}>
                  <div className="h-75 text-center" style={{ position: "relative" }}>
                    <img
                      src={product?.images && product?.images[0]}
                      alt={product?.product_name}
                      className="img-fluid h-75"
                      height={350}
                      style={{ objectFit: "cover" }}
                    />
                    <small className="fw-bolder d-block">{product?.product_name}</small>
                    <RiDeleteBinLine
                      style={{ position: "absolute", top: 0, margin: "10px 50px" }}
                      size={40}
                      color="white"
                      className="bg-danger py-2 cursor"
                      onClick={() => {
                        const newProducts = formState.products.filter((item) => item?.productID !== product?.productID);
                        setFormState({ ...formState, products: newProducts });
                      }}
                    />
                  </div>
                </Col>
              );
            })
          ) : (
            <p>No products </p>
          )}
        </Row>

        {show && (
          <ProductSelectModal
            show={show}
            handleClose={() => setShow(false)}
            modalHeader={"Add Products to Edit"}
            products={marketplaceStore.products}
            marketplaceStore={marketplaceStore}
            updateProduct={updateProduct}
            selectedIds={formState?.products.map((pro) => pro?.productID)}
            handleFilter={(query) => handleFilter(query)}
          />
        )}

        <Button variant="success" type="submit" className="mt-5">
          Save
        </Button>
      </Form>
    </Container>
  );
}

export default NewEdit;
