import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Container, Form, InputGroup, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";

import BasicsStore from "../../data/stores/BasicsStore";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

const genderOptions = [
  { value: "male", label: "Men" },
  { value: "female", label: "Women" },
];

function NewBasics({ match }) {
  /**
   * @type {{basicsStore: BasicsStore}}
   */
  const { basicsStore } = useStores();
  const basicID = match.params.basicID;
  const history = useHistory();
  const inputRef = useRef(null);

  const [showDelete, setShowDelete] = useState(false);
  const [taxonomy, setTaxonomy] = useState([]);
  const [imagePreview, setImagePreview] = useState("");
  const [imageType, setImageType] = useState("url");
  const [imageFile, setImageFile] = useState(null);
  const [image, setImage] = useState(null);
  const [formState, setFormState] = useState({
    image_url: "",
    item_url: "",
    gender: "",
    tax: "",
  });

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      if (basicsStore.basics.length === 0) {
        await basicsStore.get();
      }

      if (basicID !== "new") {
        const basic = basicsStore.basics.find((basic) => basic.id === basicID);
        setFormState({ ...basic });
        setImagePreview(basic?.image_url);
        const { data } = await axios.get("https://tai.whering.co.uk/style/v1/taxonomy?type=flat");

        const taxArray = Object.values(data).map((item) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setTaxonomy(taxArray);
      }
    }

    getData();
  }, []);

  const updateFormState = (event) => {
    if (event.target.name.includes("metadata")) {
      const [metadata, fieldKey] = event.target.name.split(".");
      setFormState((prev) => {
        return {
          ...prev,
          [metadata]: { ...prev[metadata], [fieldKey]: event.target.value },
        };
      });
    } else {
      setFormState((prev) => {
        return {
          ...prev,
          [event.target.name]: event.target.value,
        };
      });
    }
  };

  const onChangeImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    setImageFile(URL.createObjectURL(file));
    setImage(file);

    setFormState((prev) => {
      return {
        ...prev,
        image_url: "",
      };
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();

    if (!formState.image_url && !image) {
      toast("You must include an image", { type: "info" });
      return;
    }
    if (!formState.gender) {
      toast("You must include gender", { type: "info" });
      return;
    }
    if (!formState.tax && basicID !== "new") {
      toast("You must include a tax", { type: "info" });
      return;
    }

    const data = new FormData();
    data.append("data", JSON.stringify(formState));
    if (image) {
      data.append("image", image);
    }

    if (basicID === "new") {
      await basicsStore.create(data);

      if (!basicsStore.hasFailed) {
        toast("Basic successfully created", { type: "success" });
        history.goBack();
      }
    } else {
      const basic = basicsStore.basics.find((basic) => basic.id === basicID);
      if (!basic) {
        toast("you cannot update a basic that does not exist", {
          type: "error",
        });
        return;
      }

      await basicsStore.update(basicID, data);

      if (!basicsStore.hasFailed) {
        toast("Basic successfully updated", { type: "success" });
        history.goBack();
      }
    }
  };

  const handleDelete = async () => {
    await basicsStore.delete(basicID);
    if (!basicsStore.hasFailed) {
      toast("Basic successfully deleted", { type: "success" });
      history.goBack();
    }
  };

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <h1>{basicID === "new" ? "Add" : "Amend "} Basic</h1>
      <Form onSubmit={handleSave} style={{ marginBottom: 25 }}>
        {formState?.image_url && (
          <div className="my-3">
            <img src={formState?.image_url} alt={formState.item_url} height={300} />
          </div>
        )}

        {imageFile && (
          <div className="my-3">
            <img src={imageFile} alt={formState.item_url} height={300} />
          </div>
        )}

        <input type="file" id="file" ref={inputRef} style={{ display: "none" }} onChange={onChangeImage} />

        <Form.Group className="mb-3">
          <Form.Label>Image Type </Form.Label>{" "}
          <select
            onChange={(e) => {
              setImageType(e.target.value);
              setImageFile(null);
              setImagePreview("");
              setFormState({ ...formState, image_url: "" });
            }}
            value={imageType}
            className="w-2"
          >
            <option value="url">URL</option>
            <option value="file-upload">File Upload</option>
          </select>
        </Form.Group>

        {imageType === "url" ? (
          <Form.Group className="mb-3">
            <Form.Label>Image URL</Form.Label>
            <InputGroup>
              <Form.Control
                placeholder="Enter the basic image URL"
                name="imagePreview"
                value={imagePreview}
                onChange={(e) => setImagePreview(e.target.value)}
              />
              <Button
                variant="outline-primary"
                onClick={() => {
                  setFormState({ ...formState, image_url: imagePreview, image: null });
                }}
              >
                Add image
              </Button>
            </InputGroup>
          </Form.Group>
        ) : (
          <Form.Group className="mb-3">
            <Form.Label className="d-block">Image Upload</Form.Label>
            <Button onClick={() => inputRef.current.click()}>Click to select image</Button>
          </Form.Group>
        )}

        <Form.Group className="mb-3">
          <Form.Label>Item URL</Form.Label>
          <Form.Control
            placeholder="Enter the item URL"
            name="item_url"
            value={formState.item_url}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Gender</Form.Label>
          <Form.Select required name="gender" value={formState.gender} onChange={updateFormState}>
            <option value="">Please select a gender</option>
            {genderOptions.map((gender, i) => (
              <option key={i} value={gender.value}>
                {gender.label}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        {basicID !== "new" && (
          <Form.Group className="mb-3">
            <Form.Label>Taxonomy</Form.Label>
            <Select
              onChange={(selectedOption) => {
                setFormState({ ...formState, tax: selectedOption.value });
              }}
              value={taxonomy.filter((option) => option.value === formState.tax)}
              defaultValue
              options={taxonomy}
              styles={{
                control: (base, _state) => ({
                  ...base,
                  minHeight: "45px",
                  border: "1px solid #ced4da",
                  boxShadow: "none",
                }),
              }}
            />
          </Form.Group>
        )}
        <Alert variant="danger" show={showDelete}>
          <Alert.Heading>This action is irreversible.</Alert.Heading>
          <p>The data you are about to delete will not be recoverable, are you sure you want to continue?</p>
          <Button variant="outline-danger" onClick={handleDelete}>
            Yes, delete
          </Button>
          <Button onClick={() => setShowDelete(false)}>No, cancel</Button>
        </Alert>
        <Button variant="success" type="submit">
          {basicsStore?.isFetching ? <Spinner animation="border" style={{ margin: "20px 0" }} /> : "Save"}
        </Button>
        <Button variant="danger" onClick={() => setShowDelete(true)}>
          Delete
        </Button>
      </Form>
    </Container>
  );
}

export default NewBasics;
