import React from "react";
import ReactDOM from "react-dom";
import "./styles/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { StoreProvider } from "./context/mobx-context";
import RootStore from "./data/stores/RootStore";
import { ToastContainer } from "react-toastify";

const rootStore = new RootStore();

ReactDOM.render(
  <React.Fragment>
    <StoreProvider store={rootStore}>
      <App />
      <ToastContainer
        position="top-center"
        pauseOnFocusLoss={false}
        pauseOnHover={false}
        autoClose={3000}
        hideProgressBar={true}
      />
    </StoreProvider>
  </React.Fragment>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
