import { observer } from "mobx-react";
import React from "react";
import { Button, Navbar, NavDropdown, NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/whering-logo.png";
import { useStores } from "../context/mobx-context";

function Header() {
  const { authStore } = useStores();

  return (
    <Navbar className="header">
      <Navbar.Brand>
        <Link to="/">
          <img src={logo} height="50" width="170" />
        </Link>
      </Navbar.Brand>
      {authStore.isAuthenticated && (
        <>
          <Navbar.Collapse>
            <NavDropdown title="Admin" className="menu-dropdown">
              <NavDropdown.Item as="div">
                <Link to="/admin">Users</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Marketplace" className="menu-dropdown">
              <NavDropdown.Item as="div">
                <Link to="/marketplace/edits">Edits</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/marketplace/products">Products</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/marketplace/retailers">Retailers</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/marketplace/services">Services</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/marketplace/categories">Categories</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/marketplace/spotlights">Spotlights</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/marketplace/brands">Brands</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Basics" className="menu-dropdown">
              <NavDropdown.Item as="div">
                <Link to="/basics/new">Create New</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/basics">Manage Existing</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Thoughts" className="menu-dropdown">
              <NavDropdown.Item as="div">
                <Link to="/thoughts/new">Create New</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/thoughts">Manage Existing</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/images/articles">Image Gallery</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavItem as="div">
              <Link to="/users">User Management</Link>
            </NavItem>
            <NavDropdown title="Careers" className="menu-dropdown">
              <NavDropdown.Item as="div">
                <Link to="/careers/new">Create New</Link>
              </NavDropdown.Item>
              <NavDropdown.Item as="div">
                <Link to="/careers">Manage Existing</Link>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Whering Database" className="menu-dropdown">
              <NavDropdown.Item as="div">
                <Link to="/ucdbreported">Reported Items</Link>
              </NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>Signed in as: {authStore.user.firstname}</Navbar.Text>
            <Button onClick={() => authStore.logout()} size="sm" variant="outline-danger" style={{ marginLeft: 10 }}>
              Logout
            </Button>
          </Navbar.Collapse>
        </>
      )}
    </Navbar>
  );
}

export default observer(Header);
