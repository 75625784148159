import { action, flow, makeObservable, observable } from "mobx";
import ApiBasedStore from "./ApiBasedStore";
import UcdbReportedModel from "../models/UcdbReportedModel";

export default class UcdbReportedStore extends ApiBasedStore {
  ucdbReportedApi;
  page;

  constructor(rootStore, ucdbReportedApi) {
    super(rootStore);
    this.ucdbReportedApi = ucdbReportedApi;
    this.reporteds = [];
    this.page = 0;
    this.end = false;

    makeObservable(this, {
      reporteds: observable,
      page: observable,
      end: observable,
      prevPage: action,
      nextPage: action,
    });
  }

  fetchReporteds = flow(function* (initial) {
    this.startRequest();

    try {
      const response = yield this.ucdbReportedApi.get();
      this.addReporteds(response);
      this.end = response.end;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  create = flow(function* (params) {
    this.startRequest();

    try {
      const response = yield this.ucdbReportedApi.create(params);
      this.addReporteds([response.reported]);
      return response._id;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  update = flow(function* (id, params) {
    this.startRequest();

    try {
      const response = yield this.ucdbReportedApi.update(id, params);
      this.addReporteds(response.updated_documents);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * Creates a new job in state or overwrites an existing entry with new data
   * @param {Array} newReporteds
   */

  addReporteds(newReporteds) {
    newReporteds.forEach((newReported) => {
      const existing = this.reporteds.find((existingReported) => {
        return existingReported._id === newReported._id; // server uses id key for all objects
      });

      if (!existing) {
        const newReportedModel = new UcdbReportedModel(newReported);

        if (newReporteds.length > 1) {
          this.reporteds.push(newReportedModel);
        } else {
          this.reporteds.unshift(newReportedModel);
        }
      } else {
        existing.update(newReported);
      }
    });
  }
  prevPage = async () => {
    if (this.page > 0) {
      this.page -= 1;
    }
  };

  nextPage = async () => {
    if (!this.end) {
      this.page += 1;
      if (this.brands.length <= 20 * this.page) {
        await this.fetchBrands();
      }
    }
  };
}
