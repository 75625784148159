/**
 * Converts date in the format YYYY-MM-DD to DD-MM-YYYY
 * @param {String} date
 * @returns {String}
 */
export const convertDate = (date) => {
  const [year, month, day] = date.split("-");

  const result = [day, month, year].join("-");

  return result;
};
