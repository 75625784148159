import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { BsPlusCircle } from "react-icons/bs";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";
import Select from "react-select/creatable";
import { AsyncPaginate } from "react-select-async-paginate";
import { components } from "react-select";
import { RiDeleteBinLine } from "react-icons/ri";
import { countriesList } from "../../helpers/countriesList";
import ProductSelectModal from "../../components/Marketplace/ProductSelectModal";
import axios from "axios";

function NewSpotlight({ match, history }) {
  const { marketplaceStore, brandStore } = useStores();
  const spotlightID = match.params.spotlightID;

  const [show, setShow] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalType, setModalType] = useState("");

  const [selectedLocations, setSelectedLocations] = useState(null);
  const [showRetailers, setShowRetailers] = useState(true);

  const [formState, setFormState] = useState({
    featured_edits: [],
    brand_spotlight: { products: [] },
    edit_spotlight: { products: [] },
    trending_brands: [],
    locations: [],
    gender: "",
  });

  const genderOptions = [
    { value: "male", label: "Men" },
    { value: "female", label: "Women" },
    { value: "unisex", label: "All" },
  ];

  const successMessage = spotlightID === "new" ? "Spotlight created!" : "Spotlight updated!";

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      await marketplaceStore.getSpotlights();
      await marketplaceStore.getEdits();
      await marketplaceStore.getRetailers();
      await marketplaceStore.getAllProducts();
      await brandStore.fetchBrands();

      if (spotlightID !== "new") {
        const spotlight = marketplaceStore.spotlights.find((spot) => spot.spotlightID === spotlightID);
        setFormState({
          ...spotlight,
          gender: spotlight?.gender,
        });
        setShowRetailers(spotlight?.brand_spotlight?.collection === "retailer" ? true : false);

        if (spotlight.locations.length > 0) {
          const defaultCountries = countriesList.filter((country) =>
            spotlight?.locations.includes(country?.country_code)
          );
          setSelectedLocations(defaultCountries);
        }
      }
    }

    getData();
  }, []);

  const handleShow = async (modalType, header) => {
    setModalHeader(header);
    setShow(true);
    setModalType(modalType);
  };

  const updateProduct = (productObj) => {
    // Validations
    if (formState?.[modalType].products.map((product) => product._id ?? product.productID).includes(productObj._id)) {
      setFormState((prev) => {
        return {
          ...prev,
          [modalType]: {
            ...prev[modalType],
            products: prev[modalType].products.filter((product) => product._id !== productObj._id),
          },
        };
      });
      return;
    }

    if (formState?.[modalType].products.length === 4) {
      toast("You can only add a maximum of 4 products to this section", {
        type: "error",
      });
      return;
    }

    const updatedProducts = [...formState?.[modalType].products, productObj];
    // Action
    setFormState({
      ...formState,
      [modalType]: {
        ...formState?.[modalType],
        products: updatedProducts,
      },
    });
    toast("Product added!", {
      type: "success",
    });
  };

  const removeProduct = (type, filter) => {
    const newProducts = formState?.[type]?.products.filter((item) => item !== filter);
    setFormState({
      ...formState,
      [type]: {
        ...formState?.[type],
        products: newProducts,
      },
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const prepareData = {
      brand_spotlight: {
        [showRetailers ? "retailer" : "brand"]: showRetailers
          ? formState?.brand_spotlight._id
          : formState?.brand_spotlight._id,
        products: formState?.brand_spotlight?.products.map((item) => item._id ?? item.productID),
      },
      edit_spotlight: {
        edit: formState?.edit_spotlight._id,
        products: formState?.edit_spotlight?.products.map((item) => item._id ?? item.productID),
      },
      featured_edits: formState.featured_edits.map((item) => item._id),
      trending_brands: formState.trending_brands?.map((item) => item._id),
      locations: selectedLocations?.length !== 0 ? selectedLocations?.map((item) => item?.country_code) : ["GLOBAL"],
      gender: formState.gender,
    };

    if (spotlightID === "new") {
      await marketplaceStore.createSpotlight(prepareData);
    } else {
      const spotlight = marketplaceStore.spotlights.find((spot) => spot.spotlightID === spotlightID);
      if (!spotlight) {
        toast("you cannot update a spotlight that does not exist", {
          type: "error",
        });
        return;
      }

      await marketplaceStore.updateSpotlight(spotlightID, prepareData);
    }

    if (!marketplaceStore.hasFailed) {
      toast(successMessage, { type: "success" });
      history.goBack();
    }
  };

  const loadOptions = async (searchQuery, _loadedOptions, { page }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_DIONNE_API_URL}/marketplace/brands?page=${page}&limit=30&user_suggestions=false&search=${searchQuery}`
    );
    const responseJSON = await response.data;
    return {
      options: responseJSON.brands || [],
      hasMore: !response.data.end,
      additional: {
        page: searchQuery ? 0 : page + 1,
      },
    };
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div className="d-flex align-items-center">
            <input type="checkbox" checked={props.isSelected} onChange={() => null} style={{ marginRight: "10px" }} />
            <label className="p-0 m-0">{props.label}</label>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <h1>
        {spotlightID === "new" ? "Add " : "Amend "} {formState?.gender} spotlight
      </h1>
      <Form onSubmit={handleSave} style={{ marginBottom: 25 }}>
        {formState?.locations && !formState?.locations.includes("GLOBAL") && (
          <>
            <section>
              <h3 className="mt-5 mb-0">Gender</h3>
              <Row className="mt-4">
                <Col lg={8}>
                  <Select
                    onChange={(option) => {
                      setFormState((prev) => ({ ...prev, gender: option.value }));
                    }}
                    value={genderOptions.find((opt) => opt.value === formState.gender)}
                    options={genderOptions}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    styles={{
                      control: (base, _state) => ({
                        ...base,
                        minHeight: "60px",
                        border: "0.5px solid #000000",
                        boxShadow: "none",
                      }),
                    }}
                  />
                </Col>
              </Row>
            </section>
            <section>
              <h3 className="mt-5 mb-0">Locations</h3>
              <Row className="mt-4">
                <Col lg={8}>
                  <Select
                    isMulti
                    onChange={(selectedOption) => {
                      setSelectedLocations(selectedOption);
                    }}
                    value={selectedLocations}
                    options={countriesList.filter((country) => country.country_code !== "GLOBAL")}
                    getOptionLabel={(x) => x.name}
                    getOptionValue={(x) => x.country_code}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    styles={{
                      control: (base, _state) => ({
                        ...base,
                        minHeight: "60px",
                        border: "0.5px solid #000000",
                        boxShadow: "none",
                      }),
                    }}
                  />
                </Col>
              </Row>
            </section>
          </>
        )}

        {/* Featured Edits */}
        <section>
          <h3 className="mt-5 mb-0">Featured Edits</h3>
          <small className="fw-bolder text-danger">Select a maximum of 4 items from below</small>
          <Row className="mt-4">
            <Col lg={8}>
              <Select
                isMulti
                onChange={(selectedOption) => {
                  setFormState({
                    ...formState,
                    featured_edits: selectedOption.map((item) => {
                      return {
                        name: item.name,
                        _id: item._id,
                      };
                    }),
                  });
                }}
                value={formState?.featured_edits}
                options={marketplaceStore?.edits}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x._id}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isOptionDisabled={() => formState?.featured_edits?.length === 4}
                components={{
                  Option,
                }}
                styles={{
                  control: (base, _state) => ({
                    ...base,
                    minHeight: "60px",
                    border: "0.5px solid #000000",
                    boxShadow: "none",
                  }),
                }}
              />
            </Col>
          </Row>
        </section>

        {/* Brand Spotlight */}
        <section>
          <h3 className="mt-5">Brand Spotlight</h3>
          <Col sm="auto" className="mb-3">
            <Form.Check
              inline
              type="radio"
              id="custom-switch"
              onClick={(e) => {
                setShowRetailers(true);
                setFormState({ ...formState, brand_spotlight: { products: [] } });
              }}
              label="Show retailers list"
              name="showRetailers"
              checked={showRetailers ?? true}
            />

            <Form.Check
              inline
              type="radio"
              id="custom-switch"
              onClick={(e) => {
                setShowRetailers(false);
                setFormState({ ...formState, brand_spotlight: { products: [] } });
              }}
              label="Show brands list"
              name="showRetailers"
              checked={!showRetailers ?? false}
            />
          </Col>
          <Row>
            {showRetailers ? (
              <Col lg={8}>
                <Select
                  onChange={(selectedOption) => {
                    setFormState({
                      ...formState,
                      brand_spotlight: {
                        ...selectedOption,
                        collection: "retailer",
                        products: [],
                      },
                    });
                  }}
                  value={formState?.brand_spotlight}
                  options={marketplaceStore?.retailers}
                  getOptionLabel={(x) => x.name}
                  getOptionValue={(x) => x._id}
                  styles={{
                    control: (base, _state) => ({
                      ...base,
                      minHeight: "60px",
                      boxShadow: "none",
                    }),
                  }}
                />
              </Col>
            ) : (
              <Col lg={8}>
                <AsyncPaginate
                  loadOptions={loadOptions}
                  getOptionValue={(option) => option._id}
                  getOptionLabel={(option) => option.name}
                  additional={{
                    page: 0,
                  }}
                  isSearchable={true}
                  placeholder="Select a brand"
                  debounceTimeout={500}
                  onChange={(selectedOption) => {
                    setFormState({
                      ...formState,
                      brand_spotlight: {
                        ...selectedOption,
                        collection: "brand",
                        products: [],
                      },
                    });
                  }}
                  value={formState?.brand_spotlight}
                  styles={{
                    control: (base, _state) => ({
                      ...base,
                      minHeight: "60px",
                      boxShadow: "none",
                    }),
                  }}
                />
              </Col>
            )}
          </Row>

          <Row>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-5">
              <h5>Products</h5>
              <Button onClick={() => handleShow("brand_spotlight", formState?.brand_spotlight?.name)}>
                Add Product <BsPlusCircle />
              </Button>
            </div>

            {formState?.brand_spotlight?.products?.length !== 0 ? (
              formState?.brand_spotlight?.products.map((brand) => {
                return (
                  <Col lg={3} key={brand?._id}>
                    <div className="h-100 text-center" style={{ position: "relative " }}>
                      <img
                        src={brand?.images[0]}
                        alt={brand?.product_name}
                        className="img-fluid h-100"
                        style={{ objectFit: "cover" }}
                      />
                      <small className="fw-bolder">{brand?.product_name}</small>
                      <Button
                        onClick={() => removeProduct("brand_spotlight", brand)}
                        variant="outline-danger"
                        style={{ position: "absolute", bottom: "38%", right: "40%" }}
                      >
                        <RiDeleteBinLine size={20} />
                      </Button>
                    </div>
                  </Col>
                );
              })
            ) : (
              <h6 className="text-center">
                Please select <strong>{formState?.brand_spotlight?.name}</strong> related products
              </h6>
            )}
          </Row>
        </section>

        {/* Edit Spotlight */}
        <section className="pt-5">
          <h3 className="mt-5">Edit Spotlight</h3>
          <Row>
            <Col lg={8}>
              <Select
                onChange={(selectedOption) => {
                  setFormState({
                    ...formState,
                    edit_spotlight: { ...selectedOption, products: [] },
                  });
                }}
                value={formState?.edit_spotlight}
                options={marketplaceStore.edits}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x._id}
                styles={{
                  control: (base, _state) => ({
                    ...base,
                    minHeight: "60px",
                    boxShadow: "none",
                  }),
                }}
              />
            </Col>
          </Row>

          <Row>
            <div className="d-flex align-items-center justify-content-between mt-4 mb-5">
              <h5>Products</h5>
              <Button
                onClick={() => {
                  handleShow("edit_spotlight", "Edit Spotlight");
                }}
              >
                Add Product <BsPlusCircle />
              </Button>
            </div>
            {formState?.edit_spotlight?.products.map((edit) => {
              return (
                <Col lg={3} key={edit?._id}>
                  <div className="h-100 text-center" style={{ position: "relative" }}>
                    <img
                      src={edit?.images[0]}
                      alt={edit?.product_name}
                      className="img-fluid h-100"
                      style={{ objectFit: "cover" }}
                    />
                    <small className="fw-bolder">{edit?.product_name}</small>
                    <Button
                      onClick={() => removeProduct("edit_spotlight", edit)}
                      variant="outline-danger"
                      style={{ position: "absolute", bottom: "38%", right: "40%" }}
                    >
                      <RiDeleteBinLine size={20} />
                    </Button>
                  </div>
                </Col>
              );
            })}
          </Row>
        </section>

        {/* Trending Brands */}
        <section className="pt-5">
          <h3 className="mt-5">Trending Brands</h3>
          <small className="fw-bolder text-danger">Select a maximum of 5 items from below</small>
          <Select
            isMulti
            onChange={(selectedOption) => {
              setFormState({
                ...formState,
                trending_brands: selectedOption.map((item) => {
                  return {
                    name: item.name,
                    _id: item._id ?? item.retailerID,
                  };
                }),
              });
            }}
            value={formState?.trending_brands}
            options={marketplaceStore?.retailers}
            getOptionLabel={(x) => x.name}
            getOptionValue={(x) => x._id}
            closeMenuOnSelect={false}
            isOptionDisabled={() => formState?.trending_brands.length === 5}
            components={{
              Option,
            }}
            styles={{
              control: (base, _state) => ({
                ...base,
                minHeight: "60px",
                border: "0.5px solid #000000",
                boxShadow: "none",
              }),
            }}
          />
        </section>

        {show && (
          <ProductSelectModal
            show={show}
            handleClose={() => setShow(false)}
            modalHeader={modalHeader}
            updateProduct={updateProduct}
            selectedIds={formState[modalType].products.map((product) => product._id)}
            gender={formState?.gender}
            collection={formState?.brand_spotlight?.collection}
            brand={formState?.brand_spotlight?.name}
            retailer={formState?.brand_spotlight?.name}
            modalType={modalType}
          />
        )}
        <div style={{ margin: "1rem 0" }}>
          <Button variant="success" type="submit">
            Save
          </Button>
        </div>
      </Form>
    </Container>
  );
}

export default NewSpotlight;
