import wheringFetch from "../../helpers/fetch";
import { DIONNE_BASE_URL } from ".";

const route = "marketplace";
export const taxonomyRoute = "https://tai.whering.co.uk/style/v1/taxonomy";
const baseUrl = DIONNE_BASE_URL;

const brandApi = {
  get: async (page, user_suggestion, search, limit = 30, fields = "") => {
    return wheringFetch(
      baseUrl,
      `${route}/brands?page=${page}&limit=${limit}&user_suggestions=${user_suggestion}&search=${search}&fields=${fields}`,
      "GET"
    );
  },
  create: async (data) => {
    return wheringFetch(baseUrl, `${route}/brands`, "POST", data);
  },
  update: async (brandID, data) => {
    return wheringFetch(baseUrl, `${route}/brands/${brandID}`, "PATCH", data);
  },
  delete: async (brandID) => {
    return wheringFetch(baseUrl, `${route}/brands/${brandID}`, "DELETE");
  },
};

export default brandApi;
