import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormSelect, Modal, Row } from "react-bootstrap";
import { BsCheckCircle, BsCheckCircleFill } from "react-icons/bs";

const BulkUploadModal = ({ show, handleClose, job }) => {
  if (!job) {
    return <div />;
  }

  const successIds = job.success.map((s) => s.row_reference);
  const failedIds = job.failed.map((s) => s.row_reference);
  console.log(job);
  console.log(successIds, failedIds);
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" size="xl" centered>
      <Modal.Header closeButton>
        <Modal.Title>Product Upload Progress</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col style={{ fontWeight: 600 }}>Sheet Reference</Col>
          <Col style={{ fontWeight: 600 }}>Name</Col>
          <Col style={{ fontWeight: 600 }}>Status</Col>
        </Row>
        {job.data.map((productToUpload) => {
          const productStatus = successIds.includes(productToUpload.row_reference)
            ? "success"
            : failedIds.includes(productToUpload.row_reference)
            ? "failed"
            : "pending";
          return (
            <Row key={productToUpload.row_reference}>
              <Col>{productToUpload.row_reference}</Col>
              <Col>{productToUpload.product_name}</Col>
              <Col>{productStatus}</Col>
            </Row>
          );
        })}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default observer(BulkUploadModal);
