import { action, flow, makeObservable, observable } from "mobx";
import BasicsModel from "../models/BasicsModel";
import ApiBasedStore from "./ApiBasedStore";

export default class BasicsStore extends ApiBasedStore {
  basicsApi;
  page;

  constructor(rootStore, basicsApi) {
    super(rootStore);
    this.basicsApi = basicsApi;
    this.basics = [];
    this.page = 0;
    this.end = false;

    makeObservable(this, {
      basics: observable,
      page: observable,
      end: observable,
      prevPage: action,
      nextPage: action,
    });
  }

  get = flow(function* () {
    this.startRequest();

    try {
      const { preset_pieces, end, count } = yield this.basicsApi.get(this.page);
      this.addBasics(preset_pieces);
      this.end = end;
      return { end, count };
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  create = flow(function* (params) {
    this.startRequest();

    try {
      const data = yield this.basicsApi.create(params);
      this.addBasics([data]);
      return data.id;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  update = flow(function* (id, params) {
    this.startRequest();

    try {
      const data = yield this.basicsApi.update(id, params);
      this.addBasics([data]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  delete = flow(function* (id) {
    this.startRequest();
    try {
      yield this.basicsApi.delete(id);
      const index = this.basics.findIndex((basic) => basic.id === id);
      this.basics.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * Creates a new basic in state or overwrites an existing entry with new data
   * @param {Array} newBasics
   */
  addBasics(newBasics) {
    newBasics.forEach((newBasic) => {
      const existing = this.basics.find((existingBasic) => {
        return existingBasic.id === newBasic.id; // server uses id key for all objects
      });

      if (!existing) {
        const newBasicsModel = new BasicsModel(newBasic);

        if (newBasics.length > 1) {
          this.basics.push(newBasicsModel);
        } else {
          this.basics.unshift(newBasicsModel);
        }
      } else {
        existing.update(newBasic);
      }
    });
  }

  prevPage = async () => {
    if (this.page > 0) {
      this.page -= 1;
    }
  };

  nextPage = async () => {
    if (!this.end) {
      this.page += 1;
      if (this.basics.length <= 30 * this.page) {
        await this.get();
      }
    }
  };
}
