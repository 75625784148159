import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";
import { RiDeleteBinLine } from "react-icons/ri";
import axios from "axios";
import { runInAction } from "mobx";

function NewProduct({ match, history }) {
  /**
   * @type {import('../../typedefs').RootStore}
   */
  const { productStore, marketplaceStore } = useStores();
  const productID = match.params.productID;

  const [showDelete, setShowDelete] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const brandSelectRef = useRef(null);

  const [formState, setFormState] = useState({
    images: [],
    product_name: "",
    brand: "",
    prices: [],
    content_url: "",
    sale_type: "",
    tax: "",
    slug: null,
    colour: "",
    age: "adult",
    description: "",
    sizes: [],
    gender: "",
    in_stock: true,
    metadata: {},
  });

  const successMessage = productID === "new" ? "Product created!" : "Product updated!";

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      if (marketplaceStore.categories.length === 0) {
        await marketplaceStore.getCategories(`?limit=1000&fields=name,slug,taxonomy_level`);
      }

      if (productStore.retailers.length === 0) {
        await productStore.getRetailerOptions();
      }

      if (productStore.brands.length === 0) {
        await productStore.getBrandOptions();
      }

      if (productStore.colours.length === 0) {
        // Fetch the colours list from our metadata file
        const coloursResponse = await axios.get("https://tai.whering.co.uk/style/v1/colours");
        if (coloursResponse.status === 200) {
          const { data } = coloursResponse;
          const colourStringArray = [...data, { id: "grey" }, { id: "multicolor" }]
            .map((colour) => colour.id)
            .sort((a, b) => a.localeCompare(b));
          if (colourStringArray.length === 0) {
            toast("unable to fetch colours, please contact an administrator", { type: "error" });
          }
          runInAction(() => {
            productStore.colours = colourStringArray;
          });
        }
      }

      if (productStore.products.length === 0) {
        await productStore.getAllProducts();
      }

      if (productID !== "new") {
        let product = productStore.products.find((product) => product.productID === productID);
        if (!product) {
          product = await productStore.getOneProduct(productID);

          if (!product) {
            throw new Error("product not found");
          }
        }
        setFormState({ ...product });
      }
    }

    getData();
  }, []);

  const updateFormState = (event) => {
    setFormState((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    if (formState.images.length === 0) {
      toast("You must include an image URL", { type: "info" });
      return;
    }

    if (brandSelectRef.current.value === "") {
      toast("must select a new brand", { type: "error" });
    }

    if (productStore.colours.length === 0) {
      toast("Colours could not be fetched, please contact an administrator", { type: "error" });
      return;
    }

    if (productID === "new") {
      await productStore.createProduct(formState);
    } else {
      const product = productStore.products.find((product) => product.productID === productID);
      if (!product) {
        toast("you cannot update a product that does not exist", {
          type: "error",
        });
        return;
      }

      const updateData = Object.assign({ ...product }, { ...formState });
      await productStore.updateProduct(productID, updateData);
    }

    if (!productStore.hasFailed) {
      toast(successMessage, { type: "success" });
      history.goBack();
    }
  };

  const handleDelete = async () => {
    await productStore.deleteProduct(productID);

    if (!productStore.hasFailed) {
      toast("Product Deleted!", { type: "error" });
      setTimeout(() => {
        history.goBack();
      }, 500);
    }
  };

  const categoryList = marketplaceStore.categories.filter((item) => item.taxonomy_level === 2);

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <h1>{productID === "new" ? "Add" : "Amend"} a Product</h1>
      <Form onSubmit={handleSave} style={{ marginBottom: 25 }}>
        <div>
          {formState?.images.length !== 0 && (
            <Row className="mt-5">
              {formState?.images.map((image, i) => {
                return (
                  <Col key={i} lg={3} sm={6}>
                    <div className="new-product-image">
                      <img src={image} alt={formState.name} className="img-fluid h-75" />

                      <RiDeleteBinLine
                        style={{ position: "absolute", top: 0, margin: "10px x0px" }}
                        size={40}
                        color="white"
                        className="bg-danger py-2 cursor"
                        onClick={() => {
                          setFormState((prev) => {
                            // delete by index so if removing a duplicate url, it doesn't remove both images
                            delete prev.images[i];
                            return { ...prev, images: prev.images };
                          });
                        }}
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
          )}

          <div>
            <Form.Group className="mb-3">
              <Form.Label>New Image URL</Form.Label>
              <div className="d-flex">
                <Form.Control name="imageUrl" value={imageUrl} onChange={(e) => setImageUrl(e.target.value)} />
                <Button
                  onClick={() => {
                    if (imageUrl !== "") {
                      formState.images.push(imageUrl);
                      setImageUrl("");
                    }
                  }}
                  variant="outline-primary"
                >
                  Add image
                </Button>
              </div>
            </Form.Group>
          </div>
        </div>

        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control required name="product_name" value={formState.product_name} onChange={updateFormState} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Brand</Form.Label>
          <Form.Select ref={brandSelectRef} required name="brand" value={formState.brand} onChange={updateFormState}>
            <option value="">
              Please select a brand{" "}
              {formState.brand && `(currently ${formState.brand} if not in list then create in brands area)`}
            </option>
            {productStore.brands &&
              productStore.brands.map((brand) => (
                <option key={brand._id} value={brand.name}>
                  {brand.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Retailer</Form.Label>
          <Form.Select required name="retailer" value={formState.retailer} onChange={updateFormState}>
            {productStore.retailers.map((retail) => (
              <option key={retail.retailerId} value={retail.name}>
                {retail.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>

        <Form.Label>Prices</Form.Label>

        {formState?.prices.map((price, i) => {
          const fieldName = `prices[${i}]`;
          return (
            <Row className="align-items-center mb-3" key={i}>
              <Col>
                <Row className="align-items-center">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    required
                    name={`${fieldName}.currency`}
                    value={price.currency}
                    onChange={(e) => {
                      setFormState((prevState) => {
                        const newPrices = [...prevState.prices];
                        newPrices[i].currency = e.target.value;
                        return { ...prevState, prices: newPrices };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    required
                    name={`${fieldName}.amount`}
                    value={price.amount}
                    onChange={(e) => {
                      setFormState((prevState) => {
                        const newPrices = [...prevState.prices];
                        newPrices[i].amount = e.target.value;
                        return { ...prevState, prices: newPrices };
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col>
                <Row className="align-items-center">
                  <Form.Label>Sale Amount</Form.Label>
                  <Form.Control
                    name={`${fieldName}.sale_amount`}
                    value={price.sale_amount}
                    onChange={(e) => {
                      setFormState((prevState) => {
                        const newPrices = [...prevState.prices];
                        newPrices[i].sale_amount = e.target.value;
                        return { ...prevState, prices: newPrices };
                      });
                    }}
                  />
                </Row>
              </Col>

              <Col lg={1}>
                <Button
                  className="btn-danger"
                  onClick={() => {
                    const newPrices = formState.prices.filter((item) => item !== price);
                    setFormState({ ...formState, prices: newPrices });
                    console.log(formState);
                  }}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          );
        })}

        <Button
          className="btn-primary mb-3"
          onClick={() => {
            setFormState((prevState) => ({
              ...prevState,
              prices: [
                ...prevState.prices,
                {
                  currency: "",
                  amount: "",
                },
              ],
            }));
          }}
        >
          Add new price
        </Button>

        <Form.Group className="mb-3">
          <Form.Label>Content URL</Form.Label>
          <Form.Control required name="content_url" value={formState.content_url} onChange={updateFormState} />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Sale Type</Form.Label>
          <Form.Control required as="select" name="sale_type" value={formState.sale_type} onChange={updateFormState}>
            <option value=""></option>
            <option value="Rental">Rental</option>
            <option value="New">New</option>
            <option value="Pre-Loved">Pre-Loved</option>
            <option value="Pre-Loved Luxury">Pre-Loved Luxury</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Taxonomy</Form.Label>
          <Form.Control
            required={productID !== "new"}
            disabled={productID === "new"}
            as="select"
            name="tax"
            value={formState.tax}
            onChange={updateFormState}
          >
            <option value=""></option>
            {categoryList.map((item, i) => (
              <option value={item.slug} key={i}>
                {item.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>URL Slug</Form.Label>
          <Form.Control
            required={productID !== "new"}
            disabled={productID === "new"}
            name="slug"
            value={formState.slug}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Colour</Form.Label>

          <Form.Control
            as="select"
            required={productID !== "new"}
            disabled={productID === "new" || productStore.colours.length === 0}
            name="colour"
            value={formState.colour}
            onChange={updateFormState}
          >
            <option value=""></option>
            {productStore.colours.map((colour) => (
              <option value={colour} key={colour}>
                {colour}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Age</Form.Label>
          <Form.Control as="select" required name="age" value={formState.age} onChange={updateFormState}>
            <option value=""></option>
            <option value="adult">Adult</option>
            <option value="kids">Kids</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            required
            name="description"
            value={formState.description}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Gender</Form.Label>

          <Form.Control required as="select" name="gender" value={formState.gender} onChange={updateFormState}>
            <option value=""></option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="unisex">Unisex</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>In stock?</Form.Label>

          <Form.Control required as="select" name="in_stock" value={formState.in_stock} onChange={updateFormState}>
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </Form.Control>
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Sizes</Form.Label>
          <Row>
            {formState?.sizes.map((size, i) => {
              const fieldName = `sizes[${i}]`;
              return (
                <Col lg={2} sm={6} key={size}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      required
                      name={`${fieldName}`}
                      value={size}
                      onChange={(e) => {
                        setFormState((prevState) => {
                          const newSizes = [...prevState.sizes];
                          newSizes[i] = e.target.value;
                          return { ...prevState, sizes: newSizes };
                        });
                      }}
                    />
                    <InputGroup.Text>
                      <RiDeleteBinLine
                        size={30}
                        color="white"
                        className="bg-danger py-2 cursor"
                        onClick={() => {
                          const newSizes = formState.sizes.filter((item) => item !== size);
                          setFormState({ ...formState, sizes: newSizes });
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Col>
              );
            })}
          </Row>

          <Button
            className="btn-primary my-3 d-block"
            onClick={() => {
              setFormState((prevState) => ({
                ...prevState,
                sizes: [...prevState.sizes, ""],
              }));
            }}
          >
            Add new size
          </Button>
        </Form.Group>

        <h3 className="mt-5">Sharing Metadata</h3>

        <Form.Group className="mb-3">
          <Form.Label> Title</Form.Label>
          <Form.Control
            placeholder="Enter an metadata title"
            name="title"
            required
            value={formState?.metadata?.title}
            onChange={(event) =>
              setFormState((prev) => {
                return {
                  ...prev,
                  metadata: {
                    ...formState.metadata,
                    title: event.target.value,
                  },
                };
              })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label> Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter an metadata description"
            name="description"
            required
            value={formState?.metadata?.description}
            onChange={(event) =>
              setFormState((prev) => {
                return {
                  ...prev,
                  metadata: {
                    ...formState.metadata,
                    description: event.target.value,
                  },
                };
              })
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Image URL</Form.Label>
          <Form.Control
            placeholder="Enter an image URL"
            name="image_url"
            required
            value={formState?.metadata?.image_url}
            onChange={(event) =>
              setFormState((prev) => {
                return {
                  ...prev,
                  metadata: {
                    ...formState.metadata,
                    image_url: event.target.value,
                  },
                };
              })
            }
          />
        </Form.Group>

        <Alert variant="danger" show={showDelete}>
          <Alert.Heading>This action is irreversible.</Alert.Heading>
          <p>The data you are about to delete will not be recoverable, are you sure you want to continue?</p>
          <Button variant="outline-danger" onClick={handleDelete}>
            Yes, delete
          </Button>
          <Button onClick={() => setShowDelete(false)}>No, cancel</Button>
        </Alert>
        <div style={{ margin: "1rem 0" }}>
          <Button variant="success" type="submit">
            Save
          </Button>
          {productID !== "new" && (
            <Button variant="danger" onClick={() => setShowDelete(true)}>
              Delete
            </Button>
          )}
        </div>
      </Form>
    </Container>
  );
}

export default NewProduct;
