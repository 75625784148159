import axios from "axios";
import React, { useState } from "react";

export const WpickHarness = () => {
  const [outfits, setOutfits] = useState([]);
  const [email, setEmail] = useState("biancarangecroft@gmail.com");
  const [numOutfits, setNumOutfits] = useState(3);
  const [isLoading, setIsLoading] = useState(false);
  const [conditionId, setConditionId] = useState(800);
  const [temp, setTemp] = useState(3);

  const getShuffledOutfits = async () => {
    setIsLoading(true);
    const requestUrl = new URL(`${process.env.REACT_APP_TAI_API_URL}/style/v1/wpick`);
    requestUrl.searchParams.set("email", email);
    requestUrl.searchParams.set("numOutfits", numOutfits);
    requestUrl.searchParams.set("conditionId", conditionId);
    requestUrl.searchParams.set("temp", temp);
    requestUrl.searchParams.set("generateThumbnail", 1);
    requestUrl.searchParams.set("thumbType", "png");
    requestUrl.searchParams.set("thumbWidth", 375);
    requestUrl.searchParams.set("thumbHeight", 510);
    requestUrl.searchParams.set("canvasWidth", 375);
    requestUrl.searchParams.set("canvasHeight", 510);

    const response = await axios.get(requestUrl);
    setOutfits(response.data.outfits);
    setIsLoading(false);
  };

  return (
    <div>
      <h1>Styling - WPick Harness</h1>
      <div>
        <div>
          <input placeholder="Enter email address" value={email} onChange={(el) => setEmail(el.target.value)} />
          <input
            placeholder="Number of Outfits"
            value={numOutfits}
            onChange={(el) => setNumOutfits(parseInt(el.target.value))}
          />
          <select value={conditionId} onChange={(e) => setConditionId(e.target.value)}>
            <option value={200}>wet</option>
            <option value={800}>dry</option>
          </select>
          <select value={temp} onChange={(e) => setTemp(e.target.value)}>
            <option value={3}>freezing</option>
            <option value={7}>cold</option>
            <option value={18}>mild</option>
            <option value={28}>hot</option>
          </select>
        </div>

        <button onClick={getShuffledOutfits}>Submit</button>
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {!isLoading ? (
          outfits.map((o) => {
            const outfitClimates = o.climates?.join(",") ?? "N/A";
            const pieceClimates = o.pieces.map((p) => ({ tax: p.tax, climates: p.climates }));
            return (
              <div key={o.id} style={{ width: 300 }}>
                <img src={o.image_url} style={{ width: "100%" }} />
                <label style={{ fontWeight: 600 }}>Outfit Climates</label>
                <p style={{ wordBreak: "break-all", whiteSpace: "normal" }}>{outfitClimates}</p>

                <label style={{ fontWeight: 600 }}>Piece Taxonomies</label>
                {pieceClimates
                  .filter((p) => p)
                  .map((pc) => (
                    <p>
                      {pc.tax} {pc.climates && pc.climates.join(",")}
                    </p>
                  ))}
              </div>
            );
          })
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
};
