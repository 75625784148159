import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { CustomToggle } from "../../components/CustomToggle";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";

function Careers({ history }) {
  const { careerStore } = useStores();

  useEffect(() => {
    if (careerStore.jobs.length === 0) {
      careerStore.fetchJobs(true);
    }
  }, []);

  const goToAddNew = () => {
    history.push("/careers/new");
  };

  const goToJobEdit = (jobID) => {
    history.push(`/careers/${jobID}`);
  };

  const goToDetails = (jobID) => {
    history.push(`/careers/details/${jobID}`);
  };

  return (
    <TableWrapper title="Careers" onAddNewClick={goToAddNew}>
      <Table className="model-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Description</th>
            <th>Responsibilities</th>
            <th>Active</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {careerStore.jobs.map((job) => {
            return (
              <tr key={job._id}>
                <td className="flex-row" style={{ justifyContent: "center" }}>
                  <p>{job.title}</p>
                </td>
                <td>
                  <p>{job.description.slice(0, 50)}...</p>
                </td>
                <td>
                  <p>{job.responsibilities[0].slice(0, 50)} ....</p>
                </td>
                <td>
                  <p>{job.active ? "Active" : "Inactive"}</p>
                </td>

                <td>
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => goToDetails(job._id)}>View Details</Dropdown.Item>
                      <Dropdown.Item onClick={() => goToJobEdit(job._id)}>Edit</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default observer(Careers);
