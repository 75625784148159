import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";

function Edits({ history }) {
  const { marketplaceStore } = useStores();
  console.log(marketplaceStore.edits);
  useEffect(() => {
    if (marketplaceStore.edits.length === 0) {
      marketplaceStore.getEdits();
    }
  }, []);

  const goToEdit = (editID) => {
    history.push(`/marketplace/edits/${editID}`);
  };

  return (
    <TableWrapper
      showLoading={marketplaceStore.isFetching}
      onAddNewClick={() => goToEdit("new")}
      title="Marketplace - Edits"
    >
      <Table className="model-table">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Locations</th>
            <th>Description</th>
          </tr>
        </thead>

        <tbody>
          {marketplaceStore.edits.map((edit) => (
            <tr key={edit._id}>
              <td>
                <img src={edit.image_url} height={100} />
              </td>
              <td>{edit.name}</td>
              <td>{edit.locations.map((item) => item)}</td>
              <td>{edit.description}</td>
              <td>
                <Button onClick={() => goToEdit(edit._id)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default observer(Edits);
