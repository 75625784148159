import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function AppBuilds() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [builds, setBuilds] = useState([]);

  useEffect(() => {
    getBuilds();

    async function getBuilds() {
      try {
        const { data } = await axios.get("https://api.codemagic.io/builds", {
          headers: { "x-auth-token": process.env.REACT_APP_CODEMAGIC_API_KEY },
        });
        setBuilds(data.builds);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    }
  }, []);

  async function createNewBuild(branch = "dev") {
    const activeBuilds = builds.filter((build) => !["finished", "failed", "canceled"].includes(build.status));
    console.log(activeBuilds);
    if (activeBuilds.length > 0) {
      toast(
        `build ${activeBuilds
          .map((build) => build._id)
          .join(",")} already running, please try again after it has finished`
      );
      return;
    }
    try {
      setLoading(true);
      const { data } = await axios.post(
        "https://api.codemagic.io/builds",
        {
          appId: "646b24eab05cd789f73c931b",
          workflowId: "development",
          branch,
        },
        {
          headers: { "x-auth-token": process.env.REACT_APP_CODEMAGIC_API_KEY },
        }
      );
      setBuilds((prev) => [{ _id: data.buildId, createdAt: new Date().toISOString(), status: "pending" }, ...prev]);
    } catch (e) {
      toast(e.message);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  } else if (error) {
    return (
      <div>
        <h1>Error</h1>
        <p>{error}</p>
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", margin: "30px 10px" }}>
        <h1 style={{ margin: "30px 0" }}>Builds</h1>
        <div>
          <button onClick={() => createNewBuild()} style={{ marginLeft: 20, marginRight: 20 }}>
            Start New Dev Build
          </button>
          <button onClick={() => createNewBuild("feat-ux-revamp")}>Start New UX Revamp Build</button>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          {builds.map((build) => (
            <div key={build._id} style={{ border: "black 1px solid", width: 400, padding: 10, margin: 10 }}>
              <span style={{ fontWeight: 800 }}>Build ID</span>
              <p>{build._id}</p>
              <span style={{ fontWeight: 800 }}>Date</span>
              <p>{build.createdAt}</p>
              <span style={{ fontWeight: 800 }}>Status</span>
              <p>{build.status}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default AppBuilds;
