import { observer } from "mobx-react";
import { runInAction } from "mobx";
import React, { useEffect, useState } from "react";
import { Table, Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { CustomToggle } from "../../components/CustomToggle";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";
import { Button } from "react-bootstrap";

function UcdbReported({ history }) {
  const { ucdbReportedStore } = useStores();

  useEffect(() => {
    if (ucdbReportedStore.reporteds.length === 0) {
      ucdbReportedStore.page = 0;
      ucdbReportedStore.fetchReporteds(true);
    }
  }, []);

  /**
   * The report was incorrect and the ucdb item should be kept, updates the document with actioned key and result
   * @param {string} reportedID
   */
  const keepItem = async (reportedID) => {
    await ucdbReportedStore.update(reportedID, { valid_report: false, actioned: true });
    if (!ucdbReportedStore.hasFailed) {
      toast("Item will be kept in the UCDB");
    }
  };

  const removeItem = async (reportedID) => {
    await ucdbReportedStore.update(reportedID, { valid_report: true, actioned: true });
    if (!ucdbReportedStore.hasFailed) {
      toast("Item has been deleted from the UCDB");
    }
  };
  // mark item deleted in firestore db
  // curl --location --request DELETE 'https://tai-test.whering.co.uk/ucdb/v1/piece/:id?id_type=mongo'

  // colon id replaced as mongo id

  return (
    <TableWrapper showLoading={ucdbReportedStore.isFetching} title="User Reported Whering Database Items">
      <p>
        These items have been reported by users for various reasons, please check the report reason and the image to see
        if the report is correct.
      </p>
      <p>
        If you agree with the report and want to remove the item from the Whering Database then click "Delete",
        otherwise click "Keep"
      </p>
      <Table className="model-table">
        <thead>
          <tr>
            <th>Image</th>
            <th>Reason</th>
            <th>Date Reported</th>
          </tr>
        </thead>
        <tbody>
          {ucdbReportedStore.reporteds
            .slice(ucdbReportedStore.page * 20, ucdbReportedStore.page * 20 + 20)
            .filter((reported) => !reported.actioned)
            .map((reported) => {
              const reportedID = reported._id;
              const mongoId = reported.mongo_id;
              return (
                <tr key={reportedID}>
                  <td className="flex-row" style={{ justifyContent: "center" }}>
                    <img src={reported.image_url} alt={reported.name} height={300} />
                  </td>
                  <td>
                    <p>{reported.reason}</p>
                  </td>
                  <td>
                    <p>{new Date(reported.report_date).toDateString()}</p>
                  </td>

                  <td>
                    <Button onClick={() => keepItem(mongoId)} variant="success">
                      Keep Item
                    </Button>
                    <Button onClick={() => removeItem(mongoId)} variant="danger">
                      Delete Item
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
      <div className="flex-row" style={{ marginBottom: 50 }}>
        <Button
          onClick={() => runInAction(() => (ucdbReportedStore.page = 0))}
          disabled={ucdbReportedStore.page === 0 || ucdbReportedStore.isFetching || ucdbReportedStore.end}
        >
          Back to Start
        </Button>
        <Button
          onClick={ucdbReportedStore.prevPage}
          disabled={ucdbReportedStore.page === 0 || ucdbReportedStore.isFetching || ucdbReportedStore.end}
        >
          Previous
        </Button>
        <Button onClick={ucdbReportedStore.nextPage} disabled={ucdbReportedStore.isFetching || ucdbReportedStore.end}>
          Next
        </Button>
      </div>
    </TableWrapper>
  );
}

export default observer(UcdbReported);
