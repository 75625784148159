import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap";
import Select from "react-select/creatable";
import { toast } from "react-toastify";
import { components } from "react-select";
import { useStores } from "../../context/mobx-context";
import { countriesList } from "../../helpers/countriesList";

import MarketplaceStore from "../../data/stores/MarketplaceStore";
import { useHistory } from "react-router-dom";

function NewRetailer({ match }) {
  /**
   * @type {{marketplaceStore: MarketplaceStore}}
   */
  const { marketplaceStore } = useStores();
  const retailerID = match.params.retailerID;

  const inputRef = useRef(null);
  const logoImageRef = useRef(null);
  const metaRef = useRef(null);
  const history = useHistory();

  const [imageFile, setImageFile] = useState(false);
  const [logoImageFile, setLogoImageFile] = useState(false);
  const [metadataImageFile, setMetadataImageFile] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const [formState, setFormState] = useState({
    name: "",
    slug: "",
    image_url: "",
    logo_image_url: "",
    description: "",
    locations: [],
    metadata: {
      image_url: "",
      description: "",
      title: "",
    },
  });

  useEffect(() => {
    if (formState.locations.length > 0) {
      const defaultCountries = countriesList.filter((country) => formState?.locations.includes(country?.country_code));
      setSelectedLocations(defaultCountries);
    }
  }, [formState]);

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      if (marketplaceStore.categories.length === 0) {
        await marketplaceStore.getRetailers();
      }

      if (retailerID !== "new") {
        const retailer = marketplaceStore.retailers.find((retailer) => retailer.retailerID === retailerID);

        setFormState({ ...retailer });
      }
    }

    getData();
  }, []);

  const updateFormState = (event) => {
    if (event.target.name.includes("metadata")) {
      const [metadata, fieldKey] = event.target.name.split(".");
      setFormState((prev) => {
        return {
          ...prev,
          [metadata]: { ...prev[metadata], [fieldKey]: event.target.value },
        };
      });
    } else {
      setFormState((prev) => {
        return {
          ...prev,
          [event.target.name]: event.target.value,
        };
      });
    }
  };

  const onChangeImage = (event, key) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    if (key === "image") {
      setImageFile(URL.createObjectURL(file));
    } else if (key === "logo_image") {
      setLogoImageFile(URL.createObjectURL(file));
    } else {
      setMetadataImageFile(URL.createObjectURL(file));
    }

    setFormState((prev) => {
      return {
        ...prev,
        [key]: file,
      };
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const saveData = { ...formState, locations: selectedLocations.map((location) => location.country_code) };

    if (!formState.image_url && !formState.image) {
      toast("You must include a main image", { type: "info" });
      return;
    }

    if (!formState.logo_image_url && !formState.logo_image) {
      toast("You must include a logo image", { type: "info" });
      return;
    }

    if (!formState.metadata.image_url && !formState.metadata_image) {
      toast("You must include a metadata image", { type: "info" });
      return;
    }

    if (saveData.locations.length === 0) {
      toast("you must select at least one location", {
        type: "error",
      });
      return;
    }

    const data = new FormData();

    if (saveData?.image) {
      data.append("image", saveData.image);
    }
    if (saveData?.logo_image) {
      data.append("logo_image", saveData.logo_image);
    }
    if (saveData?.metadata_image) {
      data.append("metadata_image", saveData.metadata_image);
    }

    data.append("data", JSON.stringify(saveData));

    if (retailerID === "new") {
      await marketplaceStore.createRetailer(data);

      if (!marketplaceStore.hasFailed) {
        toast("Retailer successfully created", { type: "success" });
        history.goBack();
      }
    } else {
      const retailer = marketplaceStore.retailers.find((retailer) => retailer.retailerID === retailerID);
      if (!retailer) {
        toast("you cannot update a retailer that does not exist", {
          type: "error",
        });
        return;
      }

      await marketplaceStore.updateRetailer(retailerID, data);

      if (!marketplaceStore.hasFailed) {
        toast("Retailer successfully updated", { type: "success" });
        history.goBack();
      }
    }
  };

  const handleDelete = async () => {
    await marketplaceStore.deleteRetailer(retailerID);
    if (!marketplaceStore.hasFailed) {
      toast("Retailer successfully deleted", { type: "success" });
      history.goBack();
    }
  };

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <input
        type="file"
        id="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => onChangeImage(e, "image")}
      />
      <input
        type="file"
        id="file2"
        ref={metaRef}
        style={{ display: "none" }}
        onChange={(e) => onChangeImage(e, "metadata_image")}
      />
      <input
        type="file"
        id="file3"
        ref={logoImageRef}
        style={{ display: "none" }}
        onChange={(e) => onChangeImage(e, "logo_image")}
      />
      <h1>{retailerID === "new" ? "Add" : "Amend "} Retailer</h1>
      <Form onSubmit={handleSave} style={{ marginBottom: 25 }}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder="Enter the retailer name"
            name="name"
            required
            value={formState.name}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter a description"
            name="description"
            required
            value={formState.description}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Slug</Form.Label>
          <Form.Control
            placeholder="Enter a slug"
            name="slug"
            required
            value={formState.slug}
            onChange={updateFormState}
          />
        </Form.Group>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Form.Group className="mb-3">
            <Form.Label>Main Image</Form.Label>
            <div style={{ position: "relative", width: "fit-content" }}>
              {imageFile ? (
                <img src={imageFile} alt="retailer-main-image" height={300} />
              ) : (
                <img src={formState?.image_url} alt="retailer-main-image" height={300} />
              )}

              <Button onClick={() => inputRef.current.click()}>Change Main Image</Button>
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Logo Image</Form.Label>
            <div style={{ position: "relative", width: "fit-content" }}>
              {logoImageFile ? (
                <img src={logoImageFile} alt="retailer-logo-image" height={300} />
              ) : (
                <img src={formState?.logo_image_url} alt="retailer-logo-image" height={300} />
              )}

              <Button onClick={() => logoImageRef.current.click()}>Change Logo Image</Button>
            </div>
          </Form.Group>
        </div>
        <section>
          <h3 className="mt-5 mb-0">Locations</h3>
          <Row className="mt-4">
            <Col lg={8}>
              <Select
                isMulti
                onChange={(selectedOption) => {
                  setSelectedLocations(selectedOption);
                }}
                value={selectedLocations}
                options={countriesList}
                getOptionLabel={(x) => x.name}
                getOptionValue={(x) => x.country_code}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{
                  Option,
                }}
                styles={{
                  control: (base, _state) => ({
                    ...base,
                    minHeight: "60px",
                    border: "0.5px solid #000000",
                    boxShadow: "none",
                  }),
                }}
              />
            </Col>
          </Row>
        </section>

        <h4 className="mt-5">Metadata</h4>

        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder="Enter a title"
            name="metadata.title"
            required
            value={formState?.metadata?.title}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter a description"
            name="metadata.description"
            required
            value={formState?.metadata?.description}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Image URL</Form.Label>
          <div style={{ position: "relative", width: "fit-content" }}>
            {metadataImageFile ? (
              <img src={metadataImageFile} alt="retailer-metadata-image" height={300} />
            ) : (
              <img src={formState?.metadata?.image_url} alt="retailer-metadata-image" height={200} />
            )}

            <Button onClick={() => metaRef.current.click()}>Change Metadata Image</Button>
          </div>
        </Form.Group>
        <Alert variant="danger" show={showDelete}>
          <Alert.Heading>This action is irreversible.</Alert.Heading>
          <p>The data you are about to delete will not be recoverable, are you sure you want to continue?</p>
          <Button variant="outline-danger" onClick={handleDelete}>
            Yes, delete
          </Button>
          <Button onClick={() => setShowDelete(false)}>No, cancel</Button>
        </Alert>
        <Button variant="success" type="submit">
          Save
        </Button>
        <Button variant="danger" onClick={() => setShowDelete(true)}>
          Delete
        </Button>
      </Form>
    </Container>
  );
}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="d-flex align-items-center">
          <input type="checkbox" checked={props.isSelected} onChange={() => null} style={{ marginRight: "10px" }} />
          <label className="p-0 m-0">{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

export default NewRetailer;
