import React, { useEffect, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, Image } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";
import { toBase64 } from "../../helpers/image";
import { AddButton, RemoveButton } from "../../components/ActionButtons";

function NewService({ match, history }) {
  const serviceID = match.params.serviceID;
  const inputRef = useRef(null);
  const { marketplaceStore } = useStores();

  const [formState, setFormState] = useState({
    name: "",
    description: "",
    imageUrl: "",
    brand: "",
    category: "",
    contentUrl: "",
    discount: "",
    location: "",
    tags: [""],
    title: "",
    brandDescription: "",
  });
  const [imageFile, setImageFile] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      if (marketplaceStore.services.length === 0) {
        await marketplaceStore.getServices();
      }
      if (serviceID !== "new") {
        const service = marketplaceStore.services.find((service) => service.serviceID === serviceID);
        setFormState({ ...service });
      }
    }

    getData();
  }, []);

  const updateFormState = (e, index) => {
    if (index === undefined) {
      setFormState((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      let newFormValues = { ...formState };
      newFormValues[e.target.name][index] = e.target.value;
      setFormState(newFormValues);
    }
  };

  let addFormFields = (name) => {
    setFormState({ ...formState, [name]: [...formState[name], ""] });
  };

  let removeFormFields = (i, name) => {
    let newFormValues = { ...formState };
    newFormValues[name].splice(i, 1);
    setFormState(newFormValues);
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (!formState.imageUrl) {
      toast("You must include an image", { type: "warning" });
      return;
    }

    let data = { ...formState };

    // If an image file is in state, we need to upload it with the request, convert to base64 and append to data object
    if (imageFile !== false) {
      data.newImage = await toBase64(imageFile);
    }

    if (serviceID === "new") {
      await marketplaceStore.createService(data);
    } else {
      const service = marketplaceStore.services.find((service) => service.serviceID === serviceID);
      if (!service) {
        toast("cannot update an service that does not exist", {
          type: "error",
        });
        return;
      }

      // concatenate the formstate and original data object so created and updated are maintained
      const updateData = Object.assign({ ...service, ...data });
      await marketplaceStore.updateService(serviceID, updateData);
    }

    if (!marketplaceStore.hasFailed) {
      toast("Service Saved!", { type: "success" });
      setTimeout(() => {
        history.goBack();
      }, 1000);
    }
  };

  const handleDelete = async () => {
    await marketplaceStore.deleteService(serviceID);

    if (!marketplaceStore.hasFailed) {
      toast("Service Deleted!", { type: "error" });
      setTimeout(() => {
        history.goBack();
      }, 500);
    }
  };

  const onChangeImage = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    setImageFile(file);
    setFormState((prev) => {
      return {
        ...prev,
        imageUrl: URL.createObjectURL(file),
      };
    });
  };
  console.log(formState);
  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <input type="file" id="file" ref={inputRef} style={{ display: "none" }} onChange={onChangeImage} />
      <h1>{serviceID === "new" ? "Add " : "Amend "} a Service</h1>
      <Form className="model-form" onSubmit={handleSave}>
        <Col className="mb-3">
          <h1>Image</h1>
          {formState.imageUrl ? (
            <div style={{ position: "relative", width: "fit-content" }}>
              <Image src={formState.imageUrl} height={400} />
              <Button style={{ position: "absolute", right: 0, margin: 10 }} onClick={() => inputRef.current.click()}>
                Change
              </Button>
            </div>
          ) : (
            <Button onClick={() => inputRef.current.click()}>Add Image</Button>
          )}
        </Col>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder="Enter a Name"
            name="name"
            required
            value={formState.name}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter a Description"
            name="description"
            required
            value={formState.description}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Brand</Form.Label>
          <Form.Control
            placeholder="Enter a Brand"
            name="brand"
            required
            value={formState.brand}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder="Enter a title(for the service detail page)"
            name="title"
            required
            value={formState.title}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Brand Description</Form.Label>
          <Form.Control
            placeholder="Enter a Brand Description"
            name="brandDescription"
            required
            value={formState.brandDescription}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Category</Form.Label>
          <Form.Control
            placeholder="Enter a Category"
            name="category"
            required
            value={formState.category}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Content URL</Form.Label>
          <Form.Control
            placeholder="Enter a Content URL"
            name="contentUrl"
            required
            value={formState.contentUrl}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Discount</Form.Label>
          <Form.Control
            placeholder="Enter a Discount"
            name="discount"
            required
            value={formState.discount}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Location</Form.Label>
          <Form.Control
            placeholder="Enter a Location"
            name="location"
            required
            value={formState.location}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Tags</Form.Label>
          {formState?.tags.map((attribute, index) => (
            <div className="row mb-2 align-items-center" key={index}>
              <div className="col-sm-6">
                <Form.Control
                  placeholder="Enter a tag"
                  name="tags"
                  value={attribute || ""}
                  onChange={(e) => updateFormState(e, index)}
                />
              </div>

              <div className="col-lg-2">
                {index ? <RemoveButton clickAction={() => removeFormFields(index, "tags")} /> : null}
              </div>
            </div>
          ))}
          <div className="col-4">
            <AddButton clickAction={() => addFormFields("tags")} label="Add more tags" />
          </div>
        </Form.Group>
        <Alert variant="danger" show={showDelete}>
          <Alert.Heading>This action is irreversible.</Alert.Heading>
          <p>The data you are about to delete will not be recoverable, are you sure you want to continue?</p>
          <Button variant="outline-danger" onClick={handleDelete}>
            Yes, delete
          </Button>
          <Button onClick={() => setShowDelete(false)}>No, cancel</Button>
        </Alert>
        <Button variant="success" type="submit">
          Save
        </Button>
        {serviceID !== "new" && (
          <Button variant="danger" onClick={() => setShowDelete(true)}>
            Delete
          </Button>
        )}
      </Form>
    </Container>
  );
}

export default NewService;
