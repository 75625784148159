import { action, flow, makeObservable, observable } from "mobx";
import AdminUserModel from "../models/AdminUserModel";
import ApiBasedStore from "./ApiBasedStore";

export default class AdminStore extends ApiBasedStore {
  adminApi;

  constructor(rootStore, adminApi) {
    super(rootStore);
    this.adminApi = adminApi;
    this.users = [];
    makeObservable(this, {
      users: observable,
    });
  }

  getAllUsers = flow(function* () {
    this.startRequest();

    try {
      const users = yield this.adminApi.getAll();
      this.addUsers(users);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  getUser = flow(function* (userID) {
    this.startRequest();

    try {
      const user = yield this.adminApi.getOne(userID);
      this.addUsers([user]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createUser = flow(function* (data) {
    this.startRequest();

    try {
      const response = yield this.adminApi.create(data);
      this.addUsers([response.user]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  updateUser = flow(function* (data, userID) {
    this.startRequest();

    try {
      const response = yield this.adminApi.update(data, userID);
      this.addUsers([response.user]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  deleteUser = flow(function* (userID) {
    this.startRequest();

    try {
      yield this.adminApi.delete(userID);
      this.users = this.users.filter((u) => u.userID !== userID);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * Creates a new user in state or overwrites an existing entry with new data
   * @param {Array} users
   */
  addUsers(newUsers) {
    newUsers.forEach((newUser) => {
      const existing = this.users.find((existingUser) => {
        return existingUser.userID === newUser.id; // server uses id key for all objects
      });

      if (!existing) {
        const newUserModel = new AdminUserModel(newUser);
        this.users.push(newUserModel);
      } else {
        existing.update(newUser);
      }
    });
  }
}
