import { useEffect, useState } from "react";
import { firestore } from "../../../config/firebase";
import { collection, limit, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { Table } from "react-bootstrap";
import WpickReplayModal from "../../../components/WpickReplayModal";

export function WpickLoggingHarness() {
  const [logs, setLogs] = useState([]);
  const [replayRequest, setReplayRequest] = useState(false);
  const [pageLimit, setPageLimit] = useState(20);
  useEffect(() => {
    const first = query(
      collection(firestore, "wpick_results"),
      orderBy("created_at", "desc"),
      where("message", "==", "WPICK_METRICS_OUTPUT"),
      limit(pageLimit)
    );
    const unsubscribe = onSnapshot(first, (doc) => {
      let newLogs = [];
      doc.docs.forEach((snap) => {
        newLogs.push({ id: snap.id, ...snap.data() });
      });
      setLogs(newLogs);
    });
    return () => unsubscribe();
  }, []);

  return (
    <>
      <div style={{ margin: 20 }}>
        <h1>Wpick logs</h1>
        <Table>
          <thead>
            <tr>
              <th>Log ID</th>
              <th>User ID</th>
              <th>User Email</th>
              <th>Num Outfits</th>
              <th>Outfit Success</th>
              <th>Piece Success</th>
              <th>Outfits</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {logs.map((log) => {
              return (
                <tr key={log.id}>
                  <td>
                    <a href={`/harnesses/styling/wpick/logs/${log.id}`}>{log.id}</a>
                  </td>
                  <td>
                    <a href={`/harnesses/styling/wpick/logs/user/${log.user_id}`}>{log.user_id}</a>
                  </td>
                  <td>{log.user_email}</td>
                  <td>{log.numOutfits}</td>
                  <td>{log.outfit_success.toString()}</td>
                  <td>{log.piece_success.toString()}</td>
                  <td>
                    {log.outfits.map((outfit) => (
                      <img
                        key={outfit.outfit_hash}
                        src={outfit.image_url ?? outfit.image_raw}
                        height={150}
                        width={100}
                      />
                    ))}
                  </td>
                  <td>
                    <button onClick={() => setReplayRequest(log.requestUrl)}>Replay</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <WpickReplayModal replayRequest={replayRequest} setReplayRequest={setReplayRequest} />
    </>
  );
}
