import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Button, Table } from "react-bootstrap";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";
import BasicsStore from "../../data/stores/BasicsStore";

function Basics({ history }) {
  /**
   * @type {{basicsStore: BasicsStore}}
   */
  const { basicsStore } = useStores();

  const getBasics = async () => {
    basicsStore.get();
  };

  useEffect(() => {
    if (basicsStore.basics.length === 0) {
      basicsStore.page = 0;
      getBasics();
    }
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [basicsStore.page]);

  const goToBasic = (basicID) => {
    history.push(`/basics/${basicID}`);
  };

  return (
    <TableWrapper showLoading={basicsStore.isFetching} title="Basics" onAddNewClick={() => history.push(`/basics/new`)}>
      <Table className="model-table">
        <colgroup>
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>Gender</th>
            <th>Tax</th>
            <th>Item Link</th>
          </tr>
        </thead>
        <tbody>
          {basicsStore.basics.slice(basicsStore.page * 30, basicsStore.page * 30 + 30).map((basic) => (
            <tr key={basic.id}>
              <td>
                <img src={basic.image_url} height={200} alt={basic.name} />
              </td>
              <td>{basic.gender}</td>
              <td>{basic.tax}</td>
              <td>
                <a href={basic?.item_url} target="_blank" rel="noreferrer">
                  {basic.item_url?.length > 100 ? `${basic?.item_url.slice(0, 100)}...` : basic.item_url}
                </a>
              </td>

              <td width={1}>
                <Button onClick={() => goToBasic(basic.id)}>Edit</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="flex-row" style={{ marginBottom: 50 }}>
        <Button
          onClick={() => runInAction(() => (basicsStore.page = 0))}
          disabled={basicsStore.page === 0 || basicsStore.isFetching || basicsStore.end}
        >
          Back to Start
        </Button>
        <Button
          onClick={basicsStore.prevPage}
          disabled={basicsStore.page === 0 || basicsStore.isFetching || basicsStore.end}
        >
          Previous
        </Button>
        <Button onClick={basicsStore.nextPage} disabled={basicsStore.isFetching || basicsStore.end}>
          Next
        </Button>
      </div>
    </TableWrapper>
  );
}

export default observer(Basics);
