import { makeAutoObservable } from "mobx";

export default class CareerModel {
  _id;
  title;
  start_date;
  salary;
  responsibilities;
  reports_to;
  preferred_attributes;
  location;
  ideal_attributes;
  description;
  active;
  tags;
  category;
  created_at;
  updated_at;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this._id = data._id;
    this.title = data.title;
    this.start_date = data.start_date;
    this.salary = data.salary;
    this.responsibilities = data.responsibilities;
    this.reports_to = data.reports_to;
    this.preferred_attributes = data.preferred_attributes;
    this.location = data.location;
    this.ideal_attributes = data.ideal_attributes;
    this.description = data.description;
    this.active = data.active;
    this.tags = data.tags;
    this.category = data.category;
    this.created_at = data.created_at;
    this.updated_at = data.updated_at;
  }
}
