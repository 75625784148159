import { runInAction } from "mobx";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";
import BrandStore from "../../data/stores/BrandStore";

function MarketplaceBrands({ history }) {
  /**
   * @type {{brandStore: BrandStore}}
   */
  const { brandStore } = useStores();
  const [searchText, setSearchText] = useState("");

  const getBrands = async () => {
    brandStore.fetchBrands();
  };

  useEffect(() => {
    if (brandStore.brands.length === 0) {
      brandStore.searchQuery = "";
      brandStore.page = 0;
      getBrands();
    }
  }, [brandStore.showingSuggestions]);

  const goToBrand = (brandID) => {
    history.push(`/marketplace/brand/${brandID}`);
  };

  const searchBrands = () => {
    runInAction(() => {
      brandStore.page = 0;
      brandStore.setSearchQuery(searchText);
      brandStore.searchBrands();
    });
  };

  const switchBrandView = () => {
    runInAction(() => {
      brandStore.brands = [];
      brandStore.searchQuery = "";
      setSearchText("");
      brandStore.setUserSuggestions(!brandStore.showingSuggestions);
    });
  };

  const handleReject = async (brandID) => {
    await brandStore.delete(brandID);
    if (!brandStore.hasFailed) {
      toast("Brand successfully rejected", { type: "success" });
    }
  };

  return (
    <TableWrapper
      showLoading={brandStore.isFetching}
      title={`Marketplace - ${brandStore.showingSuggestions ? "User suggested brands" : "All brands"}`}
      onAddNewClick={() => history.push(`/marketplace/brand/new`)}
      onSearchChange={(e) => setSearchText(e.target.value)}
      onSearchClick={searchBrands}
      searchValue={searchText}
    >
      <Col sm="auto" className="mb-3">
        <Form.Check
          type="switch"
          id="custom-switch"
          onClick={switchBrandView}
          label={brandStore.showingSuggestions ? "View all brands" : "View user suggested brands"}
        />
      </Col>

      <Table className="model-table">
        <colgroup>
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
          <col span="1" style={{ width: "100px" }} />
        </colgroup>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Description</th>
          </tr>
        </thead>

        <tbody>
          {brandStore.brands
            .slice(brandStore.page * 30, brandStore.page * 30 + 30)
            .filter((brand) => brand.user_suggestion === brandStore.showingSuggestions)
            .map((brand) => (
              <tr key={brand.id}>
                <td>
                  <img src={brand.logo_image_url} height={100} alt={brand.name} />
                </td>
                <td>{brand.name}</td>
                <td>{brand.description}</td>

                <td width={1}>
                  <Button onClick={() => goToBrand(brand.id)}>{brand?.user_suggestion ? "Accept" : "Edit"}</Button>
                </td>

                {brandStore.showingSuggestions && (
                  <td width={1}>
                    <Button className="btn-danger" onClick={() => handleReject(brand.id)}>
                      Reject
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </Table>

      <div className="flex-row" style={{ marginBottom: 50 }}>
        <Button
          onClick={() => runInAction(() => (brandStore.page = 0))}
          disabled={brandStore.page === 0 || brandStore.isFetching || brandStore.end}
        >
          Back to Start
        </Button>
        <Button
          onClick={brandStore.prevPage}
          disabled={brandStore.page === 0 || brandStore.isFetching || brandStore.end}
        >
          Previous
        </Button>
        <Button onClick={brandStore.nextPage} disabled={brandStore.isFetching || brandStore.end}>
          Next
        </Button>
      </div>
    </TableWrapper>
  );
}

export default observer(MarketplaceBrands);
