import { action, flow, makeObservable, observable } from "mobx";
import AdminUserModel from "../models/AdminUserModel";
import ArticleModel from "../models/ArticleModel";
import ApiBasedStore from "./ApiBasedStore";

export default class BlogStore extends ApiBasedStore {
  blogApi;

  constructor(rootStore, blogApi) {
    super(rootStore);
    this.blogApi = blogApi;
    this.articles = [];
    this.images = [];

    makeObservable(this, {
      articles: observable,
      images: observable,
    });
  }

  getAllArticles = flow(function* () {
    this.startRequest();

    try {
      const { articles } = yield this.blogApi.getAll();
      // never show builder articles in this list
      this.addArticles(articles.filter((article) => article.from_builder !== true));
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  createArticle = flow(function* (data) {
    this.startRequest();

    try {
      const article = yield this.blogApi.create(data);
      this.addArticles([article]);
      return article.id;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  updateArticle = flow(function* (articleID, data) {
    this.startRequest();

    try {
      const article = yield this.blogApi.update(articleID, data);
      this.addArticles([article]);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  deleteArticle = flow(function* (articleID) {
    this.startRequest();
    try {
      yield this.blogApi.delete(articleID);
      const index = this.articles.findIndex((article) => article.articleID === articleID);
      this.articles.splice(index, 1);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  getAllImages = flow(function* () {
    try {
      const images = yield this.blogApi.images();
      this.images = images;
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  uploadImage = flow(function* (data) {
    try {
      const image = yield this.blogApi.imageUpload(data);
      setTimeout(() => {
        this.images.unshift(image);
      }, 1000);
    } catch (e) {
      this.onError(e);
    } finally {
      this.endRequest();
    }
  });

  /**
   * Creates a new user in state or overwrites an existing entry with new data
   * @param {Array} newArticles
   */
  addArticles(newArticles) {
    newArticles.forEach((newArticle) => {
      const existing = this.articles.find((existingArticle) => {
        return existingArticle.articleID === newArticle.id; // server uses id key for all objects
      });

      if (!existing) {
        const newArticleModel = new ArticleModel(newArticle);

        if (newArticles.length > 1) {
          this.articles.push(newArticleModel);
        } else {
          this.articles.unshift(newArticleModel);
        }
      } else {
        existing.update(newArticle);
      }
    });
  }
}
