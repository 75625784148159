import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useStores } from "../context/mobx-context";

function Login() {
  const [validated, setValidated] = useState(false);
  const { authStore } = useStores();
  const history = useHistory();

  useEffect(() => {
    async function checkLoggedIn() {
      await authStore.checkForPersistedUser();
      if (authStore.isAuthenticated) {
        history.push("/dashboard");
      }
    }
    checkLoggedIn();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);
    const formData = new FormData(event.target);
    const formDataObj = Object.fromEntries(formData.entries());

    await authStore.login(formDataObj.email, formDataObj.password);

    history.push("/dashboard");
  };

  return (
    <Container
      style={{ marginTop: "10%", maxWidth: 400, justifyContent: "center" }}
    >
      <h1 style={{ textAlign: "center" }}>Whering Admin</h1>
      <Form onSubmit={handleSubmit} validated={validated}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            required
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            name="password"
            required
            minLength={8}
          />
        </Form.Group>
        <Button variant="success" type="submit">
          Login
        </Button>
      </Form>
    </Container>
  );
}

export default Login;
