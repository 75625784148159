import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { firestore } from "../../../config/firebase";
import WpickReplayModal from "../../../components/WpickReplayModal";

export default function WpickLogEntry({ match }) {
  const logId = match.params.logId;
  const [logEntry, setLogEntry] = useState(null);
  const [replayRequest, setReplayRequest] = useState(false);

  useEffect(() => {
    async function getLogEntry() {
      const document = await getDoc(doc(firestore, `wpick_results/${logId}`));
      if (document.exists()) {
        setLogEntry({ id: document.id, ...document.data() });
      }
    }
    getLogEntry();
  }, []);

  if (!logEntry) {
    return <p>Loading...</p>;
  } else {
    return (
      <>
        <div style={{ margin: 10 }}>
          <h1>{logId}</h1>
          <div>
            <h2>Logged Info</h2>
            <p>Outfits Requested: {logEntry.numOutfits}</p>
            <p>
              Outfits Returned: {logEntry.outfits.length} {`(${logEntry.outfit_success}%)`}
            </p>
            <p>Percentage of slots filled: {logEntry.piece_success}%</p>
            <p>
              Unique Outfit Classes {logEntry.number_unique_outfit_classes}{" "}
              {`(${Math.ceil((logEntry.number_unique_outfit_classes / logEntry.outfits.length) * 100)}%)`}
            </p>
            <button onClick={() => setReplayRequest(logEntry.requestUrl)}>Replay</button>
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: 40 }}>
            {logEntry.outfits.map((outfit) => {
              return (
                <div style={{ display: "flex", gap: 20 }} key={outfit.outfit_hash}>
                  <div>
                    <img src={outfit.image_url} height={300} width={200} />
                  </div>
                  <div>
                    <p>
                      {outfit.outfitClass.title} ({outfit.outfitClass.id})
                    </p>
                    {outfit.outfitClass.slots.map((slot, index) => (
                      <p key={`slot.${slot.taxonomy.join(",")}.${index}`}>{slot.taxonomy.join(",")}</p>
                    ))}
                  </div>
                  {outfit.pieces.map((piece) => {
                    return (
                      <div
                        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                        key={piece.piece_id}
                      >
                        <img
                          src={piece.cropped_image_url ?? piece.nobg_image_url ?? piece.image_url}
                          height={150}
                          width={120}
                        />
                        <span>{piece.tax}</span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <WpickReplayModal replayRequest={replayRequest} setReplayRequest={setReplayRequest} />
      </>
    );
  }
}
