import React, { useState, useEffect } from "react";

import { Button, Container } from "react-bootstrap";
import { useStores } from "../../context/mobx-context";

function JobDetail({ match, history }) {
  const jobID = match.params.jobID;
  const { careerStore } = useStores();

  const [jobDetails, setJobDetails] = useState({});

  useEffect(() => {
    async function getData() {
      if (careerStore.jobs.length === 0) {
        await careerStore.fetchJobs();
      }
      if (jobID !== "new") {
        const job = careerStore.jobs.find((job) => job._id === jobID);
        setJobDetails(job);
      }
    }

    getData();
  }, [jobID]);

  const goToJobEdit = () => {
    history.push(`/careers/${jobID}`);
  };

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      {Object.keys(jobDetails).length !== 0 && (
        <div className="py-5">
          <div className="d-flex justify-content-end mb-3">
            <Button variant="success" onClick={goToJobEdit}>
              📝 Edit
            </Button>
          </div>

          <div className="container">
            <div className="row justify-content-between">
              <div className="col-xl-7 col-lg-8">
                <h4>{jobDetails.title}</h4>
                <div className="mb-5">
                  <ul>
                    <li>Location - {jobDetails.location}</li>
                    <li>Start Date - {jobDetails.start_date}</li>
                    <li>Reports to - {jobDetails.reports_to}</li>
                    <li>Salary - {jobDetails.salary}</li>
                  </ul>
                </div>

                <div>
                  <div className="mb-5">
                    <h4>Job Description</h4>
                    <p>{jobDetails.description}</p>
                  </div>
                  <div className="mb-5">
                    <h4>Responsibilities</h4>
                    <ul>
                      {jobDetails?.responsibilities.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="mb-5">
                    <h4>Ideal Attributes</h4>
                    <ul>
                      {jobDetails?.ideal_attributes.map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-4">
                <div className="mb-5">
                  <h4>Preferred Attributes</h4>
                  <ul>
                    {jobDetails?.preferred_attributes.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
}

export default JobDetail;
