import React, { useEffect, useState } from "react";
import { Alert, Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";

import BrandStore from "../../data/stores/BrandStore";
import { useHistory } from "react-router-dom";

function NewMarketplaceBrand({ match }) {
  /**
   * @type {{brandStore: BrandStore}}
   */
  const { brandStore } = useStores();
  const brandID = match.params.brandID;
  const history = useHistory();

  const [showDelete, setShowDelete] = useState(false);
  const [logoPreview, setLogoPreview] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [metadataPreview, setMetadataPreview] = useState("");
  const [formState, setFormState] = useState({
    name: "",
    image_url: "",
    logo_image_url: "",
    description: "",
    slug: "",
    metadata: {
      image_url: "",
      description: "",
      title: "",
    },
  });

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      if (brandStore.brands.length === 0) {
        await brandStore.fetchBrands();
      }

      if (brandID !== "new") {
        const brand = brandStore.brands.find((brand) => brand.id === brandID);

        setFormState({ ...brand });
        setLogoPreview(brand?.logo_image_url);
        setImagePreview(brand?.image_url);
        setMetadataPreview(brand?.metadata?.image_url);
      }
    }

    getData();
  }, []);

  const updateFormState = (event) => {
    if (event.target.name.includes("metadata")) {
      const [metadata, fieldKey] = event.target.name.split(".");
      setFormState((prev) => {
        return {
          ...prev,
          [metadata]: { ...prev[metadata], [fieldKey]: event.target.value },
        };
      });
    } else {
      setFormState((prev) => {
        return {
          ...prev,
          [event.target.name]: event.target.value,
        };
      });
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();

    if (!formState.logo_image_url) {
      toast("You must include a logo image", { type: "info" });
      return;
    }

    if (!formState.slug && brandID !== "new") {
      toast("You must include a slug", { type: "info" });
      return;
    }

    if (!formState.metadata.image_url) {
      toast("You must include a metadata image", { type: "info" });
      return;
    }

    if (brandID === "new") {
      await brandStore.create(formState);

      if (!brandStore.hasFailed) {
        toast("Brand successfully created", { type: "success" });
        history.goBack();
      }
    } else {
      const brand = brandStore.brands.find((brand) => brand.id === brandID);
      if (!brand) {
        toast("you cannot update a brand that does not exist", {
          type: "error",
        });
        return;
      }

      await brandStore.update(brandID, { ...formState, user_suggestion: false });

      if (!brandStore.hasFailed) {
        toast("Brand successfully updated", { type: "success" });
        history.goBack();
      }
    }
  };

  const handleDelete = async () => {
    await brandStore.delete(brandID);
    if (!brandStore.hasFailed) {
      toast("Brand successfully deleted", { type: "success" });
      history.goBack();
    }
  };

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <h1>{brandID === "new" ? "Add" : "Amend "} Brand</h1>
      <Form onSubmit={handleSave} style={{ marginBottom: 25 }}>
        <Form.Group className="mb-3">
          <Form.Label>Name</Form.Label>
          <Form.Control
            placeholder="Enter the brand name"
            name="name"
            required
            value={formState.name}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter a description"
            name="description"
            required
            value={formState.description}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Slug</Form.Label>
          <Form.Control
            placeholder={`Enter a slug ${brandID === "new" ? "(Optional)" : ""}`}
            name="slug"
            required={brandID !== "new"}
            value={formState.slug}
            onChange={updateFormState}
          />
        </Form.Group>

        <Row>
          <Col sm={6}>
            <Form.Label>Logo Image URL</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter a valid logo URL"
                name="logoPreview"
                value={logoPreview}
                onChange={(e) => setLogoPreview(e.target.value)}
              />
              <Button
                variant="outline-primary"
                onClick={() => setFormState({ ...formState, logo_image_url: logoPreview })}
              >
                Add logo
              </Button>
            </InputGroup>

            {formState?.logo_image_url && (
              <div className="mt-3 w-25">
                <img src={formState?.logo_image_url} alt={formState.name} className="img-fluid" />
              </div>
            )}
          </Col>

          <Col sm={6}>
            <Form.Label>Main Image URL</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Enter a valid image URL (Optional)"
                name="imagePreview"
                value={imagePreview}
                onChange={(e) => setImagePreview(e.target.value)}
              />
              <Button variant="outline-primary" onClick={() => setFormState({ ...formState, image_url: imagePreview })}>
                Add image
              </Button>
            </InputGroup>

            {formState?.image_url && (
              <div className="mt-3 w-25">
                <img src={formState?.image_url} alt={formState.name} className="img-fluid" />
              </div>
            )}
          </Col>
        </Row>

        <h4 className="mt-5">Metadata</h4>

        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder="Enter a title"
            name="metadata.title"
            required
            value={formState?.metadata?.title}
            onChange={updateFormState}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter a description"
            name="metadata.description"
            required
            value={formState?.metadata?.description}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Metadata Image URL</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Enter a valid metadata image URL"
              name="metadataPreview"
              value={metadataPreview}
              onChange={(e) => setMetadataPreview(e.target.value)}
            />
            <Button
              variant="outline-primary"
              onClick={() =>
                setFormState({ ...formState, metadata: { ...formState.metadata, image_url: metadataPreview } })
              }
            >
              Add image
            </Button>
          </InputGroup>

          <Col sm={6}>
            {formState?.metadata?.image_url && (
              <div className="mt-3 w-25">
                <img src={formState?.metadata?.image_url} alt={formState.name} className="img-fluid" />
              </div>
            )}
          </Col>
        </Form.Group>

        <Alert variant="danger" show={showDelete}>
          <Alert.Heading>This action is irreversible.</Alert.Heading>
          <p>The data you are about to delete will not be recoverable, are you sure you want to continue?</p>
          <Button variant="outline-danger" onClick={handleDelete}>
            Yes, delete
          </Button>
          <Button onClick={() => setShowDelete(false)}>No, cancel</Button>
        </Alert>
        <Button variant="success" type="submit">
          {formState?.user_suggestion ? "Accept brand suggestion" : "Save"}
        </Button>
        <Button variant="danger" onClick={() => setShowDelete(true)}>
          {formState?.user_suggestion ? "Reject brand suggestion" : "Delete"}
        </Button>
      </Form>
    </Container>
  );
}

export default NewMarketplaceBrand;
