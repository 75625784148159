import { CHER_BASE_URL } from ".";
import wheringFetch from "../../helpers/fetch";

const route = "admin/users";
const baseUrl = CHER_BASE_URL;

const adminApi = {
  getAll: async () => {
    return wheringFetch(baseUrl, `${route}/`, "GET");
  },
  getOne: async (userID = false) => {
    return wheringFetch(baseUrl, `${route}/${userID}`, "GET");
  },
  create: async (params) => {
    return wheringFetch(baseUrl, `${route}/`, "POST", params);
  },
  update: async (params, userID) => {
    return wheringFetch(baseUrl, `${route}/${userID}`, "PATCH", params);
  },
  delete: async (userID) => {
    return wheringFetch(baseUrl, `${route}/${userID}`, "DELETE");
  },
};

export default adminApi;
