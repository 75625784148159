import { CHER_BASE_URL } from ".";
import wheringFetch from "../../helpers/fetch";

const route = "careers";
const baseUrl = CHER_BASE_URL;

const careerApi = {
  get: async () => {
    return wheringFetch(baseUrl, `${route}`, "GET");
  },
  create: async (data) => {
    return wheringFetch(baseUrl, `${route}`, "POST", data);
  },
  update: async (id, data) => {
    return wheringFetch(baseUrl, `${route}/${id}`, "PATCH", data);
  },
  delete: async (id) => {
    return wheringFetch(baseUrl, `${route}/${id}`, "DELETE");
  },
};

export default careerApi;
