import { makeAutoObservable } from "mobx";

export default class SpotlightsModel {
  spotlightID;
  brand_spotlight;
  edit_spotlight;
  featured_edits;
  gender;
  trending_brands;
  locations;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this.spotlightID = data._id;
    this.brand_spotlight = data.brand_spotlight;
    this.edit_spotlight = data.edit_spotlight;
    this.featured_edits = data.featured_edits;
    this.gender = data.gender;
    this.trending_brands = data.trending_brands;
    this.locations = data.locations;
  }
}
