import { makeAutoObservable } from "mobx";

export default class ServiceModel {
  serviceID;
  brand;
  category;
  contentUrl;
  description;
  discount;
  imageUrl;
  location;
  name;
  tags;
  title;
  brandDescription;
  created;

  constructor(data) {
    this.update(data);

    makeAutoObservable(this);
  }

  update(data) {
    this.serviceID = data.id;
    this.brand = data.brand;
    this.category = data.category;
    this.contentUrl = data.content_url;
    this.description = data.description;
    this.discount = data.discount;
    this.imageUrl = data.image_url;
    this.location = data.location;
    this.name = data.name;
    this.tags = data.tags;
    this.title = data.title;
    this.brandDescription = data.brandDescription;
    this.created = data.created_at;
    this.updated = data.updated_at;
  }
}
