import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useStores } from "../../context/mobx-context";

function EditCategory({ match, history }) {
  const { marketplaceStore } = useStores();
  const categoryID = match.params.categoryID;

  const inputRef = useRef(null);
  const metaRef = useRef(null);

  const [imageFile, setImageFile] = useState(false);
  const [metadataImageFile, setMetadataImageFile] = useState(false);

  const [formState, setFormState] = useState({
    slug: "",
    image_url: "",
    description: "",
    metadata: {
      title: "",
      image_url: "",
      description: "",
    },
  });

  const successMessage = "Category updated!";

  useEffect(() => {
    // If the store has not already been populated, fetch before trying to identify the resource
    async function getData() {
      if (marketplaceStore.categories.length === 0) {
        await marketplaceStore.getCategories();
      }

      if (categoryID !== "new") {
        const category = marketplaceStore.categories.find((category) => category.categoryID === categoryID);
        setFormState({ ...category });
      }
    }

    getData();
  }, []);

  const updateFormState = (event) => {
    setFormState((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      };
    });
  };

  const onChangeImage = (event, key) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    if (key === "image") {
      setImageFile(URL.createObjectURL(file));
    } else {
      setMetadataImageFile(URL.createObjectURL(file));
    }

    setFormState((prev) => {
      return {
        ...prev,
        [key]: file,
      };
    });
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const cat = marketplaceStore.categories.find((category) => category.categoryID === categoryID);
    if (!cat) {
      toast("you cannot update a category that does not exist", {
        type: "error",
      });
      return;
    }

    const updateData = Object.assign({ ...cat }, { ...formState });

    const data = new FormData();

    data.append("data", JSON.stringify(updateData));

    if (updateData?.image) {
      data.append("image", updateData.image);
    }
    if (updateData?.metadata_image) {
      data.append("metadata_image", updateData.metadata_image);
    }

    await marketplaceStore.updateCategory(categoryID, data);

    if (!marketplaceStore.hasFailed) {
      toast(successMessage, { type: "success" });
      history.goBack();
    }
  };

  return (
    <Container className="margin-top" style={{ marginTop: 25 }}>
      <input
        type="file"
        id="file"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => onChangeImage(e, "image")}
      />
      <input
        type="file"
        id="file2"
        ref={metaRef}
        style={{ display: "none" }}
        onChange={(e) => onChangeImage(e, "metadata_image")}
      />
      <h1>{categoryID === "new" ? "Add" : "Amend "} Category</h1>
      <Form onSubmit={handleSave} style={{ marginBottom: 25 }}>
        <Form.Group className="mb-3">
          <Form.Label>Slug</Form.Label>
          <Form.Control
            placeholder="Enter a slug"
            name="slug"
            required
            value={formState.slug}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter a description"
            name="description"
            required
            value={formState.description}
            onChange={updateFormState}
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Image</Form.Label>
          <div style={{ position: "relative", width: "fit-content" }}>
            {imageFile ? (
              <img src={imageFile} alt="category-main" height={300} />
            ) : (
              <img src={formState?.image_url} alt="category-main" height={300} />
            )}

            <Button onClick={() => inputRef.current.click()}>Change Image</Button>
          </div>
        </Form.Group>

        <h4 className="mt-5">Metadata</h4>

        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            placeholder="Enter a title"
            name="metadata.title"
            required
            value={formState?.metadata?.title}
            onChange={(event) =>
              setFormState((prev) => ({ ...prev, metadata: { ...prev.metadata, title: event.target.value } }))
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter a description"
            name="metadata.description"
            required
            value={formState?.metadata?.description}
            onChange={(event) =>
              setFormState((prev) => ({ ...prev, metadata: { ...prev.metadata, description: event.target.value } }))
            }
          />
        </Form.Group>

        <Form.Group className="mb-3">
          <Form.Label>Image URL</Form.Label>
          <div style={{ position: "relative", width: "fit-content" }}>
            {metadataImageFile ? (
              <img src={metadataImageFile} alt="category-metadata" height={300} />
            ) : (
              <img src={formState?.metadata?.image_url} alt="category-metadata" height={200} />
            )}

            <Button onClick={() => metaRef.current.click()}>Change Metadata Image</Button>
          </div>
        </Form.Group>

        <Button variant="success" type="submit">
          Save
        </Button>
      </Form>
    </Container>
  );
}

export default EditCategory;
