import { CHER_BASE_URL } from ".";
import wheringFetch from "../../helpers/fetch";

const route = "users";
const baseUrl = CHER_BASE_URL;

const userApi = {
  find: async (email) => {
    return wheringFetch(baseUrl, `${route}?email=${email}`, "GET");
  },
  update: async (uid, email) => {
    return wheringFetch(baseUrl, `${route}/${uid}`, "POST", { email });
  },
  delete: async (uid) => {
    return wheringFetch(baseUrl, `${route}/${uid}`, "DELETE");
  },
  deleteOnboarding: async (email) => {
    return wheringFetch(baseUrl, `${route}/onboarding/clear`, "POST", { email });
  },
  generatePasswordResetLink: async (email) => {
    return wheringFetch(baseUrl, `${route}/password_reset`, "POST", { email });
  },
};

export default userApi;
