import { observer } from "mobx-react";
import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import TableWrapper from "../../components/TableWrapper";
import { useStores } from "../../context/mobx-context";

function Users({ history }) {
  const { userStore } = useStores();
  const [userSearch, setUserSearch] = useState();

  const onSearchClick = async () => {
    await userStore.find(userSearch);
  };

  const goToUser = (uid) => {
    history.push(`/users/${uid}`);
  };

  return (
    <TableWrapper
      title="User Management"
      onSearchChange={(event) => setUserSearch(event.target.value)}
      onSearchClick={onSearchClick}
      searchPlaceholderText="Search by email address"
      showLoading={userStore.isFetching}
    >
      <Table className="model-table">
        <thead>
          <tr>
            <th>UID</th>
            <th>Email</th>
            <th>Firstname</th>
            <th>Lastname</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {userStore.users.map((user) => (
            <tr key={user.uid}>
              <td>{user.uid}</td>
              <td>{user.email}</td>
              <td>{user.first_name}</td>
              <td>{user.last_name}</td>
              <td>
                <Button onClick={() => goToUser(user.uid)}>Edit</Button>
              </td>
            </tr>
          ))}
          {userStore.hasFetched && userStore.users.length === 0 && (
            <tr>
              <td colSpan={5}>
                <h1 style={{ textAlign: "center" }}>No Results Found</h1>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default observer(Users);
